import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const phoneNumberSchema = yup
  .string()
  .test("valid-phone", "Neteisingai įvestas tel. numeris", function (value) {
    if (!value) return false;
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(value);
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (err) {
      return false;
    }
  });
