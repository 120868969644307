import React from "react";
import { OrderedListItem } from "../../components/listItems/OrderedListItem";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import colors from "common/styles/colors";
import { LinkForButton } from "../../components/links/LinkForButton";
import { Button } from "@mui/material";

interface FullReportBannerProps {
  phone?: string;
}

export const FullReportBanner: React.FC<FullReportBannerProps> = ({
  phone,
}) => {
  return (
    <div
      style={{
        borderColor: colors.greybg,
        borderWidth: "1px",
        borderRadius: "10px",
        borderStyle: "solid",
        padding: "20px",
        marginTop: "20px",
        backgroundColor: colors.white,
      }}
    >
      <OrderedListItem
        index={0}
        isLast
        customIcon={<CampaignOutlinedIcon />}
        title="Pilna turto vertinimo ataskaita nuo 220 EUR!"
        description="Pasiūlymas taikomas po preliminarios turto vertinimo ataskaitos gavimo, kurios kaina bus įtraukta į galutinę sumą. Tiksli kaina priklauso nuo Jūsų turto vietos ir tipo."
      />
      {!!phone && (
        <LinkForButton to={`tel:${phone}`}>
          <Button variant="contained" color="primary">
            susisiekti su vertintoju
          </Button>
        </LinkForButton>
      )}
    </div>
  );
};
