import React, { useContext, useEffect, useRef, useState } from "react";
import {
  MyPropertyDetailsEditForm,
  MyPropertyDetailsEditFormHandles,
} from "common/components/form/MyPropertyDetailsEditForm";
import colors from "common/styles/colors";
import { PropertyFormCaption } from "common/components/form/PropertyFormCaption";
import { MyProperty, MyPropertyAddRequest } from "common/types/common.types";
import { AddressSelectionModal } from "../../components/AddressSelectionModal";
import { getAddressFromPlaceId } from "common/api/maps.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import {
  useUpdateMyProperty,
  useWithPropertyEditToken,
} from "common/hooks/propertyDetails.hooks";
import {
  FormGallery,
  FormGalleryHandles,
} from "common/components/form/FormGallery";
import {
  addAppraiserProperty,
  deleteAppraiserPropertyPhotos,
  getAppraiserPropertyPhotos,
  updateAppraiserProperty,
  uploadAppraiserPropertyPhotos,
} from "../../api/appraiserProperties.api";
import { useProperty } from "../../hooks/property.hooks";
import { useQueryClient } from "react-query";
import { CityFormField } from "./addressInput/CityFormField";
import { StreetFormField } from "./addressInput/StreetFormField";
import { BuildingNumberField } from "./addressInput/BuildingNumberField";
import {
  useRouteQuery,
  useScrollToTop,
  useUpdateQueryParam,
} from "../../hooks/router.hooks";
import { DesignationType } from "common/types/comparableFields.types";
import { DesignationTypeTranslations } from "../../helpers/comparableFields.helpers";
import { Button } from "common/components/buttons/Button";
import { Box, Button as ButtonMUI } from "@mui/material";
import { MapField } from "./addressInput/MapField";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { PropertyType } from "../../types/api.types";
import { PreliminaryPrice } from "./reportPayments/PreliminaryPrice";
import { FormDatepicker } from "../../components/form/FormDatepicker";
import { Typography } from "common/components/Typography";

interface PropertyDetailsEditRouteProps {}

export const PropertyDetailsEditRoute: React.FC<
  PropertyDetailsEditRouteProps
> = () => {
  useScrollToTop();
  const { fromLead, setCityId } = useContext(PropertyCreationContext);

  const { propertyId } = useParams();

  const createdPropertyId = useRouteQuery().get("createdPropertyId");

  const initialPropertyId = useRef(propertyId ?? createdPropertyId);

  useWithPropertyEditToken();

  const query = useRouteQuery();

  const { data, isLoading: isPropertyLoading } = useProperty(
    initialPropertyId.current ?? "",
    (initialPropertyId.current ?? "").length > 0,
    (property) => setInitialProperty(property)
  );

  const formRef = useRef<MyPropertyDetailsEditFormHandles>(null);

  const [initialProperty, setInitialProperty] = useState<
    Partial<MyPropertyAddRequest>
  >(data ?? {});

  useEffect(() => {
    if (data === undefined) {
      return;
    }
    setInitialProperty(data);
  }, [data]);

  useScrollToTop();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const galleryRef = useRef<FormGalleryHandles>(null);
  const queryClient = useQueryClient();

  const appraiserId = useRouteQuery().get("appraiserId") ?? undefined;

  const { navigateWithQueryParams, updateQueryParam } = useUpdateQueryParam();

  const simplePropertyType = useRouteQuery().get("simpleType") as
    | PropertyType
    | undefined;

  const { submitHandler, isLoading } = useUpdateMyProperty(
    initialPropertyId.current ?? undefined,
    (property) => {
      if (!!property) {
        queryClient.invalidateQueries(["comparables", property._id]);
        if (!!fromLead) {
          updateQueryParam("createdPropertyId", property._id, undefined, true);
          navigateWithQueryParams("../contact", [
            { paramName: "createdPropertyId", newValue: property._id },
          ]);
        } else {
          navigate(`/properties/${property?._id}`);
        }
      } else {
        navigate("/");
      }
    },
    updateAppraiserProperty,
    (property: MyPropertyAddRequest) =>
      addAppraiserProperty(property, fromLead, appraiserId, simplePropertyType),
    deleteAppraiserPropertyPhotos,
    uploadAppraiserPropertyPhotos
  );

  if (isPropertyLoading) {
    return null;
  }

  const designationType =
    (query.get("type") as DesignationType) ?? initialProperty.designationType;

  return (
    <div>
      <PreliminaryPrice />
      <ContentCard title={undefined} style={{ marginBottom: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", sm: "90px" },
          }}
        >
          <div
            style={{
              color: colors.grey,
            }}
          >
            Turto paskirtis
          </div>
          <div>{DesignationTypeTranslations[designationType]}</div>
          {!fromLead && (
            <ButtonMUI
              variant="outlined"
              onClick={() => {
                if (!!fromLead) {
                  navigateWithQueryParams("../type");
                } else {
                  navigate(
                    `/properties/create/type?type=${designationType}${
                      !!propertyId ? `&propertyId=${propertyId}` : ""
                    }`
                  );
                }
              }}
            >
              Keisti
            </ButtonMUI>
          )}
        </Box>
      </ContentCard>
      <ContentCard title="Pasirinkti ypatybes">
        <Box
          sx={{
            width: { xs: "100%", sm: "450px", md: "500px" },
            mx: "auto",
          }}
        >
          <MyPropertyDetailsEditForm
            ref={formRef}
            initialProperty={initialProperty}
            customCityComponent={
              <CityFormField
                onSelect={(city) => {
                  setCityId?.(city?.GYV_KODAS);
                }}
              />
            }
            customStreetComponent={<StreetFormField />}
            customStreetNumberComponent={<BuildingNumberField />}
            customMapComponent={<MapField />}
            onLocationEdit={() => {
              setIsModalOpen(true);
            }}
            designationType={
              (query.get("type") as DesignationType) ?? undefined
            }
            fullFinishing
            showExpectedValue={!fromLead}
            soldDateField={
              !fromLead && (
                <>
                  <FormDatepicker name="soldDate" label="Pardavimo data" />
                  <Typography
                    variant="caption"
                    textStyle={{ marginTop: "5px" }}
                  >
                    Reikalinga retrospektyvinio vertinimo atveju
                  </Typography>
                </>
              )
            }
          />
        </Box>
      </ContentCard>
      <ContentCard title="Įkelti nuotraukas">
        <FormGallery
          ref={galleryRef}
          buttonBackgroundColor="white"
          propertyId={propertyId ?? createdPropertyId ?? undefined}
          getPhotosFunction={getAppraiserPropertyPhotos}
        />
      </ContentCard>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
          gap: "15px",
          margin: "0",
          padding: "0 10px",
        }}
      >
        <PropertyFormCaption />
        <div
          style={{
            maxWidth: 345,
            width: "100%",
            alignSelf: "center",
          }}
        >
          <Button
            backgroundColor="purple2"
            textStyle={{ color: colors.white }}
            onPress={() => {
              formRef.current?.handleSubmit((property) => {
                const imagesFormdata = galleryRef.current?.getUploadImages();
                const deletedPhotoIds =
                  galleryRef.current?.getDeletedPhotosIds();

                submitHandler(
                  {
                    ...property,
                    condition: 3,
                    finishing: !property.finishing,
                  },
                  deletedPhotoIds,
                  imagesFormdata
                );
              })();
            }}
            isLoading={isLoading}
          >
            TĘSTI
          </Button>
        </div>
      </div>
      <AddressSelectionModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onSelect={async (placeId, sessionToken) => {
          setIsModalOpen(false);
          const address = await getAddressFromPlaceId(placeId, sessionToken);
          setInitialProperty({
            ...initialProperty,
            lat: address.lat,
            lng: address.lng,
            street: address.address.street,
            address: address.address.label,
            streetNumber: address.address.streetNumber,
            city: address.address.city,
          });
        }}
      />
    </div>
  );
};
