import React from "react";
import { PropertyLegal } from "../../types/api.types";
import { ContentCard } from "../../components/ContentCard";
import { Typography } from "common/components/Typography";
import { Divider } from "@mui/material";
import {
  PropertyPurpose,
  PropertyPurposeTranslations,
} from "../../types/appraiser.types";
import { LegalParagraphs } from "../../components/content/LegalParagraphs";

interface PropertyLegalContentProps {
  legals: PropertyLegal[];
  purpose?: PropertyPurpose;
}

export const PropertyLegalContent: React.FC<PropertyLegalContentProps> = ({
  legals,
  purpose,
}) => {
  let legalsData = legals;

  if (purpose) {
    legalsData = [
      {
        section: "TIKSLAS",
        paragraphs: [{ text: PropertyPurposeTranslations[purpose] }],
      },
      ...legalsData,
    ];
  }

  return (
    <ContentCard title="Apie galimos kainos nustatymą">
      <LegalParagraphs legals={legalsData} />
    </ContentCard>
  );
};
