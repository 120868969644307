import React, { useImperativeHandle } from "react";
import { ActivityIndicator, View } from "react-native";
import { splitIntoChunks } from "../../helpers/common.helpers";
import {
  getImageData,
  pickImages,
} from "../../helpers/imagePicker/imagePicker";
import { Typography } from "common/components/Typography";
import { OutlinedButton } from "../buttons/OutlinedButton";
import UploadIcon from "../../assets/icons/upload.svg";
import colors from "../../styles/colors";
import { Asset } from "react-native-image-picker";
import { useMyPropertyPhotos } from "../../hooks/api.hooks";
import { isWeb } from "../../environment";
import { FormImage } from "../images/FormImage";
import CommonConfig from "../../commonConfig";
import { UploadedPhoto } from "../../types/common.types";
interface FormGalleryProps {
  propertyId?: string;
  buttonBackgroundColor?: keyof typeof colors;
  onGalleryAdvicesPress?: () => void;
  getPhotosFunction?: (propertyId: string) => Promise<UploadedPhoto[]>;
}

export interface FormGalleryHandles {
  getUploadImages: () => FormData | undefined;
  getDeletedPhotosIds: () => string[];
}

const maxImageLimit = isWeb() ? 36 : 18;

export type BackendUri = {
  uri: string;
  backend_id: string;
};

export const FormGallery = React.forwardRef<
  FormGalleryHandles,
  FormGalleryProps
>(
  (
    {
      propertyId,
      buttonBackgroundColor,
      onGalleryAdvicesPress,
      getPhotosFunction,
    },
    ref
  ) => {
    const { data, isLoading } = useMyPropertyPhotos(
      propertyId,
      getPhotosFunction
    );
    const [selectedImages, setSelectedImages] = React.useState<Asset[]>([]);
    const [imagesToDelete, setImagesToDelete] = React.useState<string[]>([]);

    const formattedImages = (() => {
      const savedImages =
        data
          ?.map((image) => ({
            uri: image.url,
            backend_id: image._id,
          }))
          .filter((image) => !imagesToDelete.includes(image.backend_id)) || [];
      return [...savedImages, ...selectedImages];
    })();

    const imageChunks = splitIntoChunks(2, formattedImages);

    const onGalleryUploadPress = async () => {
      const result = await pickImages(maxImageLimit - formattedImages.length);

      if (result.didCancel || !result.assets) {
        CommonConfig.loggingHandler("image_picker_cancelled");
        return;
      }
      CommonConfig.loggingHandler(
        `image_picker_success_${result.assets.length}`,
        {
          imagesCount: result.assets.length,
        }
      );
      setSelectedImages([...selectedImages, ...result.assets]);
    };

    const onCloseButtonPress = (imageInfo: Asset | BackendUri) => {
      const backend_id = (imageInfo as BackendUri).backend_id;
      if (backend_id !== undefined) {
        setImagesToDelete([...imagesToDelete, backend_id]);
      } else {
        setSelectedImages(
          selectedImages.filter((image) => image.uri !== imageInfo.uri)
        );
      }
    };

    const getUploadImages = () => {
      if (!selectedImages.length) {
        return undefined;
      }
      return getImageData(selectedImages, "photos");
    };
    const getDeletedPhotosIds = () => {
      return imagesToDelete;
    };
    useImperativeHandle(ref, () => ({
      getUploadImages,
      getDeletedPhotosIds,
    }));

    return (
      <View>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Typography variant="h3" textStyle={{ marginBottom: 10 }}>
            Galerija
          </Typography>
          {!!onGalleryAdvicesPress && (
            <Typography
              variant="h4"
              textStyle={{ color: colors.purple2 }}
              onPress={onGalleryAdvicesPress}
            >
              Gauti patarimų
            </Typography>
          )}
        </View>
        <Typography variant="caption" textStyle={{ fontSize: 16 }}>
          Įkelkite iki {maxImageLimit} nuotraukų tikslesniam galimos turto
          kainos nustatymui
        </Typography>
        <View>{!!isLoading && <ActivityIndicator />}</View>

        <View style={{ marginHorizontal: -5, marginVertical: 10 }}>
          {isWeb() ? (
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {formattedImages.map((image) => (
                <FormImage
                  key={image.uri}
                  uri={image?.uri ?? ""}
                  onCloseButtonPress={() => onCloseButtonPress(image)}
                />
              ))}
            </View>
          ) : (
            imageChunks.map((chunk, index) => (
              <View key={index} style={{ flexDirection: "row" }}>
                {chunk.map((image, index) => {
                  if (image === null) {
                    return <View key={index} style={{ flex: 1 }} />;
                  }
                  return (
                    <View style={{ flex: 1 }} key={index}>
                      <FormImage
                        uri={image?.uri ?? ""}
                        onCloseButtonPress={() => onCloseButtonPress(image)}
                      />
                    </View>
                  );
                })}
              </View>
            ))
          )}
        </View>
        <View style={{ maxWidth: 400, width: "100%", alignSelf: "center" }}>
          <OutlinedButton
            leftIcon={
              <View style={{ marginLeft: 10 }}>
                <UploadIcon />
              </View>
            }
            onPress={onGalleryUploadPress}
            backgroundColor={buttonBackgroundColor ?? "greybg"}
            style={{ borderColor: colors.purple2 }}
          >
            ĮKELTI NUOTRAUKAS
          </OutlinedButton>
        </View>
      </View>
    );
  }
);
