import { CheckboxInput } from "common/components/form/CheckboxInput";
import React from "react";
import { PropertyAddonType } from "../../../types/api.types";
import { PropertyCreationContext } from "../../propertiesCRUD/PropertyCreationProvider";
import { formatPrice } from "common/helpers/text.helpers";
import { Typography } from "common/components/Typography";
import { AddonSelectInput } from "./AddonSelectInput";

interface AddonSelectProps {
  addon: PropertyAddonType;
  label: string;
  description?: string;
  price: number;
}

export const AddonSelect: React.FC<AddonSelectProps> = ({
  addon,
  label,
  price,
  description,
}) => {
  const { toggleAddon, isAddonSelected } = React.useContext(
    PropertyCreationContext
  );

  const handleToggle = () => {
    toggleAddon?.(addon);
  };

  return (
    <AddonSelectInput
      onClick={handleToggle}
      isChecked={isAddonSelected?.(addon) ?? false}
      label={label}
      description={description}
      price={price}
      inputType="checkbox"
    />
  );
};
