import React, { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { InfoTooltip } from "../tooltips/InfoTooltip";
import { useComparables } from "../../hooks/property.hooks";
import { useParams } from "react-router-dom";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";

interface BuyTransactionsButtonProps {
  transactionsToBuy: number;
}

export const BuyTransactionsButton: React.FC<BuyTransactionsButtonProps> = ({
  transactionsToBuy,
}) => {
  const { data: notBoughtComps } = useComparables();
  const { propertyId } = useParams();

  const transactionsToBuyCount = Math.min(
    transactionsToBuy,
    notBoughtComps.length
  );

  const { buyTransactions, isBuying } = useContext(TransactionsRCContext);

  const transactionsIdsToBuy = notBoughtComps
    .slice(0, transactionsToBuyCount)
    .map((comp) => comp.comparable_transaction.id);
  const handleBuyTransactions = async () => {
    await buyTransactions?.(transactionsIdsToBuy, propertyId ?? "");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (transactionsToBuyCount === 0) {
    return null;
  }

  return (
    <div>
      <LoadingButton
        style={{ marginRight: "8px" }}
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        loading={isBuying?.some((id) => transactionsIdsToBuy.includes(id))}
        onClick={() => {
          const doNotShowAgain =
            localStorage.getItem("doNotShowTransactionsBuyInFilters") ===
            "true";

          if (doNotShowAgain) {
            handleBuyTransactions();
            return;
          }

          setIsModalOpen(true);
        }}
      >
        {`Įsigyti ${transactionsToBuyCount} sandorius`}
      </LoadingButton>
      <InfoTooltip
        title={`Atrinkti ${transactionsToBuyCount} panašiausi sandoriai pagal Jūsų vertinamo turto ypatybes: statybos metus, plotą ir vietą.`}
      />
      <ConfirmationDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={(doNotShowAgain) => {
          if (doNotShowAgain) {
            localStorage.setItem("doNotShowTransactionsBuyInFilters", "true");
          }
          handleBuyTransactions();
          setIsModalOpen(false);
        }}
        title="Ar sutinkate su sandorių pirkimu?"
        description={`Atlikdami šį veiksmą, jūs sutinkate su ${transactionsToBuyCount} sandorių pirkimu. Jei sutinkate paspauskite „Patvirtinti“`}
        actionButtonText="PATVIRTINTI"
      />
    </div>
  );
};
