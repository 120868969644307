import colors from "common/styles/colors";
import React from "react";

interface SimpleTableRowProps {
  children?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const SimpleTableRow: React.FC<SimpleTableRowProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <tr
      style={{
        ...style,
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: colors.greybg,
        height: "50px",
        cursor: onClick ? "pointer" : "default",
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};
