import colors from "common/styles/colors";
import React from "react";
import { ComparablesCountOverviewRow } from "./ComparablesCountOverviewRow";
import { ComparablesCountOverviewWrapper } from "./ComparablesCountOverviewWrapper";
import { PercentageBar } from "./PercentageBar";
import { usePropertyIconsProps } from "../../hooks/propertyIcons.hooks";
import UnlockedIcon from "../../assets/icons/comparableResults/unlocked.svg";
import LockedBlueIcon from "../../assets/icons/comparableResults/lockedBlue.svg";
import LockedWhiteIcon from "../../assets/icons/comparableResults/lockedWhite.svg";
import { PriceFilter } from "./PriceFilter";
import { Button } from "@mui/material";
import { BuyTransactionsButton } from "./BuyTransactionsButton";
interface ComparablesCountOverviewProps {
  fullTransactionsCount: number;
  baseTransactionsCount: number;
  otherTransactionsCount: number;
}

export const ComparablesCountOverview: React.FC<
  ComparablesCountOverviewProps
> = ({
  fullTransactionsCount,
  baseTransactionsCount,
  otherTransactionsCount,
}) => {
  const allCount =
    fullTransactionsCount + baseTransactionsCount + otherTransactionsCount;

  return (
    <ComparablesCountOverviewWrapper
      title="RC sandorių rezultatai:"
      noBackground
    >
      <PercentageBar
        items={[
          {
            icon: <UnlockedIcon />,
            value: fullTransactionsCount / allCount,
            color: colors.purple3,
          },
          {
            icon: <LockedBlueIcon />,
            value: baseTransactionsCount / allCount,
            color: colors.white,
          },
          {
            icon: <LockedWhiteIcon />,
            value: otherTransactionsCount / allCount,
            color: colors.beige,
          },
        ]}
      />
      <ComparablesCountOverviewRow
        label="įsigyti"
        count={fullTransactionsCount}
        allCount={allCount}
        icon={<UnlockedIcon />}
        iconBackgroundColor={colors.purple3}
      />
      <PriceFilter />
      <ComparablesCountOverviewRow
        label="neįsigyti"
        count={baseTransactionsCount}
        allCount={allCount}
        icon={<LockedBlueIcon />}
        iconBackgroundColor={colors.white}
        iconBorder={`1px solid ${colors.grey}`}
      >
        <BuyTransactionsButton transactionsToBuy={3} />
      </ComparablesCountOverviewRow>
      <ComparablesCountOverviewRow
        label="kiti"
        count={otherTransactionsCount}
        allCount={allCount}
        tooltipText="Tai sandoriai kurie nėra rodomi tarp rezultatų. Pavyzdžiui sandoriai su priklausomu turtu."
        icon={<LockedWhiteIcon />}
        iconBackgroundColor={colors.beige}
      />
    </ComparablesCountOverviewWrapper>
  );
};
