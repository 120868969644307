import { Input } from "@mui/material";
import React from "react";

interface SliderInputProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step?: number;
  width?: string;
}

export const SliderInput: React.FC<SliderInputProps> = ({
  value,
  onChange,
  min,
  max,
  step,
  width,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === "" ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < min) {
      onChange(min);
    } else if (value > max) {
      onChange(max);
    }
  };

  return (
    <Input
      value={value}
      size="small"
      onChange={handleInputChange}
      onBlur={handleBlur}
      inputProps={{
        step: step ?? 10,
        min,
        max,
        type: "number",
      }}
      style={{ width: width ?? "45px" }}
    />
  );
};
