import axiosMobileInstance from "./axiosMobileInstance/axiosMobileInstance";
import { LoginProvider } from "common/types/login.types";
import { TokenServiceInstance } from "common/api/TokenService";
import {
  AdjustmentDescription,
  AdjustmentDescriptionForComparable,
  AdjustmentProvider,
  AdminAdjustments,
  AnalyzedReport,
  AppraiserFilter,
  AppraiserPropertyField,
  BasicOrganization,
  ChartData,
  ComparableMessage,
  ComparableMessageCreateRequest,
  CompositeAsset,
  CompositeAssetRequest,
  Confirmation,
  ConfirmationRequest,
  CreateAppraiserRequest,
  DecodedAppraiserToken,
  DeliveryTimeConfiguration,
  DiscountCode,
  DiscountCodeCreateRequest,
  LoginRequest,
  MessageType,
  Organization,
  OrganizationProfile,
  OrganizationUsedCredits,
  PaginatedResponse,
  PaymentMethods,
  PaymentMethodType,
  PropertyAddon,
  PropertyAddonPrices,
  PropertyAddonType,
  PropertyAddonWithPrice,
  PropertyAssessmentStatus,
  PropertyComment,
  PropertyDeliveryTimeType,
  PropertyInfra,
  PropertyLocationDetails,
  PropertyShared,
  PropertyType,
  RegisterRequest,
  SavedComparableFilter,
  SimilarPricePrediction,
  StatusMessage,
  TotalReportPrice,
  TransactionsImportResult,
  UploadedFile,
  ValuationZone,
  ValuationZoneFull,
  DepositPrices,
  DepositPaid,
  PropertyStatistics,
  PropertyEmailTemplate,
  CreatePropertyLeadMessage,
  PropertyLeadMessage,
  AdjustmentTableRow,
  TransparencyAdjustmentsCoeffs,
  SchoolExamResults,
  NearbySchools,
  SchoolRating,
} from "../types/api.types";
import {
  AdjustedField,
  AppraiserPricePredictions,
  Comparable,
  ComparableFull,
  ComparablePagination,
  ComparableTransaction,
  ComparableTransactionFull,
  FinalPricePredictions,
  LeadContact,
  MyProperty,
  ListingsWithAddons,
  ParkingType,
  PredictedPrice,
} from "common/types/common.types";
import {
  Appraiser,
  AppraiserProfile,
  AppraiserProperty,
  AppraiserWithLimits,
  BasicAppraiser,
} from "../types/appraiser.types";
import { RawTransactionField } from "../components/transactions/rawTransaction.utils";
import { AnyTransaction } from "../components/transactions/tableTransaction.utils";
import { PropertyPermission } from "../routes/property/PropertyShareSettings";

const getPropertyTokenHeader = (propertyId: string) => {
  return {
    "x-property-token": sessionStorage.getItem(`propertyToken.${propertyId}`),
  };
};

export const createProviderSession = async ({
  idToken,
  provider,
  firstName,
  lastName,
}: {
  idToken: string;
  provider: LoginProvider;
  firstName?: string;
  lastName?: string;
}) => {
  try {
    const { data } = await axiosMobileInstance.post<{ accessToken: string }>(
      `/auth/${provider}/no_user`,
      {},
      { params: { idToken, firstName, lastName } }
    );
    await TokenServiceInstance.setAccessToken(data.accessToken);
  } catch {
    const message = `Failed to create ${provider} session`;
    console.error(message);
  }
};

export const getProperties = async (
  page: number,
  status: PropertyAssessmentStatus,
  appraiserFilter?: AppraiserFilter
) => {
  const { data } = await axiosMobileInstance.get<
    PaginatedResponse<AppraiserProperty[]>
  >(`/appraiserProperties`, {
    params: {
      page,
      pageSize: 10,
      status,
      appraiserFilter,
    },
  });

  return data;
};

export const getPropertiesTotal = async (
  status: PropertyAssessmentStatus,
  appraiserFilter?: AppraiserFilter
) => {
  const { data } = await axiosMobileInstance.get<{ total: number }>(
    `/appraiserPropertiesTotal`,
    {
      params: {
        status,
        appraiserFilter,
      },
    }
  );

  return data;
};

export const getProperty = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<AppraiserProperty>(
    `/appraiserProperties/${propertyId}`,
    {
      headers: {
        ...getPropertyTokenHeader(propertyId),
      },
    }
  );
  return data;
};

export const getComparables = async (propertyId: string, cursor?: number) => {
  const { data } = await axiosMobileInstance.get(
    `/properties/${propertyId}/comparables`,
    {
      params: {
        cursor,
      },
    }
  );
  return JSON.parse(data) as {
    data: Comparable[];
    pagination: ComparablePagination;
  };
};

type PricePredictionType = AppraiserPricePredictions | FinalPricePredictions;

export const getPricePrediction = async <T extends PricePredictionType>(
  propertyId: string,
  isFinal?: boolean,
  includeOtherFields?: boolean
) => {
  const { data } = await axiosMobileInstance.get<
    T & { createdAt: string; updatedAt: string }
  >(`/properties/${propertyId}/pricePredictions`, {
    params: {
      isFinal,
      includeOtherFields,
    },
  });
  return data;
};

export const getPricePredictionFilters = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<{
    filters: SavedComparableFilter[] | undefined;
  }>(`/properties/${propertyId}/pricePredictions/filters`);
  return data.filters;
};

export const setPricePredictionFilters = async (
  propertyId: string,
  filters: SavedComparableFilter[]
) => {
  await axiosMobileInstance.post(
    `/properties/${propertyId}/pricePredictions/filters`,
    {
      filters,
    }
  );
};

export const addPricePrediction = async <T extends PricePredictionType>(
  propertyId: string,
  pricePrediction: T,
  isFinal?: boolean
) => {
  await axiosMobileInstance.put<T>(
    `/properties/${propertyId}/pricePredictions?isFinal=${!!isFinal}`,
    pricePrediction
  );
};

export const loginAppraiser = async (data: LoginRequest) => {
  const { data: responseData } = await axiosMobileInstance.post<Appraiser>(
    "/auth/login",
    data
  );
  return responseData;
};

export const loginAsAppraiser = async (appraiserId: string) => {
  const { data: responseData } = await axiosMobileInstance.post<Appraiser>(
    `/auth/loginAs`,
    {},
    {
      params: {
        appraiserId,
      },
    }
  );
  return responseData;
};

export const registerAppraiser = async (data: RegisterRequest) => {
  const { data: responseData } = await axiosMobileInstance.post<Appraiser>(
    "/auth/register",
    data
  );
  return responseData;
};

export const resetAppraiserPassword = async (data: RegisterRequest) => {
  const { data: responseData } = await axiosMobileInstance.post<Appraiser>(
    "/auth/resetPassword",
    data
  );
  return responseData;
};

export const logoutAppraiser = async () => {
  await axiosMobileInstance.delete("/auth/logout");
};

export const getAppraiser = async () => {
  const { data } = await axiosMobileInstance.get<Appraiser>("/appraiser");
  return data;
};

export const getOrganization = async () => {
  const { data } = await axiosMobileInstance.get<Organization>("/organization");
  return data;
};

export const createAppraiser = async (data: CreateAppraiserRequest) => {
  const { data: responseData } = await axiosMobileInstance.post<Appraiser>(
    "/appraisers",
    data
  );
  return responseData;
};

export const getAppraisersInOrganization = async () => {
  const { data } = await axiosMobileInstance.get<Appraiser[]>(
    "/appraisers/organization"
  );
  return data;
};

export const getAppraisersCreditsInOrganization = async () => {
  const { data } = await axiosMobileInstance.get<AppraiserWithLimits[]>(
    "/appraisers/organization/credits"
  );
  return data;
};

export const setAppraiserMonthlyCreditLimit = async (
  appraiserId: string,
  monthlyCreditLimit: number | null
) => {
  await axiosMobileInstance.post(`/appraisers/${appraiserId}/monthlyCredit`, {
    monthlyCreditLimit,
  });
};

export const setOrganizationMonthlyCreditLimit = async (
  monthlyCreditLimit: number | null
) => {
  await axiosMobileInstance.post("/organization/monthlyCredit", {
    monthlyCreditLimit,
  });
};

export const setOrganizationTransactionsRetention = async (
  retentionDays: number | null
) => {
  const { data } = await axiosMobileInstance.post("/organization/retention", {
    transactionRetentionDays: retentionDays,
  });
  return data;
};

export const getOrganizationUsedCredits = async () => {
  const { data } = await axiosMobileInstance.get<OrganizationUsedCredits>(
    "/organization/monthlyCredit"
  );
  return data;
};

export const getAppraiserPropertyFields = async () => {
  const { data } = await axiosMobileInstance.get<AppraiserPropertyField[]>(
    "/appraiserPropertyFields"
  );
  return data;
};

export const createAppraiserPropertyFields = async (data: { name: string }) => {
  const { data: responseData } =
    await axiosMobileInstance.post<AppraiserPropertyField>(
      "/appraiserPropertyFields",
      data
    );
  return responseData;
};

export const getDecodedAppraiserToken = async (token: string) => {
  const { data } = await axiosMobileInstance.get<DecodedAppraiserToken>(
    "/appraisers/decodeToken",
    {
      params: {
        token,
      },
    }
  );
  return data;
};

export const regenerateAppraiserLink = async (appraiserId: string) => {
  const { data } = await axiosMobileInstance.post<Appraiser>(
    `/appraisers/${appraiserId}/regenerateLink`
  );
  return data;
};

export const getRawTransaction = async (id: string, propertyId: string) => {
  const { data } = await axiosMobileInstance.post<RawTransactionField[][]>(
    `/properties/${propertyId}/rc_api/transaction_details/raw`,
    undefined,
    {
      params: {
        transactionId: id,
      },
    }
  );

  return data;
};

export const getRawTransactionExcel = async (
  id: string,
  propertyId: string
) => {
  return await axiosMobileInstance.post(
    `/properties/${propertyId}/rc_api/transaction_details/raw`,
    undefined,
    {
      params: {
        transactionId: id,
        format: "excel",
      },
      responseType: "blob",
    }
  );
};

export const analyzePdfReport = async (file: File) => {
  const formData = new FormData();
  formData.append("report", file);

  try {
    const response = await axiosMobileInstance.post<AnalyzedReport>(
      "/upload/pdf",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const importTransactionsExcel = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axiosMobileInstance.post<TransactionsImportResult>(
    "/transactions/import",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

export const createComparableMessage = async (
  data: ComparableMessageCreateRequest
) => {
  const { data: responseData } =
    await axiosMobileInstance.post<ComparableMessage>(
      "/comparable/messages",
      data
    );

  return responseData;
};

export const updateComparableMessage = async (
  data: ComparableMessageCreateRequest,
  messageId: string
) => {
  const { data: responseData } =
    await axiosMobileInstance.put<ComparableMessage>(
      `/comparable/messages/${messageId}`,
      data
    );

  return responseData;
};

export const deleteComparableMessage = async (messageId: string) => {
  await axiosMobileInstance.delete<ComparableMessage>(
    `/comparable/messages/${messageId}`
  );
};

export const getComparableMessages = async (transactionId: string) => {
  const { data } = await axiosMobileInstance.get<ComparableMessage[]>(
    `/comparable/messages`,
    {
      params: {
        transactionId,
      },
    }
  );

  return data;
};

export const getMessagesStatus = async () => {
  const { data } = await axiosMobileInstance.get<{
    [key: string]: StatusMessage;
  }>(`/comparable/messages/status`);

  return data;
};

export const getValuationZonesFromBoundingBox = async (
  latMin: number,
  latMax: number,
  lngMin: number,
  lngMax: number,
  signal: AbortSignal | undefined
) => {
  const { data } = await axiosMobileInstance.get<ValuationZoneFull[]>(
    "/valuation_zone/bounding_box",
    {
      params: {
        latMin,
        latMax,
        lngMin,
        lngMax,
      },
      signal,
    }
  );
  return data;
};

export const getValuationZone = async (lat: number, lng: number) => {
  const { data } = await axiosMobileInstance.get<string>("/valuation_zone", {
    params: {
      lat,
      lng,
    },
  });

  return data;
};

export const getAdjustmentsProvidersByField = async (field: string) => {
  const { data } = await axiosMobileInstance.post<AdjustmentProvider[]>(
    `/valuations_proxy`,
    {
      method: "GET",
      path: "/adjustments/providers",
      params: {
        fieldname: field,
      },
      attachAppraiserId: true,
    }
  );

  return data;
};

export const getListingsWithParking = async (
  lat: number,
  lng: number,
  radius: number,
  parking_type: ParkingType | undefined
) => {
  const { data } = await axiosMobileInstance.post<ListingsWithAddons>(
    "/valuations_proxy",
    {
      path: "/listings/parkings",
      method: "GET",
      params: {
        lat,
        lng,
        max_distance: radius,
        parking_type,
      },
    }
  );

  return data;
};

export const getListingsWithStorage = async (
  lat: number,
  lng: number,
  radius: number
) => {
  const { data } = await axiosMobileInstance.post<ListingsWithAddons>(
    "/valuations_proxy",
    {
      path: "/listings/storages",
      method: "GET",
      params: {
        lat,
        lng,
        max_distance: radius,
      },
    }
  );

  return data;
};

export const getAllMatches = async (transaction: ComparableTransaction) => {
  const { data } = await axiosMobileInstance.post<{
    listings: AnyTransaction[];
    projects: AnyTransaction[];
    project_units: AnyTransaction[];
  }>(`/valuations_proxy`, {
    method: "POST",
    path: "/matching/all",
    body: transaction,
  });
  return data;
};

export const getAdjustmentsByField = async ({
  comparable,
  field,
  provider,
  propertyId,
}: {
  comparable: ComparableTransaction;
  field: string;
  provider: string;
  propertyId: string;
}) => {
  const { data } = await axiosMobileInstance.post<AdjustedField>(
    "/adjustments/by_field",
    {
      comparable,
      field,
      provider,
    },
    {
      params: {
        propertyId,
      },
    }
  );
  return data;
};

export const getAdminAdjustments = async () => {
  const { data } = await axiosMobileInstance.get<AdminAdjustments>(
    "/adjustments/admin"
  );
  return data;
};

export const setAdminAdjustment = async ({
  provider,
  field,
  description,
}: {
  provider: string;
  field: string;
  description: string;
}) => {
  await axiosMobileInstance.post("/adjustments/admin", {
    provider,
    field,
    description,
  });
};

export const setAdminAdjustmentAdditional = async ({
  name,
  appraiserPropertyFieldId,
  description,
}: {
  name: string;
  appraiserPropertyFieldId: string;
  description: string;
}) => {
  await axiosMobileInstance.post("/adjustments/admin/additional", {
    name,
    appraiserPropertyFieldId,
    description,
  });
};

export const getAdjustmentsDescriptions = async () => {
  const { data } = await axiosMobileInstance.get<AdjustmentDescription[]>(
    "/adjustments/descriptions"
  );
  return data;
};

export const getAdjustmentsDescriptionsForComparable = async (
  propertyId: string
) => {
  const { data } = await axiosMobileInstance.get<
    AdjustmentDescriptionForComparable[]
  >("/adjustments/descriptions_comparables", {
    params: {
      propertyId,
    },
  });
  return data;
};

export const getPropertyShare = async (token: string) => {
  const { data } = await axiosMobileInstance.get<PropertyShared>(
    `/appraiserProperties/${token}/share`,
    {
      params: {
        token,
      },
    }
  );
  return data;
};

export const getPropertyValuation = async (id: string) => {
  const { data } = await axiosMobileInstance.get<PropertyShared>(
    `/valuations/${id}`
  );
  return data;
};

export const getBasicAppraiser = async (appraiserId: string) => {
  const { data } = await axiosMobileInstance.get<{
    appraiser?: BasicAppraiser;
    organization: BasicOrganization;
  }>(`/appraisers/basic/${appraiserId}`);
  return data;
};

export const getAppraiserOrganizationLink = async () => {
  const { data } = await axiosMobileInstance.get<{ appraiserId: string }>(
    `/organization_link`
  );
  return data;
};

export const getPaymentMethods = async () => {
  const { data } = await axiosMobileInstance.get<PaymentMethods>(
    "/payments/methods"
  );
  return data;
};

export const createReportOrder = async ({
  propertyToken,
  selectedMethod,
  methodType,
  discountCode,
  deliveryTime,
  deliveryDate,
  depositChecked,
}: {
  propertyToken: string;
  selectedMethod: string | undefined;
  methodType: PaymentMethodType;
  deliveryTime: PropertyDeliveryTimeType;
  deliveryDate: string;
  discountCode?: string;
  depositChecked?: boolean;
}) => {
  const { data } = await axiosMobileInstance.post<{
    paymentUrl: string;
  }>(
    `/payments/create/report`,
    {
      selectedMethod,
      methodType,
      discountCode,
      deliveryTime,
      deliveryDate,
      depositChecked,
    },
    {
      params: {
        token: propertyToken,
      },
    }
  );
  return data;
};

export const generatePropertyShareLink = async (reqData: {
  propertyId: string;
  permissions: PropertyPermission[];
  pdf?: boolean;
}) => {
  const { data } = await axiosMobileInstance.post<{
    link: string;
    pdfUrl?: string;
  }>(
    `/appraiserProperties/${reqData.propertyId}/link`,
    {
      permissions: reqData.permissions,
    },
    {
      params: {
        pdf: reqData.pdf,
      },
    }
  );
  return data;
};

export const setLeadContactPermissions = async (reqData: {
  propertyId: string;
  permissions: PropertyPermission[];
}) => {
  const { data } = await axiosMobileInstance.post<{
    link: string;
  }>(`/appraiserProperties/${reqData.propertyId}/leadContact/permissions`, {
    permissions: reqData.permissions,
  });
  return data;
};

export const sendPaymentNotification = async (token: string) => {
  const { data } = await axiosMobileInstance.post<{ link: string | null }>(
    `/payments/notification?order-token=${token}`
  );
  return data;
};

export const setAppraiserProfileInfo = async (data: AppraiserProfile) => {
  await axiosMobileInstance.put("/appraisers/profile", data);
};

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axiosMobileInstance.post<{ url: string }>(
    "/images/upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const getReportPrices = async (
  appraiserId: string,
  simpleType?: PropertyType,
  discountCode?: string,
  selectedAddons?: PropertyAddonType[],
  deliveryTime?: PropertyDeliveryTimeType,
  cityId?: number
) => {
  const { data } = await axiosMobileInstance.get<TotalReportPrice>(
    "/payments/report_prices",
    {
      params: {
        appraiserId,
        simpleType,
        discountCode,
        selectedAddons,
        deliveryTime,
        cityId,
      },
    }
  );
  return data;
};

export const updatePropertyLeadContact = async ({
  propertyId,
  expectedValue,
  leadContact,
}: {
  propertyId: string;
  expectedValue?: number | null;
  leadContact: LeadContact;
}) => {
  await axiosMobileInstance.put(
    `/appraiserProperties/${propertyId}/lead`,
    { leadContact, expectedValue },
    {
      headers: {
        ...getPropertyTokenHeader(propertyId),
      },
    }
  );
};

export const getPropertyComment = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<PropertyComment | null>(
    `/propertyComment/${propertyId}`
  );
  return data;
};

export const updatePropertyComment = async ({
  propertyId,
  comment,
}: {
  propertyId: string;
  comment: string;
}) => {
  const { data } = await axiosMobileInstance.post<PropertyComment>(
    `/propertyComment/${propertyId}`,
    { comment }
  );
  return data;
};

export const getDiscountCodes = async () => {
  const { data } = await axiosMobileInstance.get<DiscountCode[]>(
    "/discountCodes"
  );
  return data;
};

export const createDiscountCode = async (data: DiscountCodeCreateRequest) => {
  const { data: responseData } = await axiosMobileInstance.post<DiscountCode>(
    "/discountCodes",
    data
  );
  return responseData;
};

export const updateDiscountCode = async (
  data: DiscountCodeCreateRequest & { id: string }
) => {
  const { data: responseData } = await axiosMobileInstance.put<DiscountCode>(
    `/discountCodes/${data.id}`,
    data
  );
  return responseData;
};

export const deleteDiscountCode = async (id: string) => {
  await axiosMobileInstance.delete<DiscountCode>(`/discountCodes/${id}`);
};

export const getPropertyReportPrice = async (
  propertyId: string,
  discountCode?: string,
  deliveryTime?: PropertyDeliveryTimeType,
  depositChecked?: boolean
) => {
  const { data } = await axiosMobileInstance.get<TotalReportPrice>(
    "/appraiserProperties/report/price",
    {
      params: {
        discountCode,
        deliveryTime,
        depositChecked,
      },
      headers: {
        ...getPropertyTokenHeader(propertyId),
      },
    }
  );

  return data;
};

export const setPropertyAppraiser = async ({
  propertyId,
  appraiserId,
}: {
  propertyId: string;
  appraiserId: string;
}) => {
  await axiosMobileInstance.post(
    `/appraiserProperties/${propertyId}/setAppraiser`,
    {},
    {
      params: {
        appraiserId,
      },
    }
  );
};

export const getPropertyLocationDetails = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<PropertyLocationDetails[]>(
    `/appraiserProperties/${propertyId}/locationDetails`
  );
  return data;
};

export const regenerateLocationDetails = async (locationDetailsId: string) => {
  await axiosMobileInstance.post(
    `/locationDetails/${locationDetailsId}/regenerate`
  );
};

export const getPropertyInfra = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<PropertyInfra[]>("/infra", {
    params: {
      propertyId,
    },
  });

  return data;
};

export const verifyReport = async ({
  propertyId,
  sendEmail,
}: {
  propertyId: string;
  sendEmail: boolean;
}) => {
  const { data } = await axiosMobileInstance.post(
    `/appraiserProperties/${propertyId}/verify`,
    {},
    {
      params: {
        sendEmail,
      },
    }
  );
  return data;
};

export const sendPropertyReminder = async (propertyId: string) => {
  await axiosMobileInstance.post(`/appraiserProperties/${propertyId}/reminder`);
};

export const createCompositeAsset = async (data: CompositeAssetRequest) => {
  const { data: d } = await axiosMobileInstance.post("/compositeAssets", data, {
    headers: {
      ...getPropertyTokenHeader(data.propertyId),
    },
  });
  return d;
};

export const updateCompositeAsset = async (
  id: string,
  data: CompositeAssetRequest
) => {
  const { data: d } = await axiosMobileInstance.put(
    `/compositeAssets/${id}`,
    data,
    {
      headers: {
        ...getPropertyTokenHeader(data.propertyId),
      },
    }
  );
  return d;
};

export const deleteCompositeAsset = async (id: string, propertyId: string) => {
  await axiosMobileInstance.delete(`/compositeAssets/${id}`, {
    headers: {
      ...getPropertyTokenHeader(propertyId),
    },
  });
};

export const getCompositeAssets = async (
  propertyId: string,
  pricePredictionId?: string
) => {
  const { data: d } = await axiosMobileInstance.get<CompositeAsset[]>(
    "/compositeAssets",
    {
      params: {
        propertyId,
        pricePredictionId,
      },
      headers: {
        ...getPropertyTokenHeader(propertyId),
      },
    }
  );
  return d;
};

export const createConfirmation = async (
  data: ConfirmationRequest,
  propertyId: string
) => {
  await axiosMobileInstance.post("/confirmation", data, {
    params: { propertyId },
  });
};

export const getLastConfirmation = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<Confirmation>(
    "/confirmation",
    { params: { propertyId } }
  );
  return data;
};

export const uploadPropertyFile = async (propertyId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  await axiosMobileInstance.post(`/propertyFiles`, formData, {
    params: {
      propertyId,
    },
  });
};

export const uploadPropertyFileCustomer = async (
  propertyId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("file", file);

  await axiosMobileInstance.post(`/propertyFiles/byCustomer`, formData, {
    headers: {
      ...getPropertyTokenHeader(propertyId),
    },
    params: {
      propertyId,
    },
  });
};

export const deletePropertyFile = async (
  propertyId: string,
  fileId: string
) => {
  await axiosMobileInstance.delete(`/propertyFiles`, {
    params: {
      propertyId,
      fileId,
    },
  });
};

export const deletePropertyFileCustomer = async (
  propertyId: string,
  fileId: string
) => {
  await axiosMobileInstance.delete(`/propertyFiles/byCustomer`, {
    headers: {
      ...getPropertyTokenHeader(propertyId),
    },
    params: {
      propertyId,
      fileId,
    },
  });
};

export const getPropertyFiles = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<UploadedFile[]>(
    `/propertyFiles`,
    {
      params: {
        propertyId,
      },
    }
  );
  return data;
};

export const getPropertyFilesFromCustomer = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<UploadedFile[]>(
    `/propertyFiles/byCustomer`,
    {
      headers: {
        ...getPropertyTokenHeader(propertyId),
      },
      params: {
        propertyId,
      },
    }
  );
  return data;
};

export const getPropertyAddonPrices = async () => {
  const { data } = await axiosMobileInstance.get<PropertyAddonPrices>(
    `/propertyAddons/prices`
  );
  return data;
};

export const getDeliveryTimeConfig = async () => {
  const { data } = await axiosMobileInstance.get<DeliveryTimeConfiguration[]>(
    `/deliveryTime/config`
  );
  return data;
};

export const getPropertyPurchasedAddons = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<PropertyAddonWithPrice[]>(
    `/propertyAddons/purchased`,
    {
      params: {
        propertyId,
      },
    }
  );
  return data;
};

export const getPropertyPurchasedCompositeAssets = async (
  propertyId: string
) => {
  const { data } = await axiosMobileInstance.get<CompositeAsset[]>(
    `/compositeAssets/purchased`,
    {
      params: {
        propertyId,
      },
    }
  );
  return data;
};

export const getMarketInsights = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<ChartData[]>(
    `/marketInsights`,
    {
      params: {
        propertyId,
      },
    }
  );
  return data;
};

export const getSimilarPricePredictions = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<SimilarPricePrediction[]>(
    `/properties/${propertyId}/pricePredictions/similar`
  );
  return data;
};

export const setOrganizationProfile = async (data: OrganizationProfile) => {
  const { data: retData } = await axiosMobileInstance.put<Organization>(
    "/organization/profile",
    data
  );

  return retData;
};

export const getDepositPrices = async () => {
  const { data } = await axiosMobileInstance.get<DepositPrices>(
    "/payments/deposit_prices"
  );
  return data;
};

export const setSimilarPricePredictions = async (
  propertyId: string,
  predictionsPropertyId: string
) => {
  await axiosMobileInstance.post(
    `/properties/${propertyId}/pricePredictions/similar`,
    {},
    { params: { predictionsPropertyId } }
  );
};

export const getDepositPaid = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<DepositPaid>(
    `/appraiserProperties/${propertyId}/deposits/total`
  );

  return data;
};

export const getAppraiserPropertiesStatistics = async (
  startMonth: number,
  startYear: number,
  endMonth: number,
  endYear: number
) => {
  const { data } = await axiosMobileInstance.get<PropertyStatistics[]>(
    "/appraiserPropertiesStatistics",
    {
      params: {
        startMonth,
        startYear,
        endMonth,
        endYear,
      },
    }
  );
  return data;
};

export const getReconstructionYearFromListings = async (
  lat: number,
  lng: number
) => {
  const { data } = await axiosMobileInstance.post<{
    year_reconstruction: number | null;
  }>("/valuations_proxy", {
    method: "GET",
    path: "/listings/year_reconstruction",
    params: {
      lat,
      lng,
    },
  });
  return data;
};

export const getPropertyEmailTemplates = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<PropertyEmailTemplate[]>(
    `/appraiserProperties/${propertyId}/messages/templates`
  );
  return data;
};

export const createPropertyLeadMessage = async (
  propertyId: string,
  message: CreatePropertyLeadMessage
) => {
  await axiosMobileInstance.post(
    `/appraiserProperties/${propertyId}/messages`,
    message
  );
};

export const getPropertyLeadMessages = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<PropertyLeadMessage[]>(
    `/appraiserProperties/${propertyId}/messages`
  );

  return data;
};

export const getAdjustmentCoeffs = async (
  field: string,
  provider: string,
  propertyId: string,
  comparable: ComparableTransactionFull | undefined
) => {
  const { data } =
    await axiosMobileInstance.post<TransparencyAdjustmentsCoeffs>(
      `/valuations_proxy`,
      {
        method: "POST",
        path: "/adjustments/coeffs",
        params: {
          field,
          provider,
        },
        body: {
          comparable: comparable ?? {},
        },
        attachAppraiserId: true,
        attachProperty: !comparable,
      },
      {
        params: {
          propertyId,
        },
      }
    );

  return data;
};

export const getAdjustmentTable = async (
  field: string,
  provider: string,
  propertyId: string
) => {
  const { data } = await axiosMobileInstance.post<AdjustmentTableRow[] | null>(
    `/valuations_proxy`,
    {
      method: "POST",
      path: "/adjustments/table",
      params: {
        field,
        provider,
      },
      body: {
        comparable: {},
      },
      attachAppraiserId: true,
      attachProperty: true,
    },
    {
      params: {
        propertyId,
      },
    }
  );

  return data;
};

export const getSchoolExamsResults = async (
  propertyId: string,
  examsId: string,
  token?: string
) => {
  const { data } = await axiosMobileInstance.get<SchoolExamResults>(
    `/schoolVbeExams`,
    {
      params: {
        propertyId,
        examsId,
      },
      headers: {
        "x-property-token": token,
      },
    }
  );

  return data;
};

export const getNearbySchools = async (
  propertyId: string,
  examsId: string,
  token?: string
) => {
  const { data } = await axiosMobileInstance.get<NearbySchools>(
    `/schoolNearbyRating`,
    {
      params: {
        propertyId,
      },
      headers: {
        "x-property-token": token,
      },
    }
  );

  return data;
};

export const getSchoolRating = async (
  propertyId: string,
  examsId: string,
  token?: string
) => {
  const { data } = await axiosMobileInstance.get<SchoolRating>(
    `/schoolRating`,
    {
      params: {
        propertyId,
        examsId,
      },
      headers: {
        "x-property-token": token,
      },
    }
  );

  return data;
};
