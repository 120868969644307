import { Comparable, ComparableFull } from "common/types/common.types";
import axiosMobileInstance from "./axiosMobileInstance/axiosMobileInstance";
import {
  TransactionsSearchResponse,
  UsedCreditsByMonth,
  UsedCreditsRow,
} from "../types/api.types";
import { MonthDate } from "../components/datepicker/types";

export const rcTransactionsSearch = async (
  queryData: any,
  propertyId: string,
  allowBuy: boolean,
  saveResults: boolean
) => {
  const { data } = await axiosMobileInstance.post<TransactionsSearchResponse>(
    `/properties/${propertyId}/rc_api/transactions`,
    queryData,
    {
      params: {
        allow_buy: allowBuy,
        save_results: saveResults,
      },
    }
  );
  return data;
};

export const rcTransactionDetails = async (
  transactionIds: string[],
  propertyId: string
) => {
  const { data } = await axiosMobileInstance.post<ComparableFull[]>(
    `/properties/${propertyId}/rc_api/transaction_details`,
    undefined,
    {
      params: {
        transactionIds: transactionIds.join(","),
      },
    }
  );

  return data;
};

export const getUsedCreditsForProperty = async (propertyId: string) => {
  const { data } = await axiosMobileInstance.get<UsedCreditsRow[]>(
    `/properties/${propertyId}/credits`
  );

  return data;
};

export const getUsedCreditsByMonth = async (
  startDate: MonthDate,
  endDate: MonthDate
) => {
  const { data } = await axiosMobileInstance.get<UsedCreditsByMonth[]>(
    `/transactions_history/statistics`,
    {
      params: {
        startYear: startDate.year,
        startMonth: startDate.month,
        endYear: endDate.year,
        endMonth: endDate.month,
      },
    }
  );

  return data;
};
