import React, { useContext, useMemo } from "react";
import { useComparablesData } from "../../hooks/property.hooks";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  ChartData,
  ChartDataItem,
  ChartSection,
  ChartType,
} from "../../types/api.types";
import BaseChart from "../charts/BaseChart";
import { SliderWithInput } from "../form/SliderWithInput";
import { ComparablesFiltersContext } from "../ComparablesFilterProvider";
import {
  ComparableFilterType,
  getFinishingFilterFunction,
  getPriceAreaFilterFunction,
} from "../../helpers/comparableFilters.helpers";
import _ from "lodash";
import colors from "common/styles/colors";
import { addAlpha } from "common/helpers/colors.helpers";
import { PriceBreakdownChart } from "../charts/PriceBreakdownChart";
import { formatAreaPrice } from "common/helpers/text.helpers";

interface PriceFilterProps {}

const priceAreaStep = 100;

export const PriceFilter: React.FC<PriceFilterProps> = () => {
  const { dataFull: comparablesFull } = useComparablesData();

  const { addFilter, getAppliedFilter, removeFilter } = useContext(
    ComparablesFiltersContext
  );

  if (comparablesFull.length <= 1) {
    return null;
  }

  const getChartData = () => {
    // Create price ranges with 100€/m² steps
    const priceRanges: { [key: number]: number } = {};

    const prices = comparablesFull.map(
      (comp) =>
        Math.floor(
          comp.comparable_transaction.sold_price_area / priceAreaStep
        ) * priceAreaStep
    );

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    for (let i = minPrice; i <= maxPrice; i += priceAreaStep) {
      if (!priceRanges[i]) {
        priceRanges[i] = 0;
      }
    }

    prices.forEach((price) => {
      priceRanges[price] = priceRanges[price] + 1;
    });

    const chartDataItems = Object.entries(priceRanges).map(
      ([range, count]) => ({
        x: Number(range),
        y: count,
      })
    );
    return chartDataItems;
  };

  const chartData = getChartData();
  const chartDataWithLabels = chartData.map((item) => ({
    ...item,
    x: `${item.x + priceAreaStep} €/m²`,
  }));

  const defaultValues = [
    chartData[0].x,
    chartData[chartData.length - 1].x + priceAreaStep,
  ];

  const selectedValues =
    getAppliedFilter?.(ComparableFilterType.PriceArea)?.values ?? defaultValues;

  return (
    <div>
      <PriceBreakdownChart
        prices={comparablesFull.map(
          (comp) => comp.comparable_transaction.sold_price_area
        )}
        priceStep={priceAreaStep}
        priceFormatter={(price) => formatAreaPrice(price)}
        onFilterChange={(minPrice, maxPrice) => {
          if (minPrice === defaultValues[0] && maxPrice === defaultValues[1]) {
            removeFilter?.(ComparableFilterType.PriceArea);
            return;
          }

          addFilter?.({
            type: ComparableFilterType.PriceArea,
            values: [minPrice, maxPrice],
            filterFunction: getPriceAreaFilterFunction([minPrice, maxPrice]),
          });
        }}
        selectedPrices={selectedValues}
      />
    </div>
  );
};
