import { GoogleMap, OverlayViewF } from "@react-google-maps/api";
import { MatchedListing } from "common/types/common.types";
import React, { useMemo, forwardRef, useImperativeHandle } from "react";
import { ComparableMarker } from "../map/ComparableMarker";
import { useParams } from "react-router-dom";
import { useProperty } from "../../hooks/property.hooks";
import { ClusterOnMap } from "../../types/map.types";
import { ComparableIconOverlayView } from "../map/ComparableIconOverlayView";
import { mainPropertyBGColor } from "../../hooks/propertyIcons.hooks";
import { MY_PROPERTY_SIZE_MULTIPLIER } from "../map/constants";
import { getGoogleMapsBoundsFromCoords } from "../../helpers/map.helpers";
import { map } from "lodash";

interface AddonMapProps {
  listings: MatchedListing[];
  mapCardComponent: (listing: MatchedListing) => React.ReactNode;
  mapPriceFn: (listing: MatchedListing) => number;
}

export interface AddonMapRef {
  setSelectedListing: (listingId: MatchedListing) => void;
}

export const AddonMap = forwardRef<AddonMapRef, AddonMapProps>(
  ({ listings, mapCardComponent, mapPriceFn }, ref) => {
    const { propertyId } = useParams();
    const { data: property } = useProperty(propertyId ?? "");

    const [selectedListing, setSelectedListing] = React.useState<string>();

    const getListingId = (listing: MatchedListing) =>
      `${listing.lat}|${listing.lng}`;

    useImperativeHandle(ref, () => ({
      setSelectedListing: (listing: MatchedListing) => {
        setSelectedListing(getListingId(listing));
        mapRef.current?.panTo({
          lat: listing.lat,
          lng: listing.lng,
        });
      },
    }));

    const center = useMemo(() => {
      if (!property) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: property?.lat,
        lng: property?.lng,
      };
    }, [property?.lat, property?.lng]);

    const mapRef = React.useRef<google.maps.Map | null>(null);

    const groupedListings = useMemo(() => {
      const grouped = listings.reduce((acc, listing) => {
        const key = `${listing.lat}|${listing.lng}`;
        acc[key] = listing;
        return acc;
      }, {} as Record<string, MatchedListing>);

      return Object.values(grouped);
    }, [listings]);

    const getInitialBounds = () => {
      return getGoogleMapsBoundsFromCoords([
        ...(groupedListings?.map((l) => ({
          lat: l.lat,
          lng: l.lng,
        })) ?? []),
        {
          lat: property?.lat ?? 0,
          lng: property?.lng ?? 0,
        },
      ]);
    };

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
      mapRef.current = map;
      mapRef.current.fitBounds(getInitialBounds());
    }, []);

    if (!property) {
      return null;
    }

    return (
      <GoogleMap
        onLoad={onLoad}
        options={{
          fullscreenControl: true,
          clickableIcons: false,
          mapTypeControl: false,
          gestureHandling: "greedy",
        }}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        zoom={15}
        center={center}
        onClick={() => setSelectedListing(undefined)}
      >
        {groupedListings.map((listing) => {
          const listingId = getListingId(listing);

          return (
            <ComparableMarker
              key={listingId}
              isSelected={selectedListing === listingId}
              markerProps={{
                lat: listing.lat,
                lng: listing.lng,
                onClick: () => setSelectedListing(listingId),
                customText: `${Math.ceil(mapPriceFn(listing) / 1000)}k`,
              }}
            >
              {mapCardComponent(listing)}
            </ComparableMarker>
          );
        })}
        <ComparableIconOverlayView
          lat={property.lat}
          lng={property.lng}
          backgroundColor={mainPropertyBGColor}
          sizeMultiplier={MY_PROPERTY_SIZE_MULTIPLIER}
        />
      </GoogleMap>
    );
  }
);
