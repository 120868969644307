import { Button, MenuItem, Select, TextField } from "@mui/material";
import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import React from "react";
import { SimpleDialog } from "../../components/dialogs/SimpleDialog";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  createPropertyLeadMessage,
  getPropertyEmailTemplates,
  getPropertyLeadMessages,
} from "../../api/api";
import {
  CreatePropertyLeadMessage,
  CreateTemplateMessagePart,
} from "../../types/api.types";
import { showToastError } from "common/toast/toast";
import {
  getLithuanianDateString,
  getLithuanianDateTimeString,
} from "common/helpers/dateStringHelpers";
import { usePropertyEditRights } from "../../hooks/property.hooks";
import { CenteredContentWrapper } from "../../components/wrappers/CenteredContentWrapper";

interface PropertyMessagesRouteProps {}

export const PropertyMessagesRoute: React.FC<
  PropertyMessagesRouteProps
> = () => {
  const [open, setOpen] = React.useState(false);
  const { propertyId } = useParams();
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<
    string | null
  >(null);
  const { data: templates } = useQuery(
    ["propertyTemplates", propertyId ?? ""],
    () => getPropertyEmailTemplates(propertyId ?? ""),
    {
      onSuccess: (data) => {
        if (data.length > 0) {
          setSelectedTemplateId(data[0].id);
        }
      },
    }
  );

  const [messages, setMessages] = React.useState<
    Record<string, CreateTemplateMessagePart[]>
  >({});

  const selectedTemplate = templates?.find((t) => t.id === selectedTemplateId);

  const getCurrentMessage = (param: string) => {
    return (
      messages[selectedTemplateId ?? ""]?.find((m) => m.param === param) ??
      selectedTemplate?.messageParts.find((m) => m.param === param)
    )?.text;
  };

  const setCurrentMessage = (param: string, message: string) => {
    const newMessageParts =
      messages[selectedTemplateId ?? ""]?.filter((m) => m.param !== param) ??
      [];
    newMessageParts.push({
      param,
      text: message,
    });

    setMessages({
      ...messages,
      [selectedTemplateId ?? ""]: newMessageParts,
    });
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading: isMutating } = useMutation(
    (message: CreatePropertyLeadMessage) =>
      createPropertyLeadMessage(propertyId ?? "", message),
    {
      onSuccess: () => {
        setOpen(false);
        queryClient.invalidateQueries(["propertyMessages", propertyId ?? ""]);
      },
    }
  );

  const { data: sentMessages } = useQuery(
    ["propertyMessages", propertyId ?? ""],
    () => getPropertyLeadMessages(propertyId ?? "")
  );

  const { canEdit } = usePropertyEditRights(propertyId ?? "");

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Pranešimai</Typography>
        {canEdit && (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
          >
            Naujas pranešimas
          </Button>
        )}
      </div>
      <div
        style={{
          marginTop: "30px",
          backgroundColor: colors.white,
          padding: "20px",
          minHeight: "200px",
          borderRadius: "10px",
        }}
      >
        {sentMessages?.map((message) => (
          <div
            key={message._id}
            style={{
              padding: "10px",
              border: `1px solid ${colors.grey}`,
              borderRadius: "5px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h3">{message.title}</Typography>
              <Typography variant="caption">
                {getLithuanianDateTimeString(message.createdAt)}
              </Typography>
            </div>
            <Typography>{message.message}</Typography>
          </div>
        ))}
        {!sentMessages?.length && (
          <CenteredContentWrapper style={{ marginTop: "60px" }}>
            <Typography variant="caption">
              Išsiųstų pranešimų dar nėra
            </Typography>
          </CenteredContentWrapper>
        )}
      </div>
      <SimpleDialog
        title="Naujas pranešimas"
        open={open}
        onClose={() => setOpen(false)}
        buttonText="Išsiųsti"
        isLoading={isMutating}
        onSubmit={() => {
          if (selectedTemplateId === null) {
            showToastError("Pasirinkite šabloną");
            return;
          }

          mutate({
            messageParts: messages[selectedTemplateId ?? ""] ?? [],
            templateId: selectedTemplateId ?? "",
            title:
              templates?.find((t) => t.id === selectedTemplateId)
                ?.templateName ?? "",
          });
        }}
      >
        <div
          style={{
            marginBottom: "5px",
          }}
        >
          <Typography textStyle={{ fontWeight: "500" }}>
            Pranešimo šablonas
          </Typography>
        </div>
        <Select
          value={selectedTemplateId}
          onChange={(e) => setSelectedTemplateId(e.target.value)}
        >
          {templates?.map((t) => (
            <MenuItem key={t.id} value={t.id}>
              {t.templateName}
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginTop: "20px", marginBottom: "5px" }}>
          <Typography textStyle={{ fontWeight: "500" }}>
            Pranešimo tekstas
          </Typography>
        </div>
        <div
          style={{
            border: `1px solid ${colors.greyborder}`,
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            gap: "10px",
            borderRadius: "5px",
          }}
        >
          {selectedTemplate?.messageParts.map((p) =>
            !!p.param ? (
              <TextField
                multiline
                rows={4}
                value={getCurrentMessage(p.param)}
                onChange={(e) => {
                  setCurrentMessage(p.param ?? "", e.target.value);
                }}
              />
            ) : (
              <Typography>{p.text}</Typography>
            )
          )}
        </div>
      </SimpleDialog>
    </div>
  );
};
