import React from "react";
import { useRouteQuery } from "../../hooks/router.hooks";
import { PropertyImage } from "common/types/common.types";
import { PropertyGallery } from "./PropertyGallery";
import { AppraiserProperty } from "../../types/appraiser.types";
import { AppBar, Box, Button, Dialog, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { pageContainerSxProps } from "../../components/containers/PageContainer";
import colors from "common/styles/colors";

interface PropertyWithGalleryProps {
  property: AppraiserProperty;
  children: React.ReactElement;
}

export const PropertyWithGallery: React.FC<PropertyWithGalleryProps> = ({
  property,
  children,
}) => {
  const query = useRouteQuery();

  const showGallery = query.get("showGallery");
  const navigate = useNavigate();

  const headerHeight = "70px";

  return (
    <>
      <Dialog fullScreen open={showGallery === "true"}>
        <div
          style={{
            backgroundColor: colors.bg,
          }}
        >
          <div
            style={{
              position: "fixed",
              zIndex: 10000,
              height: headerHeight,
              width: "100%",
              backgroundColor: colors.white,
              display: "flex",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              color="inherit"
            >
              <ArrowBackIosIcon />
            </IconButton>
          </div>
          <Box sx={{ ...pageContainerSxProps, marginTop: headerHeight }}>
            <PropertyGallery property={property} />
          </Box>
        </div>
      </Dialog>
      {children}
    </>
  );
};
