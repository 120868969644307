import {
  MatchedListing,
  ParkingTypeTranslations,
} from "common/types/common.types";
import React from "react";
import { BasePropertyCard } from "../cards/BasePropertyCard";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { Button } from "@mui/material";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import colors from "common/styles/colors";

interface AddonListingCardProps {
  listing: MatchedListing;
  price: number;
  priceArea?: number;
  caption?: string;
  onClick?: () => void;
}

export const AddonListingCard: React.FC<AddonListingCardProps> = ({
  listing,
  price,
  priceArea,
  caption,
  onClick,
}) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);

  return (
    <BasePropertyCard
      images={listing.images}
      address={listing.address}
      price={price}
      priceArea={priceArea}
      dateText={`Pardavimo data: ${
        listing.sold_date
          ? getLithuanianDateString(listing.sold_date)
          : "Aktyvus"
      }`}
      caption={caption}
      onClick={onClick}
    >
      <Button
        onClick={() => {
          setDetailsOpen(true);
        }}
        sx={{
          alignSelf: "flex-end",
        }}
      >
        Daugiau
      </Button>
      <SimpleDialog
        title={listing.address ?? ""}
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        hideButton
      >
        <div
          style={{
            marginBottom: "10px",
            fontWeight: 500,
          }}
        >
          Aprašymas
        </div>
        <div>{listing.description}</div>
      </SimpleDialog>
    </BasePropertyCard>
  );
};
