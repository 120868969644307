import React, { useContext } from "react";
import { useFinalPricePredictions } from "../../hooks/pricePredictions.hooks";
import { formatPrice } from "common/helpers/text.helpers";
import { AdjustmentsMap } from "../map/AdjustmentsMap";
import { AppraiserProperty } from "../../types/appraiser.types";
import { Typography } from "common/components/Typography";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { Button } from "@mui/material";
import { Divider } from "common/components/listItems/Divider";
import { LinkForButton } from "../links/LinkForButton";
import { PredictionOverviewPrice } from "./PredictionOverviewPrice";
import { PredictionOverviewComparables } from "./PredictionOverviewComparables";
import { PredictionCompositeAssets } from "../PredictionCompositeAssets";
import { PredictionNotEvaluated } from "./PredictionNotEvaluated";
import { PredictionsConfidenceMeasure } from "../../routes/property/PredictionsConfidenceMeasure";

interface PredictionOverviewProps {
  property: AppraiserProperty;
  hasEditRights: boolean;
}

export const PredictionOverview: React.FC<PredictionOverviewProps> = ({
  property,
  hasEditRights,
}) => {
  const { data, isLoading } = useFinalPricePredictions(property._id);

  if (isLoading || !data) {
    return null;
  }

  const comparablesSelectionUri = `/properties/${property._id}/comparables`;

  const editButton = hasEditRights ? (
    <LinkForButton to={comparablesSelectionUri}>
      <Button>koreguoti</Button>
    </LinkForButton>
  ) : null;

  if (!data.predicted_price.average_price_area || !data.comparables.length) {
    return (
      <PredictionNotEvaluated
        hasEditRights={hasEditRights}
        comparablesSelectionUri={comparablesSelectionUri}
        property={property}
      />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ alignItems: "center", display: "flex" }}>
        <Typography style={{ fontSize: 16, marginRight: "8px" }}>
          {`Įvertinta ${getLithuanianDateString(data.updatedAt)}`}
        </Typography>
        {editButton}
      </div>
      <PredictionOverviewPrice
        predictedPrice={data.predicted_price}
        property={property}
      />
      <div
        style={{
          height: "20px",
        }}
      />
      {!!data._id && (
        <PredictionCompositeAssets
          propertyId={property._id}
          pricePredictionId={data._id}
        />
      )}
      <PredictionOverviewComparables
        editButton={editButton}
        comparables={data.comparables}
        property={property}
      />
      <div style={{ alignSelf: "flex-end", marginTop: "10px" }}>
        <LinkForButton to="valuation-summary">
          <Button>peržiūrėti vertinimą</Button>
        </LinkForButton>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          gap: "20px",
          justifyContent: "space-around",
        }}
      >
        {!!data.confidence && (
          <PredictionsConfidenceMeasure confidence={data.confidence} />
        )}
        <div>
          <Typography variant="h3">
            Analizuoti turto vertinimo skaičiavimus
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "50px",
              margin: "20px 0",
            }}
          >
            <div>Skaičiavimų analizė</div>
            <LinkForButton to="transparency">
              <Button>Peržiūrėti</Button>
            </LinkForButton>
          </div>
        </div>
      </div>
    </div>
  );
};
