import React from "react";
import { Typography } from "../Typography";
import { Text } from "react-native";
import { termsOfServiceLink } from "../../constants/externalLinks.constants";
import { openInBrowser } from "../../webBrowser/webBrowser";
import colors from "../../styles/colors";

interface PropertyFormCaptionProps {}

export const PropertyFormCaption: React.FC<PropertyFormCaptionProps> = () => {
  return (
    <Typography variant="caption">
      Kurdamas ataskaitą, sutinku su CityNow{" "}
      <Text
        onPress={() => openInBrowser(termsOfServiceLink)}
        style={{ color: colors.purple2 }}
      >
        naudojimosi sąlygomis
      </Text>
    </Typography>
  );
};
