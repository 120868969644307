import React, { useMemo, useRef } from "react";
import { ContentCard } from "../components/ContentCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  useComparablesSummary,
  usePricePredictions,
} from "../hooks/pricePredictions.hooks";
import { useMutation } from "react-query";
import { FinalPricePredictions } from "common/types/common.types";
import { addPricePrediction, createConfirmation } from "../api/api";
import { MUILoadingButton } from "../components/buttons/MUILoadingButton";
import { ComparablesSummaryTable } from "../components/comparablesSummary/ComparablesSummaryTable";
import { ValuationSummary } from "../components/comparablesSummary/ValuationSummary";
import {
  AdjustmentsJustification,
  AdjustmentsJustificationHandles,
} from "../components/comparablesSummary/AdjustmentsJustification";
import { CompositeAssets } from "../components/comparablesSummary/CompositeAssets";
import { PredictionsConfirmButton } from "./PredictionsConfirmButton";
import {
  ConfirmationForm,
  ConfirmationFormHandles,
} from "../components/comparablesSummary/ConfirmationForm";
import { ConfirmationRequest } from "../types/api.types";
import { showToastError } from "common/toast/toast";
import { useProperty } from "../hooks/property.hooks";
import { getErrorMessage } from "common/helpers/error.helpers";
import { useDeletePropertyToken } from "common/hooks/propertyDetails.hooks";

interface ComparablesSummaryProps {}

export const ComparablesSummary: React.FC<ComparablesSummaryProps> = () => {
  const { propertyId } = useParams();

  useDeletePropertyToken(propertyId ?? "");

  const { getFinalPricePredictions, pricePredictions } = usePricePredictions(
    propertyId ?? ""
  );

  const navigate = useNavigate();
  const {
    mutateAsync: mutateAsyncPredictions,
    isLoading: isLoadingPredictions,
  } = useMutation(async (pricePrediction: FinalPricePredictions) => {
    await addPricePrediction<FinalPricePredictions>(
      propertyId ?? "",
      pricePrediction,
      true
    );
  });

  const {
    mutateAsync: mutateAsyncConfirmation,
    isLoading: isLoadingConfirmation,
  } = useMutation(
    async (x: { data: ConfirmationRequest; propertyId: string }) =>
      createConfirmation(x.data, x.propertyId),
    {
      onError: (error) => {
        showToastError(getErrorMessage(error));
      },
    }
  );

  const [buttonPressed, setButtonPressed] = React.useState<"save" | "confirm">(
    "save"
  );

  const { comps } = useComparablesSummary(propertyId ?? "");

  const finalPricePredictions = useMemo(
    () => getFinalPricePredictions(),
    [getFinalPricePredictions]
  );

  const navigateToProperty = () => {
    navigate(`/properties/${propertyId}`);
  };

  const saveFinalPricePredictions = async (navigateOnSuccess: boolean) => {
    const final = getFinalPricePredictions();
    if (!final) return;
    final.descriptions = adjustmentsJustificationRef.current?.getDescriptions();
    await mutateAsyncPredictions(final);

    if (navigateOnSuccess) {
      navigateToProperty();
    }
  };

  const adjustmentsJustificationRef =
    React.useRef<AdjustmentsJustificationHandles>(null);

  const confirmationFormRef = useRef<ConfirmationFormHandles>(null);

  const isLoading = isLoadingConfirmation || isLoadingPredictions;

  const { data: property } = useProperty(propertyId ?? "");

  if (!propertyId) return null;

  return (
    <div style={{ padding: "15px 0" }}>
      <ContentCard title="Turto vertinimo informacija" borderRadius={0}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ComparablesSummaryTable
            finalPricePredictions={finalPricePredictions}
            propertyId={propertyId ?? ""}
            comps={comps}
          />
        </div>
        <ValuationSummary
          finalPricePredictions={finalPricePredictions}
          propertyId={propertyId ?? ""}
        />
        <div
          style={{
            height: "30px",
          }}
        />
        <AdjustmentsJustification
          ref={adjustmentsJustificationRef}
          comps={comps}
        />
      </ContentCard>
      <ContentCard title="Priklausomas turtas" borderRadius={0}>
        <CompositeAssets
          propertyId={propertyId}
          pricePredictionId={pricePredictions?._id}
        />
      </ContentCard>
      <ContentCard title="Patvirtinimas">
        <ConfirmationForm
          ref={confirmationFormRef}
          disabled={!!property?.reportVerifiedAt}
          propertyId={propertyId}
        />
      </ContentCard>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <MUILoadingButton
          loading={isLoading && buttonPressed === "save"}
          disabled={isLoading}
          onClick={async () => {
            setButtonPressed("save");
            await saveFinalPricePredictions(true);
          }}
          style={{
            width: "300px",
          }}
          variant="outlined"
        >
          Išsaugoti ir uždaryti
        </MUILoadingButton>
        <PredictionsConfirmButton
          disabled={isLoading}
          loading={isLoading && buttonPressed === "confirm"}
          propertyId={propertyId}
          onClick={async () => {
            if (property?.reportVerifiedAt) {
              await saveFinalPricePredictions(false);
              return true;
            }

            let error = false;
            await confirmationFormRef.current?.handleSubmit(
              async (data) => {
                setButtonPressed("confirm");

                await mutateAsyncConfirmation({
                  data,
                  propertyId,
                });

                await saveFinalPricePredictions(false);
              },
              (data) => {
                if (data.confirmCheckbox) {
                  showToastError("Patvirtinkite, kad sutinkate su sąlygomis");
                }
                error = true;
              }
            )();
            if (error) {
              return false;
            }
            return true;
          }}
          onConfirm={navigateToProperty}
        />
      </div>
    </div>
  );
};
