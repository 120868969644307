import { StyledImage } from "common/components/images/StyledImage";
import React, { useEffect } from "react";
import { BasicAppraiser } from "./BasicAppraiser";
import { ContentCard } from "../../components/ContentCard";
import { PropertyTypeOption } from "./PropertyTypeOption";
import { PropertyDeliveryTimeType, PropertyType } from "../../types/api.types";
import { useQuery } from "react-query";
import { getBasicAppraiser, getReportPrices } from "../../api/api";
import { LeadActionCard } from "./LeadActionCard";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { getDesignationTypeFromSimpleType } from "../../helpers/leadProperty.helpers";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { Box, Button, Link, Tooltip } from "@mui/material";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { BasicOrganization } from "./BasicOrganization";
import { AddonListSelect } from "../property/addons/AddonListSelect";
import { LeadLandingPageActionItem } from "./LeadLandingPageActionItem";
import { LinkForButton } from "../../components/links/LinkForButton";
import { Typography } from "common/components/Typography";
import { OrderedListItem } from "../../components/listItems/OrderedListItem";
import colors from "common/styles/colors";
import { DeliveryAddonSelect } from "../property/addons/DeliveryAddonSelect";
import { FullReportBanner } from "../property/FullReportBanner";
import { CityFormField } from "./addressInput/CityFormField";
import { FormProvider, useForm } from "react-hook-form";
import { formatPrice } from "common/helpers/text.helpers";
import { DesignationTypesForValuation } from "../../helpers/comparableFields.helpers";
import { useReportPrices } from "../../hooks/api.hooks";

interface LeadLandingPageProps {}

const valuationServices = [
  {
    label: "Butas",
    valationServiceLabel: "Buto kainos nustatymas",
    value: PropertyType.Apartment,
  },
  {
    label: "Namas",
    valationServiceLabel: "Namo kainos nustatymas",
    value: PropertyType.House,
  },
  {
    label: "Kotedžas",
    valationServiceLabel: "Kotedžo kainos nustatymas",
    value: PropertyType.Cottage,
  },
];

const howItWorks = [
  {
    title: "Įveskite turto duomenis",
    description:
      "Užpildykite užklausos formą apie norimą įvertinti turtą ir jei reikia – nurodykite Jūsų lūkestį turto vertei.",
  },
  {
    title: "Sekite užsakymo eigą",
    description:
      "El. paštu gausite nuorodą, kurioje galėsite stebėti užsakymo eigą ir užsisakyti papildomas paslaugas.",
  },
  {
    title: "Kainos nustatymo procesas",
    description:
      "NT ekspertas atliks kainos nustatymą naudodamas kredito įstaigų reikalaujamą palyginamąjį metodą. Bus atrinkti panašūs sandoriai ir apskaičiuotas kvadratinio metro kainos vidurkis.\n\nJei preliminari kaina Jums tinka, galite užsisakyti išsamų turto vertinimą su papildoma turto apžiūra.",
  },
];

export const LeadLandingPage: React.FC<LeadLandingPageProps> = () => {
  // const [selectedPropertyType, setSelectedPropertyType] =
  //   React.useState<PropertyType>(PropertyType.Apartment);
  const selectedPropertyType =
    (useRouteQuery().get("simpleType") as PropertyType) ??
    PropertyType.Apartment;
  const appraiserId = useRouteQuery().get("appraiserId");
  const discountCode = useRouteQuery().get("discountCode");
  const deliveryTime = (useRouteQuery().get("deliveryTime") ??
    PropertyDeliveryTimeType.Standard) as PropertyDeliveryTimeType;

  const { selectedAddons, setCityId } = React.useContext(
    PropertyCreationContext
  );

  const { data: prices, isLoading: isLoadingPrices } = useReportPrices();

  const navigate = useNavigate();

  const { getUpdatedQueryParam, updateQueryParam } = useUpdateQueryParam();

  const { data: basicData, isLoading } = useQuery(
    ["basic_appraiser", appraiserId],
    () => getBasicAppraiser(appraiserId ?? ""),
    {
      enabled: !!appraiserId,
    }
  );

  const { appraiser, organization } = basicData ?? {};

  const methods = useForm();
  const city = methods.watch("city");

  if (isLoading || isLoadingPrices) {
    return <LoaderCentered />;
  }

  if ((!appraiser && !organization) || !prices) {
    return <div>Klaida</div>;
  }

  return (
    <div>
      {!!appraiser && (
        <BasicAppraiser appraiser={appraiser} organization={organization} />
      )}
      {!!organization && !appraiser && (
        <BasicOrganization organization={organization} />
      )}

      <ContentCard title="Galimos turto kainos ataskaita">
        <div>
          {
            "Mūsų patyrę nekilnojamojo turto ekspertai atliks Jūsų turto rinkos analizę ir pateiks Jums preliminarų turto kainos nustatymą. Tai greitas ir ekonomiškas būdas sužinoti apytikslę Jūsų turto kainą, pagrįstą rinkos analize ir turto charakteristikomis."
          }
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          {"Peržiūrėti, kaip atrodys turto kainos nustatymo ataskaita:"}
          <LinkForButton
            target="_blank"
            to="https://www.relo.lt/relo-turto-ataskaita"
          >
            <Button>pavyzdinė ataskaitą</Button>
          </LinkForButton>
        </div>
        <Typography variant="h3">Kaip tai veikia?</Typography>
        <div
          style={{ maxWidth: "600px", margin: "0 auto", paddingTop: "30px" }}
        >
          {howItWorks.map((step, index) => (
            <OrderedListItem
              key={index}
              title={step.title}
              description={step.description}
              index={index + 1}
              isLast={index === howItWorks.length - 1}
            />
          ))}
        </div>
      </ContentCard>
      <ContentCard title="Paslaugos užsakymas">
        <LeadLandingPageActionItem index={1} title="Pasirinkite turto tipą">
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {valuationServices.map((service) => (
              <PropertyTypeOption
                key={service.value}
                checked={selectedPropertyType === service.value}
                label={service.label}
                onClick={() => {
                  updateQueryParam("simpleType", service.value);
                }}
              />
            ))}
          </Box>
        </LeadLandingPageActionItem>
        <LeadLandingPageActionItem index={2} title="Įveskite turto vietą">
          <FormProvider {...methods}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                }}
              >
                <CityFormField
                  onSelect={(city) => {
                    setCityId?.(city?.GYV_KODAS);
                  }}
                  hideLabel
                />
              </div>
              {!!prices.locationPrice && (
                <Typography variant="h2">{`+${formatPrice(
                  prices.locationPrice
                )}`}</Typography>
              )}
            </div>
          </FormProvider>
        </LeadLandingPageActionItem>
        <LeadLandingPageActionItem
          index={3}
          title="Papildomos ataskaitos dalys"
        >
          <AddonListSelect />
        </LeadLandingPageActionItem>
        <LeadLandingPageActionItem index={4} title="Ataskaitos įvykdymo laikas">
          <DeliveryAddonSelect />
        </LeadLandingPageActionItem>
        <div style={{ marginTop: "40px" }} />
        <LeadActionCard
          fullPrice={prices.fullPrice}
          discountedPrice={prices.discount ? prices.totalPrice : undefined}
          code={discountCode}
          onOrderClick={() => {
            let updateQuery = [
              {
                paramName: "type",
                newValue:
                  getDesignationTypeFromSimpleType(selectedPropertyType),
              },
              { paramName: "simpleType", newValue: selectedPropertyType },
            ];
            if (!!city) {
              updateQuery.push({ paramName: "city", newValue: city });
            }

            const updateLocation = getUpdatedQueryParam(updateQuery);

            navigate({
              pathname: "../inputMethod",
              search: updateLocation.search,
            });
          }}
          selectedAddons={selectedAddons ?? []}
          selectedDeliveryTime={deliveryTime}
          selectedValuationService={
            valuationServices.find(
              (service) => service.value === selectedPropertyType
            )?.valationServiceLabel ?? ""
          }
          locationAddon={prices.locationPriceDescription ?? undefined}
        />
        <FullReportBanner />
      </ContentCard>
    </div>
  );
};
