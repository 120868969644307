import React from "react";
import { AddonsWrapper } from "./AddonsWrapper";
import { AddonSelectInput } from "./AddonSelectInput";
import { useQuery } from "react-query";
import {
  getDeliveryTimeConfig,
  getPropertyAddonPrices,
} from "../../../api/api";
import {
  useRouteQuery,
  useUpdateQueryParam,
} from "../../../hooks/router.hooks";
import { PropertyDeliveryTimeType } from "../../../types/api.types";
import { useDeliveryTimeConfigs } from "../../../hooks/api.hooks";

interface DeliveryAddonSelectProps {}

export const DeliveryAddonSelect: React.FC<DeliveryAddonSelectProps> = () => {
  const { data: deliveryTimeConfigs } = useDeliveryTimeConfigs();

  const { updateQueryParam } = useUpdateQueryParam();
  const query = useRouteQuery();
  const selectedDeliveryTime =
    query.get("deliveryTime") ?? PropertyDeliveryTimeType.Standard;

  if (!deliveryTimeConfigs) {
    return null;
  }

  return (
    <AddonsWrapper>
      {deliveryTimeConfigs.map((config) => {
        return (
          <AddonSelectInput
            label={config.label}
            description={config.sublabel}
            price={config.price}
            inputType="radio"
            isChecked={selectedDeliveryTime === config.type}
            onClick={() => updateQueryParam("deliveryTime", config.type)}
          />
        );
      })}
    </AddonsWrapper>
  );
};
