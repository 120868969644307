import { Slider } from "@mui/material";
import React from "react";
import { SliderInput } from "./SliderInput";

interface SliderWithInputProps {
  value: number | number[];
  onChange: (value: number | number[]) => void;
  min: number;
  max: number;
  step?: number;
  inputWidth?: string;
  inputPosition?: "horizontal" | "vertical";
}

export const SliderWithInput: React.FC<SliderWithInputProps> = ({
  value,
  onChange,
  min,
  max,
  step,
  inputWidth,
  inputPosition = "horizontal",
}) => {
  const isRange = typeof value !== "number";

  const getValue = (index?: number) => {
    if (isRange) {
      return value[index ?? 0];
    }
    return value;
  };

  const handleSliderInputChange = (inputValue: number, index: number) => {
    if (isRange && Array.isArray(value)) {
      const newValue = [...(value as number[])];
      newValue[index] = inputValue;
      onChange(newValue);
    } else {
      onChange(inputValue);
    }
  };

  const leftInput = isRange ? (
    <SliderInput
      value={getValue(0)}
      onChange={(value) => handleSliderInputChange(value, 0)}
      min={min}
      max={isRange ? getValue(1) : max}
      step={step}
      width={inputWidth}
    />
  ) : null;

  const slider = (
    <Slider
      value={value}
      onChange={(_, value) => onChange(value)}
      style={{ flex: 1 }}
      min={min}
      max={max}
      step={step}
    />
  );

  const rightInput = (
    <SliderInput
      value={getValue(1)}
      onChange={(value) => handleSliderInputChange(value, 1)}
      min={isRange ? getValue(0) : min}
      max={max}
      step={step}
      width={inputWidth}
    />
  );
  if (inputPosition === "horizontal") {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
        {leftInput}
        {slider}
        {rightInput}
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div style={{ padding: "0 10px" }}>{slider}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {leftInput}
          {rightInput}
        </div>
      </div>
    );
  }
};
