import React, { useEffect, useImperativeHandle } from "react";
import * as Yup from "yup";
import { ConfirmationRequest } from "../../types/api.types";
import { UseFormHandleSubmit, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { FormDatepicker } from "../form/FormDatepicker";
import { FormCheckboxInput } from "common/components/form/hookForms/FormCheckboxInput";
import { getLithuanianDateString } from "common/helpers/dateStringHelpers";
import { useProperty } from "../../hooks/property.hooks";
import { useQuery } from "react-query";
import { property } from "lodash";
import { getLastConfirmation } from "../../api/api";
import { useAppraiser } from "../../hooks/auth.hooks";
import { useOrganization } from "../../hooks/organization.hooks";
import dayjs from "dayjs";

interface ConfirmationFormProps {
  disabled?: boolean;
  propertyId: string;
}

export const confirmationText =
  "Patvirtintu, kad esu nepriklausomas turto vertintojas ir neturiu jokių interesų konflikto, susijusių su šiuo vertinimu.";

const validationSchema: Yup.ObjectSchema<ConfirmationRequest> = Yup.object({
  organizationName: Yup.string().required(),
  appraiserFullName: Yup.string().required(),
  certificateNumber: Yup.string().nullable(),
  certificateValidFrom: Yup.date().nullable(),

  appraiserFullNameConfirm: Yup.string()
    .required()
    .oneOf([Yup.ref("appraiserFullName")], "Vertintojo vardas turi sutapti"),
  confirmCheckbox: Yup.boolean()
    .oneOf([true], "Būtina sutikti su sąlygomis")
    .required(),
});

export interface ConfirmationFormHandles {
  handleSubmit: UseFormHandleSubmit<ConfirmationRequest>;
}

export const ConfirmationForm = React.forwardRef<
  ConfirmationFormHandles,
  ConfirmationFormProps
>(({ disabled, propertyId }, ref) => {
  const methods = useForm<ConfirmationRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: methods.handleSubmit,
  }));

  const [confirmationDate, setConfirmationDate] = React.useState<Date>(
    new Date()
  );

  const { data: confirmationData } = useQuery(
    ["confirmation", propertyId],
    () => getLastConfirmation(propertyId),
    {
      enabled: disabled,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (!confirmationData) return;
    methods.reset({
      ...confirmationData,
      certificateValidFrom: dayjs(
        confirmationData.certificateValidFrom ?? null
      ) as any,
    });
    setConfirmationDate(confirmationData.createdAt);
  }, [confirmationData]);

  const { data: appraiser } = useAppraiser();

  const { data: organization } = useOrganization();

  useEffect(() => {
    if (disabled) return;
    if (appraiser?.fullName) {
      methods.setValue("appraiserFullName", appraiser.fullName);
    }
    if (appraiser?.certificateNumber) {
      methods.setValue("certificateNumber", appraiser.certificateNumber);
    }
    if (appraiser?.certificateValidFrom) {
      methods.setValue(
        "certificateValidFrom",
        dayjs(appraiser.certificateValidFrom) as any
      );
    }
  }, [
    appraiser?.fullName,
    appraiser?.certificateNumber,
    appraiser?.certificateValidFrom,
    disabled,
  ]);

  useEffect(() => {
    if (disabled) return;
    if (organization?.name) {
      methods.setValue("organizationName", organization.name);
    }
  }, [organization?.name, disabled]);

  return (
    <FormProvider {...methods}>
      <FormSettingsContext.Provider
        value={{
          smallFont: true,
          desktopColumns: true,
          maxInputWidth: 500,
          inputHeight: 56,
          disabled,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            maxWidth: "1000px",
          }}
        >
          <FormInput
            name="organizationName"
            label="Įmonės pavadinimas"
            variant="text"
          />
          <FormInput
            name="appraiserFullName"
            label="Vertintojo vardas pavardė"
            variant="text"
          />
          <FormInput
            name="certificateNumber"
            label="NT vertintojo kvalifikacijos pažymėjimas"
            variant="text"
          />
          <FormDatepicker
            name="certificateValidFrom"
            label="Pažymėjimas išduotas"
          />
          <div style={{ margin: "40px 0" }}>
            <FormCheckboxInput
              name="confirmCheckbox"
              label={confirmationText}
            />
          </div>
          <FormInput
            name="appraiserFullNameConfirm"
            label="Patvirtinimui, pakartokite savo vardą ir pavardę"
            variant="text"
          />
          <div>
            <span>Patvirtinimo data</span>
            <span style={{ marginLeft: "20px" }}>
              {getLithuanianDateString(confirmationDate)}
            </span>
          </div>
        </div>
      </FormSettingsContext.Provider>
    </FormProvider>
  );
});
