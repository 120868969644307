import React from "react";
import CheckIcon from "@mui/icons-material/Check";

interface CheckLabelProps {
  title: string;
}

export const CheckLabel: React.FC<CheckLabelProps> = ({ title }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      <CheckIcon color="primary" fontSize="small" />
      {title}
    </div>
  );
};
