import { Box } from "@mui/material";
import {
  formatArea,
  formatAreaPrice,
  formatPrice,
} from "common/helpers/text.helpers";
import React, { useMemo } from "react";
import { Typography } from "common/components/Typography";
import { usePricePredictions } from "../../hooks/pricePredictions.hooks";
import { useProperty } from "../../hooks/property.hooks";
import { FinalPricePredictions } from "common/types/common.types";
import { AppraiserProperty } from "../../types/appraiser.types";

interface ValuationSummaryProps {
  propertyId: string;
  propertyFromProps?: AppraiserProperty;
  finalPricePredictions: FinalPricePredictions | undefined;
}

export const ValuationSummary: React.FC<ValuationSummaryProps> = ({
  propertyId,
  finalPricePredictions,
  propertyFromProps,
}) => {
  const { data: propertyFetched } = useProperty(
    propertyId ?? "",
    !propertyFromProps
  );

  const property = propertyFromProps ?? propertyFetched;

  return (
    <div>
      <Typography variant="h2" textStyle={{ fontSize: 20 }}>
        Galimos turto kainos suvestinė
      </Typography>
      <Box
        component="table"
        sx={{
          "& tr": {
            height: "35px",
          },
          "& tr > td:first-of-type": {
            pr: "30px",
          },
          fontSize: "16px",
          mt: "10px",
        }}
      >
        <tbody>
          <tr>
            <td>Svertinis kainų vidurkis</td>
            <td>
              {formatAreaPrice(
                finalPricePredictions?.predicted_price.average_price_area
              )}
            </td>
          </tr>
          <tr>
            <td>Turto plotas</td>
            <td>{formatArea(property?.area)}</td>
          </tr>
          <tr style={{ fontWeight: 600 }}>
            <td>Nustatyta galima turto kaina</td>
            <td>
              {formatPrice(
                (finalPricePredictions?.predicted_price.average_price_area ??
                  0) * (property?.area ?? 0)
              )}
            </td>
          </tr>
        </tbody>
      </Box>
    </div>
  );
};
