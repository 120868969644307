import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress } from "@mui/material";
import { FormInput } from "common/components/form/hookForms/FormInput";
import { LeadContact } from "common/types/common.types";
import React, { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { ContentCard } from "../../components/ContentCard";
import { useMutation, useQuery } from "react-query";
import { getBasicAppraiser, updatePropertyLeadContact } from "../../api/api";
import { usePropertyShare } from "../../hooks/property.hooks";
import {
  useRouteQuery,
  useScrollToTop,
  useUpdateQueryParam,
} from "../../hooks/router.hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormInputBox } from "../../components/form/FormInputBox";
import { CompositeAssets } from "../../components/comparablesSummary/CompositeAssets";
import { PropertyFileUpload } from "../property/PropertyFileUpload";
import { useWithPropertyEditToken } from "common/hooks/propertyDetails.hooks";
import { PropertyType } from "../../types/api.types";
import { PreliminaryPrice } from "./reportPayments/PreliminaryPrice";
import { FormPhoneInput } from "../../components/form/FormPhoneInput";
import { phoneNumberSchema } from "../../schemas/phone.schema";

interface LeadContactFormProps {}

type FormType = {
  expectedValue?: number | null;
} & LeadContact;

const validationSchema: Yup.ObjectSchema<FormType> = Yup.object({
  expectedValue: Yup.number().nullable(),
  name: Yup.string().required("Įveskite vardą"),
  email: Yup.string()
    .email("Neteisingas el. pašto adresas")
    .required("Įveskite el. pašto adresą"),
  phone: phoneNumberSchema.required("Įveskite telefono numerį"),
  additionalInfo: Yup.string().nullable(),
});

export const LeadContactForm: React.FC<LeadContactFormProps> = () => {
  const simpleType = useRouteQuery().get("simpleType");
  const createdPropertyId = useRouteQuery().get("createdPropertyId");
  const token =
    sessionStorage.getItem(`propertyToken.${createdPropertyId}`) ?? "";

  const {
    data: propertyData,
    isLoading: isLoadingProperty,
    isError: isErrorProperty,
  } = usePropertyShare(token);

  useScrollToTop();

  const { isEditing } = useWithPropertyEditToken();

  const methods = useForm<FormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      expectedValue: propertyData?.property?.expectedValue,
      name: propertyData?.property?.leadContact?.name,
      email: propertyData?.property?.leadContact?.email,
      phone: propertyData?.property?.leadContact?.phone,
      additionalInfo: propertyData?.property?.leadContact?.additionalInfo,
    },
  });

  useEffect(() => {
    if (propertyData?.property?.leadContact) {
      methods.reset(propertyData?.property?.leadContact);
    }
  }, [propertyData?.property?.leadContact]);
  useEffect(() => {
    if (propertyData?.property?.expectedValue) {
      methods.setValue("expectedValue", propertyData?.property?.expectedValue);
    }
  }, [propertyData?.property.expectedValue]);

  const { navigateWithQueryParams } = useUpdateQueryParam();

  const { mutate, isLoading } = useMutation(updatePropertyLeadContact, {
    onSuccess: () => {
      if (isEditing) {
        window.location.href = propertyData?.property.leadContact?.link ?? "";
      } else {
        navigateWithQueryParams("../payment");
      }
    },
  });

  if (isLoadingProperty) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!propertyData || isErrorProperty) {
    return <div>Klaida</div>;
  }

  const fileUploadCaption = `Kadastrinių matavimų byla, VĮ „Registrų centras“ pažymėjimas${
    simpleType !== PropertyType.Apartment ? ", Žemės sklypo planas" : ""
  }. Jeigu turite įkelkite šiuos dokumentus (arba jų nuotraukas). Tai paspartintų vertinimo eigą ir preliminari vertė būtų tikslensė.`;

  return (
    <div>
      <PreliminaryPrice propertyId={propertyData.property._id} />
      <ContentCard title="Priklausomas turtas">
        <CompositeAssets propertyId={propertyData.property._id} isCustomer />
      </ContentCard>
      <PropertyFileUpload
        propertyId={propertyData.property._id}
        isCustomer
        customEditRight={true}
        caption={fileUploadCaption}
        customHeader="Įkelti papildomus dokumentus"
      />

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => {
            mutate({
              propertyId: propertyData?.property._id,
              leadContact: data,
              expectedValue: data.expectedValue,
            });
          })}
        >
          <ContentCard
            title="Turto kainos nustatymo tikslas"
            caption="Kai turto kainos nustatymo tikslas yra kredito paraiška, galite nurodyti lūkestinę kainą. Tipiškai tai yra visa pardavėjo prašoma kaina. Lūkestinei kainai stipriai atitinkant esamas rinkos sąlygos, NT ekspertas gali greičiau įvertinti jūsų užklausą."
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <FormInput
                name="expectedValue"
                label="Lūkestinė kaina"
                variant="number"
                maxWidth={500}
                units="€"
              />
            </div>
          </ContentCard>
          <ContentCard title="Papildoma informacija">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormInputBox
                name="additionalInfo"
                rows={5}
                textFieldProps={{
                  placeholder: "Įrašykite",
                }}
              />
            </div>
          </ContentCard>
          <ContentCard
            title="Jūsų kontaktiniai duomenys"
            caption="Prašome įvesti savo kontaktinius duomenis"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                maxWidth: 500,
              }}
            >
              <FormInput
                name="name"
                label="Vardas"
                variant="text"
                maxWidth={500}
              />
              <FormInput
                name="email"
                label="El. paštas"
                variant="text"
                maxWidth={500}
              />
              <FormPhoneInput name="phone" label="Telefonas" />
            </div>
          </ContentCard>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              style={{ maxWidth: "350px", width: "100%", margin: "0px 15px" }}
              loading={isLoading}
            >
              Toliau
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
