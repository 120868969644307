import React from "react";
import BaseChart from "./BaseChart";
import colors from "common/styles/colors";
import { addAlpha } from "common/helpers/colors.helpers";
import { SliderWithInput } from "../form/SliderWithInput";

interface PriceBreakdownChartProps {
  priceStep: number;
  prices: number[];
  priceFormatter: (price: number) => string;
  onFilterChange: (minPrice: number, maxPrice: number) => void;
  selectedPrices?: [number, number];
  inputWidth?: string;
}

export const PriceBreakdownChart: React.FC<PriceBreakdownChartProps> = ({
  prices,
  priceStep,
  priceFormatter,
  onFilterChange,
  selectedPrices,
  inputWidth,
}) => {
  const getChartData = () => {
    // Create price ranges with 100€/m² steps
    const priceRanges: { [key: number]: number } = {};

    const pricesFloored = prices.map(
      (p) => Math.floor(p / priceStep) * priceStep
    );

    const minPrice = Math.min(...pricesFloored);
    const maxPrice = Math.max(...pricesFloored);
    for (let i = minPrice; i <= maxPrice; i += priceStep) {
      if (!priceRanges[i]) {
        priceRanges[i] = 0;
      }
    }

    pricesFloored.forEach((price) => {
      priceRanges[price] = priceRanges[price] + 1;
    });

    const chartDataItems = Object.entries(priceRanges).map(
      ([range, count]) => ({
        x: Number(range),
        y: count,
      })
    );
    return chartDataItems;
  };

  const chartData = getChartData();
  const chartDataWithLabels = chartData.map((item) => ({
    ...item,
    x: priceFormatter(item.x + priceStep),
  }));

  const defaultValues = [
    chartData[0].x,
    chartData[chartData.length - 1].x + priceStep,
  ];

  const selectedValues = selectedPrices ?? defaultValues;

  return (
    <div>
      <BaseChart
        data={chartDataWithLabels}
        tickLabelPlacement="tick"
        tickPlacement="end"
        barLabel="value"
        colorMap={{
          type: "ordinal",
          values: chartDataWithLabels.map((item) => item.x),
          colors: chartData.map((item) => {
            if (item.x >= selectedValues[0] && item.x < selectedValues[1]) {
              return colors.purple3;
            }
            return addAlpha(colors.purple3, 0.3);
          }),
        }}
      ></BaseChart>
      <div
        style={{
          padding: "0 30px",
        }}
      >
        <SliderWithInput
          value={selectedValues}
          onChange={(values) => {
            onFilterChange((values as number[])[0], (values as number[])[1]);
          }}
          step={priceStep}
          min={defaultValues[0]}
          max={defaultValues[1]}
          inputWidth={inputWidth ?? "55px"}
          inputPosition="vertical"
        />
      </div>
    </div>
  );
};
