import React, { useState } from "react";
import { PropertyInfra, PropertyInfraIndexed } from "../../../types/api.types";

import { Divider } from "common/components/listItems/Divider";
import { PropertyDistanceCard } from "./PropertyDistanceCard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AppraiserProperty } from "../../../types/appraiser.types";
import { SchoolDialog } from "./SchoolDialog";

interface DistancesSectionProps {
  name: string;
  infras: PropertyInfraIndexed[];
  property: AppraiserProperty;
}

export const DistancesSection: React.FC<DistancesSectionProps> = ({
  name,
  infras,
  property,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedInfra, setSelectedInfra] = useState<PropertyInfra | null>(
    null
  );

  const handleOpenModal = (infra: PropertyInfra) => {
    setSelectedInfra(infra);
    setOpenModal(true);
  };

  return (
    <div>
      <div
        style={{
          fontSize: "20px",
          marginBottom: "15px",
        }}
      >
        {name}
      </div>
      <div>
        {infras.map((infra) => {
          return (
            <div
              key={infra._id}
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <PropertyDistanceCard
                  infra={infra}
                  infraNumber={infra.index + 1}
                />

                <Divider
                  style={{
                    marginTop: "10px",
                  }}
                />
              </div>
              {infra.subtype === "school" && (
                <KeyboardArrowRightIcon
                  style={{ marginBottom: "10px", cursor: "pointer" }}
                  onClick={() => handleOpenModal(infra)}
                />
              )}
            </div>
          );
        })}
      </div>
      <SchoolDialog
        open={openModal}
        infra={selectedInfra}
        propertyId={property._id}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};
