import React from "react";
import { PropertyLegal } from "../../types/api.types";
import { Typography } from "common/components/Typography";
import { Divider } from "@mui/material";

interface LegalParagraphsProps {
  legals: PropertyLegal[];
}

export const LegalParagraphs: React.FC<LegalParagraphsProps> = ({ legals }) => {
  return (
    <div>
      {legals.map((legal, index) => (
        <section key={index}>
          <Typography variant="h3">{legal.section}</Typography>
          {legal.paragraphs.map((paragraph, index) => (
            <div>
              <p>{paragraph.text}</p>
              {!!paragraph.listItems && (
                <ul>
                  {paragraph.listItems.map((listItem, index) => (
                    <li key={index}>{listItem}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          {index !== legals.length - 1 && (
            <Divider
              style={{
                margin: "30px 0",
              }}
            />
          )}
        </section>
      ))}
    </div>
  );
};
