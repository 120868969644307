import React from "react";
import { PropertyDeliveryTimeType } from "../../../types/api.types";
import { OrderSummarySection } from "./OrderSummarySection";
import { useDeliveryTimeConfigs } from "../../../hooks/api.hooks";

interface DeliveryTimeSummaryProps {
  deliveryTimePrice: number | undefined;
  deliveryTimeLabel: string;
  isPurchased?: boolean;
}

export const DeliveryTimeSummary: React.FC<DeliveryTimeSummaryProps> = ({
  deliveryTimePrice,
  deliveryTimeLabel,
  isPurchased,
}) => {
  return (
    <OrderSummarySection
      items={[
        {
          title: deliveryTimeLabel,
          price: deliveryTimePrice,
        },
      ]}
      total={deliveryTimePrice ?? 0}
      title="Atlikimo laikas"
      isPurchased={isPurchased}
    />
  );
};
