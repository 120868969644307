import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { importTransactionsExcel } from "../api/api";
import colors from "common/styles/colors";
import { ComparablesCountOverviewWrapper } from "../components/filters/ComparablesCountOverviewWrapper";
import { ComparablesCountOverviewRow } from "../components/filters/ComparablesCountOverviewRow";
import { Alert, Button, Input } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getErrorMessage } from "common/helpers/error.helpers";

interface TransactionsImportProps {
  onSuccess?: () => void;
}

export const TransactionsImport: React.FC<TransactionsImportProps> = ({
  onSuccess,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Setting up the mutation with React Query
  const mutation = useMutation(importTransactionsExcel, {
    onSuccess: () => {
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      onSuccess?.();
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    if (file) {
      mutation.mutate(file);
    }
  };

  const allCount =
    (mutation.data?.found_in_db_count ?? 0) +
    (mutation.data?.saved_in_db_count ?? 0) +
    (mutation.data?.not_supported_count ?? 0);

  const errorText = getErrorMessage(mutation.error) ?? "Įvyko klaida";

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        accept=".xls, .xlsx"
        onChange={handleFileChange}
        style={{ marginRight: "15px" }}
      />
      <LoadingButton
        variant="contained"
        onClick={handleSubmit}
        loading={mutation.isLoading}
        disabled={!file}
      >
        Importuoti sandorius
      </LoadingButton>
      <div style={{ marginTop: "30px" }}>
        {mutation.isSuccess && mutation.data && (
          <ComparablesCountOverviewWrapper title="RC sandorių importavimo rezultatai:">
            <ComparablesCountOverviewRow
              label="rasti duomenų bazėje"
              count={mutation.data.found_in_db_count}
              allCount={allCount}
            />
            <ComparablesCountOverviewRow
              label="sėkmingai importuoti"
              count={mutation.data.saved_in_db_count}
              allCount={allCount}
            />
            <ComparablesCountOverviewRow
              label="nepalaikomi"
              count={mutation.data.not_supported_count}
              allCount={allCount}
              tooltipText="Tai sandoriai kurių nepavyko importuoti. Pavyzdžiui buvo rastas tik garažo įrašas."
            />
          </ComparablesCountOverviewWrapper>
        )}
        {mutation.isError && <Alert severity="error">{errorText}</Alert>}
      </div>
    </div>
  );
};
