import colors from "common/styles/colors";
import React from "react";

interface SimpleTableHeaderProps {
  children?: React.ReactNode;
  isFirstElement?: boolean;
  textAlign?: "left" | "center" | "right";
  width?: string;
  forceWidth?: boolean;
  style?: React.CSSProperties;
}

export const SimpleTableHeader: React.FC<SimpleTableHeaderProps> = ({
  children,
  isFirstElement,
  textAlign,
  width,
  forceWidth,
  style,
}) => {
  return (
    <th
      style={{
        textAlign: textAlign ?? (isFirstElement ? "left" : "center"),
        fontWeight: 400,
        color: colors.grey,
        paddingBottom: "15px",
        width,
        minWidth: forceWidth ? width : undefined,
        ...style,
      }}
    >
      {children}
    </th>
  );
};
