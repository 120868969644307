import React, { useContext } from "react";
import { useDepositPrices } from "../../../hooks/api.hooks";
import { Checkbox } from "@mui/material";
import { PropertyCreationContext } from "../PropertyCreationProvider";
import colors from "common/styles/colors";

interface DepositSelectionProps {}

const label =
  "Užsakysiu pilną turto vertinimo ataskaitą po preliminarios ataskaitos";

export const DepositSelection: React.FC<DepositSelectionProps> = ({}) => {
  const { data } = useDepositPrices();

  const { depositChecked, toggleDepositChecked } = useContext(
    PropertyCreationContext
  );

  if (!data) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        cursor: "pointer",
        gap: "10px",
        borderRadius: "10px",
        border: "1px solid #E0E0E0",
        padding: "5px",
        marginTop: "20px",
      }}
      onClick={toggleDepositChecked}
    >
      <Checkbox checked={depositChecked} onClick={toggleDepositChecked} />
      <div>
        <p
          style={{
            marginTop: "10px",
            fontWeight: 500,
          }}
        >
          {label}
        </p>
        <p>
          {"Sumokėdami "}
          <span
            style={{ color: colors.purple2 }}
          >{`${data.depositPrice} € užstatą`}</span>
          {" už pilną vertinimo ataskaitą, gausite "}
          <span
            style={{ color: colors.purple2 }}
          >{`${data.depositDiscount} € nuolaidą`}</span>
          {" pilnai vertinimo ataskaitai."}
        </p>
      </div>
    </div>
  );
};
