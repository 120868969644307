import { CheckboxInput } from "common/components/form/CheckboxInput";
import { Typography } from "common/components/Typography";
import { formatPrice } from "common/helpers/text.helpers";
import React from "react";

interface AddonSelectInputProps {
  onClick: () => void;
  isChecked: boolean;
  label: string;
  description?: string;
  price?: number;
  inputType: "checkbox" | "radio";
}

export const AddonSelectInput: React.FC<AddonSelectInputProps> = ({
  onClick,
  isChecked,
  label,
  description,
  price,
  inputType,
}) => {
  return (
    <div
      style={{
        border: "1px solid #e0e0e0",
        borderRadius: "5px",
        padding: "10px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <CheckboxInput
        label={label}
        isChecked={isChecked}
        onChange={onClick}
        style={{
          alignSelf: "center",
          fontSize: 16,
        }}
        type={inputType}
      />
      <div
        style={{
          marginLeft: "40px",
        }}
      >
        <Typography variant="caption">{description}</Typography>
      </div>
      {!!price && (
        <div
          style={{
            fontWeight: 600,
            fontSize: "24px",
            marginLeft: "40px",
            marginTop: "10px",
          }}
        >
          {formatPrice(price)}
        </div>
      )}
    </div>
  );
};
