import { useFormFieldErrors } from "common/hooks/form.hooks";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MuiPhone } from "./MuiPhone";
import { FormFieldWrapper } from "common/components/form/FormFieldWrapper";

interface FormPhoneInputProps {
  label?: string;
  name: string;
  isRequired?: boolean;
}

export const FormPhoneInput: React.FC<FormPhoneInputProps> = ({
  name,
  label,
  isRequired,
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <FormFieldWrapper
            label={label}
            error={errorText}
            isRequired={isRequired}
          >
            <MuiPhone onChange={onChange} value={value} />
          </FormFieldWrapper>
        );
      }}
    />
  );
};
