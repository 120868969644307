import { Typography } from "common/components/Typography";
import colors from "common/styles/colors";
import React from "react";

interface OrderedListItemProps {
  title: string;
  description: string;
  index: number;
  isLast?: boolean;
  customIcon?: React.ReactElement;
}

export const OrderedListItem: React.FC<OrderedListItemProps> = ({
  title,
  description,
  index,
  isLast,
  customIcon,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        marginBottom: isLast ? 0 : "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          minHeight: "100px",
        }}
      >
        <div
          style={{
            width: "40px",
            minHeight: "40px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.purple4,
            color: colors.purple2,
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {customIcon ?? index}
        </div>
        {!isLast && (
          <div
            style={{
              width: "1px",
              height: "100%",
              backgroundColor: colors.greybg,
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <div
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {description}
        </div>
      </div>
    </div>
  );
};
