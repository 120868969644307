import React from "react";
import { useAutoPredictPrice } from "../../hooks/pricePredictions.hooks";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { InputField } from "common/components/form/InputField";
import { Button } from "@mui/material";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { Typography } from "common/components/Typography";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";

interface AutoPricePredictionProps {
  property: AppraiserProperty;
}

export const AutoPricePrediction: React.FC<AutoPricePredictionProps> = ({
  property,
}) => {
  const { autoPredictPrice, isLoading } = useAutoPredictPrice();

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setConfirmationOpen(true);
        }}
        variant="outlined"
      >
        Įvertinti automatiškai
      </Button>

      <ConfirmationDialog
        title="Automatinė vertė"
        isOpen={confirmationOpen}
        onClose={() => {
          if (isLoading) return;
          setConfirmationOpen(false);
        }}
        hideDoNotShowAgain
        actionButtonText="SKAIČIUOTI"
        isLoading={isLoading}
        onConfirm={async () => {
          await autoPredictPrice(property, property.transactionId);
          setConfirmationOpen(false);
        }}
      >
        <p>Vertinimui atlikti bus nupirkta iki 3-jų panašiausių sandorių</p>
        <p>Atlikti skaičiavimai bus prarasti (jei tokie buvo)</p>
      </ConfirmationDialog>
    </>
  );
};
