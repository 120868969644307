import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlWrapper } from "./FormControlWrapper";
import { useFormFieldErrors } from "common/hooks/form.hooks";
import { TextField, TextFieldProps } from "@mui/material";
import { FormSettingsContext } from "common/components/form/hookForms/FormSettingsContext";
import { FormFieldWrapper } from "common/components/form/FormFieldWrapper";

interface FormInputBoxProps {
  label?: string;
  name: string;
  rows?: number;
  textFieldProps?: TextFieldProps;
}

export const FormInputBox: React.FC<FormInputBoxProps> = ({
  name,
  label,
  rows,
  textFieldProps,
}) => {
  const { control } = useFormContext();

  const { errorText } = useFormFieldErrors(name, label);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <FormFieldWrapper label={label} error={errorText}>
            <TextField
              {...textFieldProps}
              value={value}
              onChange={onChange}
              multiline
              rows={rows}
            />
          </FormFieldWrapper>
        );
      }}
    />
  );
};
