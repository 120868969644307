import { Box, Button } from "@mui/material";
import { Divider } from "common/components/listItems/Divider";
import { formatPrice } from "common/helpers/text.helpers";
import colors from "common/styles/colors";
import React from "react";
import {
  PropertyAddonType,
  PropertyDeliveryTimeType,
} from "../../types/api.types";
import { CheckLabel } from "../../components/labels/CheckLabel";
import { addonsLabels } from "../property/addons/AddonListSelect";
import { useDeliveryTimeConfigs } from "../../hooks/api.hooks";

interface LeadActionCardProps {
  fullPrice: number;
  discountedPrice?: number;
  code?: string | null;
  onOrderClick?: () => void;

  selectedAddons: PropertyAddonType[];
  selectedDeliveryTime?: PropertyDeliveryTimeType;
  selectedValuationService: string;
  locationAddon?: string;
}

export const LeadActionCard: React.FC<LeadActionCardProps> = ({
  fullPrice,
  discountedPrice,
  code,
  onOrderClick,
  selectedValuationService,
  selectedAddons,
  selectedDeliveryTime,
  locationAddon,
}) => {
  const { data: deliveryTimeConfigs } = useDeliveryTimeConfigs();
  return (
    <Box
      sx={{
        borderColor: colors.lightgrey,
        borderWidth: "1px",
        borderStyle: "solid",
        padding: { xs: "10px", sm: "30px" },
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: "24px",
          fontWeight: 500,
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Užsakymo suvestinė
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            margin: "30px",
          }}
        >
          <CheckLabel title={selectedValuationService} />
          {selectedAddons.map((addon) => (
            <CheckLabel key={addon} title={addonsLabels[addon]} />
          ))}
          <CheckLabel
            title={`Bus paruošta ${
              deliveryTimeConfigs?.find(
                (config) => config.type === selectedDeliveryTime
              )?.label
            }`}
          />
          {locationAddon && <CheckLabel title={locationAddon} />}
        </div>
      </div>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px",
          mt: "30px",
          px: { xs: 0, sm: "30px" },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: 600,
              display: "flex",
              gap: "10px",
            }}
          >
            <span
              style={{
                textDecoration:
                  discountedPrice !== undefined ? "line-through" : "none",
              }}
            >
              {formatPrice(fullPrice, 2)}
            </span>
            {discountedPrice !== undefined && (
              <span>{formatPrice(discountedPrice, 2)}</span>
            )}
          </div>
          {discountedPrice !== undefined && (
            <span style={{ color: colors.purple2 }}>
              Pritaikytas kodas: {code}
            </span>
          )}
        </div>
        <Button
          variant="contained"
          sx={{
            maxWidth: "345px",
            width: "100%",
          }}
          onClick={onOrderClick}
        >
          Užsakyti
        </Button>
      </Box>
    </Box>
  );
};
