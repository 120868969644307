import React, { useContext } from "react";
import { PropertyRow } from "../../../components/PropertyRow";
import { formatPrice } from "common/helpers/text.helpers";
import { InfoTooltip } from "../../../components/tooltips/InfoTooltip";
import {
  usePropertyReportPrice,
  useReportPrices,
} from "../../../hooks/api.hooks";
import { PropertyCreationContext } from "../PropertyCreationProvider";
import { useRouteQuery } from "../../../hooks/router.hooks";

interface PreliminaryPriceProps {
  propertyId?: string;
}

export const PreliminaryPrice: React.FC<PreliminaryPriceProps> = ({
  propertyId,
}) => {
  const { fromLead } = useContext(PropertyCreationContext);
  const { data: preliminaryPrice } = useReportPrices(fromLead && !propertyId);
  const { data: propertyReportPrice } = usePropertyReportPrice(
    undefined,
    fromLead && !!propertyId
  );

  const price = propertyId ? propertyReportPrice : preliminaryPrice;

  const editToken = useRouteQuery().get("editToken");

  if (!fromLead || !!editToken) {
    return null;
  }

  return (
    <div
      style={{
        borderRadius: "10px",
        padding: "30px",
        backgroundColor: "white",
        marginBottom: "20px",
      }}
    >
      <PropertyRow
        label="Užsakymo kaina"
        value={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div style={{ fontSize: "16px" }}>{`${formatPrice(
              price?.totalPrice
            )} (preliminari kaina)`}</div>
            <InfoTooltip title="Preliminari kaina - tai pradinė kaina, kuri gali keistis atsižvelgiant į turto tipą, vietą, papildomas ataskaitas ir įvykdymo laiką." />
          </div>
        }
      />
    </div>
  );
};
