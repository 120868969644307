import colors from "common/styles/colors";
import React from "react";
import { PropertyPlaceholderImage } from "../imageGallery/PropertyPlaceholderImage";
import { Typography } from "common/components/Typography";
import { Text } from "react-native";
import { formatAreaPrice, formatPrice } from "common/helpers/text.helpers";
import { PropertyGallery } from "../../routes/property/PropertyGallery";
import { ImageGallery } from "../imageGallery/ImageGallery";
import { PropertyImageGallery } from "../imageGallery/PropertyImageGallery";

interface BasePropertyCardProps {
  images?: string[];
  address?: string;
  price?: number;
  priceArea?: number;
  dateText?: string;
  caption?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const BasePropertyCard: React.FC<BasePropertyCardProps> = ({
  address,
  price,
  priceArea,
  dateText,
  caption,
  children,
  images,
  onClick,
}) => {
  const formattedPrice = price ? formatPrice(price) : null;
  const formattedPriceArea = priceArea ? formatAreaPrice(priceArea) : null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "480px",
        border: `1px solid ${colors.greyborder}`,
        borderRadius: "10px",
        backgroundColor: colors.white,
      }}
    >
      <PropertyImageGallery
        images={images?.map((url) => ({ url }))}
        height={160}
        width={160}
        borderBottomLeftRadius={10}
        borderTopLeftRadius={10}
        borderRadius={0}
        headerText={address}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          cursor: !!onClick ? "pointer" : "default",
        }}
        onClick={() => onClick?.()}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <Typography variant="h4">{address}</Typography>
          <Text
            style={{
              marginTop: 5,
            }}
          >
            <Typography variant="h4">
              {formattedPriceArea ?? formattedPrice}
            </Typography>
            {!!formattedPriceArea && !!formattedPrice && (
              <Typography
                textStyle={{
                  fontSize: 16,
                  marginLeft: 5,
                  fontWeight: "400",
                }}
              >
                {`(${formattedPrice})`}
              </Typography>
            )}
          </Text>

          {!!dateText && (
            <Typography
              textStyle={{
                fontSize: 14,
                color: colors.green,
                marginTop: 10,
              }}
            >
              {dateText}
            </Typography>
          )}

          {!!caption && (
            <Typography
              variant="caption"
              textStyle={{
                marginTop: 10,
              }}
            >
              {caption}
            </Typography>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
