import { OutlinedButton } from "common/components/buttons/OutlinedButton";
import colors from "common/styles/colors";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface CollapseContainerProps {
  label: string;
  buttonLabel: string;
  children: React.ReactNode;
}

export interface CollapseContainerRef {
  setShowFull: (value: boolean) => void;
}

export const CollapseContainer = forwardRef<
  CollapseContainerRef,
  CollapseContainerProps
>(({ label, buttonLabel, children }, ref) => {
  const [showFull, setShowFull] = useState(false);

  useImperativeHandle(ref, () => ({
    setShowFull: (value: boolean) => setShowFull(value),
  }));

  const keyboardArrowIconStyle = {
    marginLeft: "18px",
    color: colors.purple2,
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
          marginTop: "20px",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <div style={{ color: colors.grey }}>{label}</div>
        <OutlinedButton
          onPress={() => {
            setShowFull(!showFull);
          }}
          style={{ width: "290px" }}
          rightIcon={
            <>
              {showFull ? (
                <KeyboardArrowUpIcon style={keyboardArrowIconStyle} />
              ) : (
                <KeyboardArrowDownIcon style={keyboardArrowIconStyle} />
              )}
            </>
          }
        >
          {buttonLabel}
        </OutlinedButton>
      </div>
      {showFull && <div style={{ marginBottom: "30px" }}>{children}</div>}
    </>
  );
});
