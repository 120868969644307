import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";

interface FormLoadingButtonProps {
  isLoading: boolean;
}

export const FormLoadingButton: React.FC<FormLoadingButtonProps> = ({
  isLoading,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <LoadingButton
        type="submit"
        variant="contained"
        style={{
          width: "345px",
        }}
        loading={isLoading}
      >
        Išsaugoti
      </LoadingButton>
    </div>
  );
};
