import { SchoolExamResults, ChartData, ChartSection, ChartType, SchoolRating} from "../types/api.types";

export const processExamResults = (data: SchoolExamResults | null) => {
  const subjectMapping: { [key: string]: string } = {
    Istorija: "Istorijos",
    Biologija: "Biologijos",
    "Užsienio kalba (anglų)": "Anglų kalbos",
    Matematika: "Matematikos",
    "Lietuvių kalba ir literatūra": "Lietuvių kalbos",
  };

  // One of these will be used as 4th subject
  const oneOfSubjects = ["Istorija", "Biologija"];

  // Filter out the subjects on which school rating was calculated
  let filteredExams =
    data?.exams.filter((exam) =>
      Object.keys(subjectMapping).includes(exam.subject_name)
    ) || [];

  const prioritizedSubject = oneOfSubjects.find((subject) =>
    filteredExams.some(
      (exam) =>
        exam.subject_name === subject &&
        exam.average_grade !== null &&
        exam.average_grade !== 0
    )
  );

  if (prioritizedSubject) {
    filteredExams = filteredExams.filter(
      (exam) => exam.subject_name === prioritizedSubject || !oneOfSubjects.includes(exam.subject_name)
    );
  }

  filteredExams = filteredExams.sort(
    (a, b) =>
      Object.keys(subjectMapping).indexOf(a.subject_name) -
      Object.keys(subjectMapping).indexOf(b.subject_name)
  );

  // Map subject names for the chart
  filteredExams = filteredExams.map((exam) => ({
    ...exam,
    subject_name: subjectMapping[exam.subject_name].replace(" ", "\n"),
  }));

  return filteredExams;
};

export const createChartData = (
  examResults: SchoolExamResults | null,
  infraName: string | undefined
): ChartData => {
  const filteredExams = processExamResults(examResults);

  return {
    title: `Egzaminų rezultatai - ${infraName || ""}`,
    chart_type: ChartType.Bars,
    data: filteredExams.map((exam) => ({
      x: exam.subject_name,
      y: exam.average_grade,
    })),
    source:
      "https://www.nsa.smm.lt/egzaminai-ir-pasiekimu-patikrinimai/brandos-egzaminai/rezultatai/",
    section: ChartSection.SchoolExamResultsStatistics,
    layout: "horizontal",
  };
};

export const processSchoolRating = (data: SchoolRating | null): string => {
  if (!data) {
    return "-";
  }

  if (data.grade === 0 && data.error) {
    return "-";
  }

  const { city, city_count, municipality, municipality_count, country, country_count } =
    data.rating_position;

  const formatRating = (rating: number | null, count: number | null): string | null => {
    if (rating && count) {
      return rating > 10 ? `${rating}` : `${rating}/${Math.min(count, 10)}`;
    }
    return null;
  };

  return (
    formatRating(city, city_count) ||
    formatRating(municipality, municipality_count) ||
    formatRating(country, country_count) ||
    "-"
  );
};

export const getLocationDescription = (schoolRating: SchoolRating | null) => {
  if (!schoolRating) return "Lietuvos";
  return schoolRating.city || schoolRating.municipality || "Lietuvos";
};