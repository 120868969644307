import React, { useMemo } from "react";
import { InfraByType, PropertyInfra } from "../../../types/api.types";
import { GoogleMap } from "@react-google-maps/api";
import { AppraiserProperty } from "../../../types/appraiser.types";
import { mainPropertyBGColor } from "../../../hooks/propertyIcons.hooks";
import { MY_PROPERTY_SIZE_MULTIPLIER } from "../../../components/map/constants";
import { ComparableIconOverlayView } from "../../../components/map/ComparableIconOverlayView";
import { InfraMarker } from "./InfraMarker";
import { getBoundingBoxFromCoords } from "common/helpers/map.helpers";
import { greyscaleStyle } from "../../../components/map/MarkerMap";
interface PropertyDistancesMapProps {
  infrasByType: InfraByType[];
  property: AppraiserProperty;
}

export const PropertyDistancesMap: React.FC<PropertyDistancesMapProps> = ({
  infrasByType,
  property,
}) => {
  const [selectedInfra, setSelectedInfra] = React.useState<PropertyInfra>();

  const center = useMemo(() => {
    return {
      lat: property.lat,
      lng: property.lng,
    };
  }, [property.lat, property.lng]);

  const mapRef = React.useRef<google.maps.Map>();

  const setMapBounds = () => {
    if (mapRef.current && infrasByType.length > 0) {
      const coords = infrasByType.flatMap((infra) =>
        infra.infras.map((inf) => ({
          lat: inf.lat,
          lng: inf.lng,
        }))
      );
      coords.push({ lat: property.lat, lng: property.lng });
      const bounds = getBoundingBoxFromCoords(coords);
      const googleBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(bounds.minLat, bounds.minLng),
        new google.maps.LatLng(bounds.maxLat, bounds.maxLng)
      );
      mapRef.current.fitBounds(googleBounds);
    }
  };

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    mapRef.current = map;
    setMapBounds();
  }, []);

  return (
    <GoogleMap
      id="property-distances-map"
      onLoad={onLoad}
      mapContainerStyle={{
        height: "450px",
        width: "100%",
      }}
      center={center}
      zoom={15}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling: "cooperative",
        clickableIcons: false,
        styles: greyscaleStyle,
      }}
      onClick={() => {
        setSelectedInfra(undefined);
      }}
    >
      <ComparableIconOverlayView
        lat={property.lat}
        lng={property.lng}
        backgroundColor={mainPropertyBGColor}
        sizeMultiplier={MY_PROPERTY_SIZE_MULTIPLIER}
      />
      {infrasByType.flatMap((infras, index1) =>
        infras.infras.map((infra, index2) => (
          <InfraMarker
            key={`${index1}-${index2}`}
            isSelected={selectedInfra?._id === infra._id}
            onClick={() => setSelectedInfra(infra)}
            infra={infra}
            badgeCount={infra.index + 1}
          />
        ))
      )}
    </GoogleMap>
  );
};
