import { DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { MUILoadingButton } from "../buttons/MUILoadingButton";
import { DialogHeader } from "./DialogHeader";
import { theme } from "../../styles/MUITheme";
interface SimpleDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onSubmit?: () => void;
  children?: React.ReactNode;
  width?: string;
  buttonVariant?: "contained" | "outlined" | "text";
  buttonText?: string;
  hideButton?: boolean;
}

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
  title,
  open,
  onClose,
  isLoading,
  onSubmit,
  children,
  width,
  buttonVariant,
  buttonText,
  hideButton,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        style: { width: width ?? "800px" },
      }}
      scroll="body"
      fullScreen={isMobile}
    >
      <DialogHeader title={title} onClose={onClose} />
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {children}
        {!hideButton && (
          <MUILoadingButton
            variant={buttonVariant ?? "contained"}
            loading={isLoading}
            style={{ alignSelf: "center", width: "200px", marginTop: "20px" }}
            onClick={onSubmit}
          >
            {buttonText ?? "Išsaugoti"}
          </MUILoadingButton>
        )}
      </DialogContent>
    </Dialog>
  );
};
