import {
  formatPrice,
  getEndingByCountLTVardininkas,
} from "common/helpers/text.helpers";
import React, { useMemo, useState } from "react";
import { LinkButton } from "../buttons/LinkButton";
import { SimpleDialog } from "../dialogs/SimpleDialog";
import { ListingsWithAddons, MatchedListing } from "common/types/common.types";
import { Typography } from "common/components/Typography";
import { SmallNumberInput } from "common/components/form/SmallNumberInput";
import { LoaderCentered } from "../loaders/LoaderCentered";
import { PriceBreakdownChart } from "../charts/PriceBreakdownChart";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";
import { Button, CircularProgress } from "@mui/material";
import { FieldLabel } from "common/components/form/FieldLabel";
import { AddonMap, AddonMapRef } from "./AddonMap";
import { Virtuoso } from "react-virtuoso";

interface AddonPriceInfoProps {
  data: ListingsWithAddons | undefined;
  isLoading?: boolean;
  isFetching?: boolean;

  listingFilterFn: (
    listing: MatchedListing,
    priceMin: number,
    priceMax: number
  ) => boolean;

  setSelectedRadius?: (radius: number) => void;
  selectedRadius?: number;

  mapPriceFn: (listing: MatchedListing) => number;
  mapCardComponent: (
    listing: MatchedListing,
    onClick?: () => void
  ) => React.ReactNode;

  title: string;

  filtersComponent?: React.ReactNode;
}

export const AddonPriceInfo: React.FC<AddonPriceInfoProps> = ({
  data,
  isLoading,
  isFetching,

  setSelectedRadius,
  selectedRadius,

  listingFilterFn,

  mapPriceFn,
  mapCardComponent,
  title,

  filtersComponent,
}) => {
  const [open, setOpen] = React.useState(false);

  const medianPrice = data?.statistics.median;

  const [selectedPriceMin, setSelectedPriceMin] = React.useState<number>();
  const [selectedPriceMax, setSelectedPriceMax] = React.useState<number>();
  const filteredListings = useMemo(() => {
    if (!data) {
      return null;
    }
    if (selectedPriceMin === undefined || selectedPriceMax === undefined) {
      return data.listings;
    }

    return data.listings.filter((listing) =>
      listingFilterFn(listing, selectedPriceMin, selectedPriceMax)
    );
  }, [data, selectedPriceMin, selectedPriceMax]);

  const totalListings = filteredListings?.length;

  const medianPriceLabel = `Medianos kaina ${formatPrice(medianPrice)}`;

  const addonMapRef = React.useRef<AddonMapRef>(null);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "15px",
          marginTop: "-10px",
        }}
      >
        <Typography variant="caption">{medianPriceLabel}</Typography>
        <LinkButton
          onClick={() => {
            setOpen(true);
          }}
        >
          DETALIAU
        </LinkButton>
      </div>
      <SimpleDialog
        open={open}
        onClose={() => setOpen(false)}
        title={title}
        width="1300px"
        buttonVariant="outlined"
        buttonText="grįžti atgal"
        onSubmit={() => setOpen(false)}
      >
        <FieldLabel label="Atstumu nuo vertinamo objekto, km">
          <div style={{ width: "150px" }}>
            <SmallNumberInput
              maxValue={30}
              minValue={1}
              value={selectedRadius}
              onChange={(v) => setSelectedRadius?.(v)}
            />
          </div>
        </FieldLabel>

        {!!filtersComponent && (
          <div style={{ marginTop: "15px" }}>{filtersComponent}</div>
        )}
        {isLoading && <LoaderCentered />}
        {!!medianPrice && (
          <div style={{ marginTop: "20px" }}>
            <Typography variant="caption">{medianPriceLabel}</Typography>
          </div>
        )}
        {!!data && (
          <PriceBreakdownChart
            priceStep={1000}
            prices={data?.listings.map(mapPriceFn) ?? []}
            priceFormatter={(price) => formatPrice(price)}
            onFilterChange={(min, max) => {
              setSelectedPriceMin(min);
              setSelectedPriceMax(max);
            }}
            selectedPrices={
              selectedPriceMin !== undefined && selectedPriceMax !== undefined
                ? [selectedPriceMin, selectedPriceMax]
                : undefined
            }
            inputWidth="65px"
          />
        )}
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h3">Objektai pagal atstumą</Typography>
              <Typography variant="caption">
                {totalListings} rezultat
                {getEndingByCountLTVardininkas(totalListings).toLowerCase()}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "500px", height: "600px" }}>
                <Virtuoso
                  data={filteredListings ?? []}
                  itemContent={(_, listing) => (
                    <div style={{ marginBottom: "15px" }}>
                      {mapCardComponent(listing, () => {
                        addonMapRef.current?.setSelectedListing(listing);
                      })}
                    </div>
                  )}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  flex: 1,
                  height: "600px",
                }}
              >
                <AddonMap
                  ref={addonMapRef}
                  listings={filteredListings ?? []}
                  mapCardComponent={mapCardComponent}
                  mapPriceFn={mapPriceFn}
                />
              </div>
            </div>
            {isFetching && !isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backdropFilter: "blur(2px)",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  paddingTop: "200px",
                }}
              >
                <CenteredContentWrapper>
                  <CircularProgress />
                </CenteredContentWrapper>
              </div>
            )}
          </div>
        </div>
      </SimpleDialog>
    </div>
  );
};
