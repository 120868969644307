import React from "react";

interface CenteredContentWrapperProps {
  children: React.ReactNode;
  width?: string;
  style?: React.CSSProperties;
}

export const CenteredContentWrapper: React.FC<CenteredContentWrapperProps> = ({
  children,
  width,
  style,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        ...style,
      }}
    >
      <div style={{ width }}>{children}</div>
    </div>
  );
};
