import React, { useEffect, useState } from "react";
import { TagComponent } from "./TagComponent";
import colors from "common/styles/colors";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface ExpirationTimeTagProps {
  deliveryDate: string;
}

const expiredLabel = "Laikas baigėsi";

export const ExpirationTimeTag: React.FC<ExpirationTimeTagProps> = ({
  deliveryDate,
}) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const delivery = new Date(deliveryDate);
      const difference = delivery.getTime() - now.getTime();

      if (difference <= 0) {
        setTimeLeft(expiredLabel);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    calculateTimeLeft(); // Initial calculation

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [deliveryDate]);

  return (
    <TagComponent
      color={timeLeft === expiredLabel ? colors.red : colors.premium}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AccessTimeIcon />
      <div
        style={{
          width: "100px",
        }}
      >
        {timeLeft}
      </div>
    </TagComponent>
  );
};
