import React from "react";
import { InfraByType, PropertyInfra } from "../../../types/api.types";
import { DistancesSection } from "./DistancesSection";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../styles/MUITheme";
import { AppraiserProperty } from "../../../types/appraiser.types";

interface PropertyDistancesListProps {
  infrasByType: InfraByType[];
  property: AppraiserProperty
}

const infraTypeDict: { [key: string]: string } = {
  shop: "Parduotuvės",
  pharmacy: "Vaistinės",
  bus_stop: "Autobusų stotelės",
  education: "Mokymosi įstaigos",
  business: "Verslo centrai",
  food: "Maitinimo įstaigos",
  recreation: "Poilsio vietos",
  housing: "Gyvenamieji pastatai",
  park: "Parkai",
  production: "Gamybinės įmonės",
  sport: "Sporto objektai",
  service: "Paslaugų įstaigos",
  health: "Sveikatinimo įstaigos",
};

export const PropertyDistancesList: React.FC<PropertyDistancesListProps> = ({
  infrasByType,
  property
}) => {
  const sortedInfrasByType = [...infrasByType].sort((a, b) =>
    a.type < b.type ? -1 : 1
  );

  const renderDistanceSection = (
    infraByType: InfraByType,
    variant?: "even" | "odd"
  ) => {
    const index = sortedInfrasByType.findIndex(
      (i) => i.type === infraByType.type
    );

    if (variant !== undefined && index % 2 !== (variant === "even" ? 0 : 1)) {
      return null;
    }

    return (
      <DistancesSection
        key={infraByType.type}
        name={infraTypeDict[infraByType.type] || "Kita"}
        infras={infraByType.infras}
        property={property}
      />
    );
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "30px",
        marginTop: "20px",
      }}
    >
      {isMobile ? (
        <div style={{ flex: 1 }}>
          {sortedInfrasByType.map((infra_type) =>
            renderDistanceSection(infra_type)
          )}
        </div>
      ) : (
        <>
          <div style={{ flex: 1 }}>
            {sortedInfrasByType.map((infra_type) =>
              renderDistanceSection(infra_type, "even")
            )}
          </div>
          <div style={{ flex: 1 }}>
            {sortedInfrasByType.map((infra_type) =>
              renderDistanceSection(infra_type, "odd")
            )}
          </div>
        </>
      )}
    </div>
  );
};
