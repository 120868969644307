export function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

// Returns red / yellow / green color based on the value in the range of min and max.
export const getColorInRange = (value: number, min = 0, max = 1) => {
  value = Math.max(min, Math.min(value, max));
  const normalized = (value - min) / (max - min);

  const MAX_RED = 204;
  const MAX_GREEN = 153;

  let red, green;
  if (normalized <= 0.5) {
    red = MAX_RED;
    green = Math.round(MAX_GREEN * (normalized * 2));
  } else {
    red = Math.round(MAX_RED * (2 - normalized * 2));
    green = MAX_GREEN;
  }

  return `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}00`;
};
