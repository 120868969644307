import React from "react";
import { ContentCard } from "../components/ContentCard";
import { PropertyInfoContent } from "./property/PropertyInfoContent";
import { PredictionOverviewPrice } from "../components/predictionOverview/PredictionOverviewPrice";
import { PropertyShared } from "../types/api.types";
import { BasicAppraiser } from "./propertiesCRUD/BasicAppraiser";
import { PropertyStatuses } from "./property/PropertyStatuses";
import { PropertyCommentContent } from "./property/PropertyCommentContent";
import { AppraiserRole } from "../types/appraiser.types";
import { PredictionCompositeAssets } from "../components/PredictionCompositeAssets";
import { ComparablesSummaryTable } from "../components/comparablesSummary/ComparablesSummaryTable";
import { getPredictionsWithRealPrices } from "../helpers/pricePredictions.helpers";
import { DescriptionsTable } from "../components/comparablesSummary/DescriptionsTable";
import { ValuationSummary } from "../components/comparablesSummary/ValuationSummary";
import { PropertyFileList } from "./property/PropertyFileList";
import { PropertyDistances } from "./property/distances/PropertyDistances";
import { PropertyEditButton } from "./property/PropertyEditButton";
import { CenteredContentWrapper } from "../components/wrappers/CenteredContentWrapper";
import { PropertyLeadContact } from "../components/properties/PropertyLeadContact";
import { PropertyMarketInsights } from "./property/marketInsights/PropertyMarketInsights";
import { useRouteQuery } from "../hooks/router.hooks";
import { ComparableSummaryTableChunked } from "../components/comparablesSummary/ComparableSummaryTableChunked";
import { PropertyGallery } from "./property/PropertyGallery";
import { PropertyLocationContent } from "./property/PropertyLocationContent";
import { PropertyLegalContent } from "./property/PropertyLegalContent";
import { AdjustmentsMap } from "../components/map/AdjustmentsMap";
import { Box } from "@mui/material";
import { FullReportBanner } from "./property/FullReportBanner";
import { LegalParagraphs } from "../components/content/LegalParagraphs";

interface PropertyShareProps {
  data: PropertyShared;
}

export const PropertyShare: React.FC<PropertyShareProps> = ({ data }) => {
  const printable = useRouteQuery().get("printable") === "true";

  const hasAppraiser =
    !!data.basicOrganization &&
    data.property.appraiser.role !== AppraiserRole.ORGANIZATION_LEAD;

  return (
    <div>
      {!printable && !!data.property.propertyStatuses && (
        <PropertyStatuses
          latestStatus={data.property.propertyRequestStatus}
          propertyStatuses={data.property.propertyStatuses}
        />
      )}
      {hasAppraiser && (
        <div
          style={{
            pageBreakAfter: "avoid",
          }}
        >
          <BasicAppraiser
            appraiser={data.property.appraiser}
            organization={data.basicOrganization}
            marginTop={0}
            confirmation={data.confirmation}
          />
        </div>
      )}
      {!!data.property.leadContact && (
        <PropertyLeadContact
          leadContact={data.property.leadContact}
          createdAt={data.property.createdAt.toString()}
          updatedAt={data.property.updatedAtWithToken}
          discountCode={data.property.order?.discountCode}
          expectedValue={data.property.expectedValue}
          addons={data.purchasedAddons}
          compositeAssets={data.purchasedCompositeAssets}
          hidePdf={printable}
          deliveryTimeLabel={
            data.property.order?.deliveryTimeLabel ?? undefined
          }
          depositPaid={data.totalDepositPaid}
        />
      )}
      {!!data.legal && (
        <PropertyLegalContent
          purpose={data.property.purpose}
          legals={data.legal}
        />
      )}
      {!!data.predictedPrice && (
        <ContentCard title="Galima turto kaina">
          {!!data.predictedPrice && (
            <PredictionOverviewPrice
              predictedPrice={data.predictedPrice}
              property={data.property}
            />
          )}
          {!!data.compositeAssets && (
            <PredictionCompositeAssets
              propertyId={data.property._id}
              compositeAssets={data.compositeAssets}
            />
          )}
        </ContentCard>
      )}
      {hasAppraiser && (
        <div style={{ marginBottom: "20px" }}>
          <FullReportBanner phone={data.property.appraiser.phoneNumber} />
        </div>
      )}

      {!data.hideProperty && (
        <ContentCard title="Informacija apie turtą">
          <PropertyInfoContent property={data.property} printable={printable} />
        </ContentCard>
      )}

      {!printable && (
        <CenteredContentWrapper>
          {data.editToken !== undefined && (
            <PropertyEditButton
              propertyId={data.property._id}
              disabled={!data.editToken}
              to={`/lead/details?createdPropertyId=${data.property._id}&simpleType=${data.property.simpleType}&editToken=${data.editToken}`}
            />
          )}
        </CenteredContentWrapper>
      )}

      {!!data.propertyComment && (
        <ContentCard title="Vertintojo komentaras">
          <PropertyCommentContent data={data.propertyComment} />
        </ContentCard>
      )}
      {!!data.documents?.length && (
        <ContentCard title="Dokumentai">
          <PropertyFileList files={data.documents} />
        </ContentCard>
      )}
      {!!data.locationDetails?.length && (
        <PropertyLocationContent locations={data.locationDetails} />
      )}
      {!!data.distances && (
        <PropertyDistances property={data.property} infras={data.distances} />
      )}
      {!!data.marketInsights && (
        <PropertyMarketInsights chartsData={data.marketInsights} />
      )}
      {!!data.pricePrediction && (
        <ContentCard title="Skaičiavimai">
          <Box
            id="property-adjustments-map"
            sx={{
              height: { xs: "300px", md: "450px" },
              mb: "30px",
            }}
          >
            <AdjustmentsMap
              property={data.property}
              comparables={data.pricePrediction.comparables}
              mapOptions={{
                mapTypeControl: false,
                streetViewControl: false,
                scrollwheel: undefined,
              }}
            />
          </Box>
          <ComparableSummaryTableChunked
            finalPricePredictions={data.pricePrediction}
            propertyId={data.property._id}
            propertyFromProps={data.property}
            chunkSize={printable ? 3 : null}
          />
        </ContentCard>
      )}
      {!!data.methodology && (
        <ContentCard title="Skaičiavimų metodologija">
          <LegalParagraphs legals={data.methodology} />
        </ContentCard>
      )}
      {printable && !data.hideGallery && (
        <PropertyGallery property={data.property} />
      )}
    </div>
  );
};
