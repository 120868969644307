import React from "react";
import { ChartData } from "../../types/api.types";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import colors from "common/styles/colors";
import { trimText } from "../../helpers/text.helpers";
import { BaseChart, ChartViewProps } from "./BaseChart";

interface ChartProps {
  chartData: ChartData;
}

export const Chart: React.FC<ChartProps & ChartViewProps> = ({
  chartData,
  ...props
}) => {
  const {
    title,
    chart_type,
    data,
    x_axis_label,
    y_axis_label,
    source,
    subtitle,
    description,
    layout
  } = chartData;

  return (
    <div>
      <h3>{title}</h3>
      {subtitle && (
        <p
          style={{
            color: colors.grey,
          }}
        >
          {subtitle}
        </p>
      )}
      {description && (
        <p
          style={{
            color: colors.grey,
          }}
        >
          {description}
        </p>
      )}
      <BaseChart
        data={data}
        chartType={chart_type}
        xAxisLabel={x_axis_label}
        yAxisLabel={y_axis_label}
        layout={layout}
        {...props}
      />
      <div
        style={{
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        Šaltinis:{" "}
        <a href={source} target="_blank">
          {trimText(source)}
        </a>
      </div>
    </div>
  );
};
