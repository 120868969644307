import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
} from "@mui/material";
import { SvgIcon } from "@mui/material";
import { SelectType } from "../../types/form.types";

interface CheckboxButtonGroupProps<T> {
  values: SelectType<T>[];
  onSelect?: (value: T[]) => void;
  onUnselect?: (value: T[]) => void;
  selectedValues: T[];
  disabledValues?: T[];
  icons?: React.ReactElement[];
  backgroundColor?: string;
}

export function CheckboxButtonGroup<T>(props: CheckboxButtonGroupProps<T>) {
  const handleToggle = (item: SelectType<T>) => () => {
    const childValues = getAllChildValues(item);
    const childValuesActive = childValues.filter(
      (v) => !props.disabledValues?.includes(v)
    );
    const allSelected = areAllChildrenSelected(childValues);

    if (item.children) {
      if (allSelected) {
        props.onUnselect?.(childValuesActive);
      } else {
        props.onSelect?.(
          childValuesActive.filter((v) => !props.selectedValues.includes(v))
        );
      }
    } else if (item.value !== undefined) {
      if (props.selectedValues.includes(item.value)) {
        props.onUnselect?.([item.value]);
      } else {
        props.onSelect?.([item.value]);
      }
    }
  };

  const getAllChildValues = (item: SelectType<T>): T[] => {
    if (!item.children) return item.value ? [item.value] : [];
    return item.children.flatMap(getAllChildValues);
  };

  const areAllChildrenSelected = (childValues: T[]): boolean => {
    return childValues.every((v) => props.selectedValues.includes(v));
  };

  const areSomeChildrenSelected = (childValues: T[]): boolean => {
    return childValues.some((v) => props.selectedValues.includes(v));
  };

  const renderItems = (items: SelectType<T>[], level = 0) => {
    return items.map((item, index) => {
      const childValues = getAllChildValues(item);
      const allSelected = areAllChildrenSelected(childValues);
      const someSelected = areSomeChildrenSelected(childValues);

      return (
        <React.Fragment key={`CheckboxButton${level}-${index}`}>
          <ListItemButton
            onClick={handleToggle(item)}
            disabled={childValues.every((v) =>
              props.disabledValues?.includes(v)
            )}
            style={{ height: 60, paddingLeft: level * 16 + 16 }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={allSelected}
                indeterminate={!allSelected && someSelected}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItemButton>
          <Divider />
          {item.children && renderItems(item.children, level + 1)}
        </React.Fragment>
      );
    });
  };

  return (
    <List style={{ backgroundColor: props.backgroundColor }}>
      {renderItems(props.values)}
    </List>
  );
}
