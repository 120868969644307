import React from "react";
import { PropertyInfra } from "../../../types/api.types";
import { getListString } from "common/helpers/common.helpers";
import { generateWorkTimesString } from "common/helpers/workTimeHelpers";
import colors from "common/styles/colors";
import { CommuteDistance } from "common/components/infra/CommuteDistance";
import { IconBadge } from "common/components/map/IconBadge";

interface PropertyDistanceCardProps {
  infra: PropertyInfra;
  infraNumber?: number;
}

export const PropertyDistanceCard: React.FC<PropertyDistanceCardProps> = ({
  infra,
  infraNumber,
}) => {

  const captionText = getListString([
    infra.address,
    infra.work_times ? generateWorkTimesString(infra.work_times) : undefined,
    infra.project_status,
    infra.rating_title
  ]);

  return (
    <div
      key={infra._id}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <div style={{ flex: 1 }}>
        <div
          style={{
            fontWeight: 500,
            fontSize: "14px",
            marginBottom: "5px",
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            color: infra.is_project ? colors.grey2 : undefined,
          }}
        >
          {infraNumber !== undefined && <IconBadge count={infraNumber} />}
          {infra.name}
        </div>
        <div style={{ fontSize: "14px", color: colors.grey }}>
          {captionText}
        </div>
      </div>
      <div>
        {infra.distances.map((dist, index) => {
          return (
            <CommuteDistance
              key={index}
              commutes={infra.distances}
              mode={dist.mode}
            />
          );
        })}
      </div>
    </div>
  );
};
