import React, { useEffect, useState } from "react";
import {
  rcTransactionDetails,
  rcTransactionsSearch,
} from "../api/rcTransactions.api";
import { Comparable, ComparableFull } from "common/types/common.types";
import { showToastError } from "common/toast/toast";
import { useQueryClient } from "react-query";
import { rcTransactionsProdMode } from "../api/constants";
import { useRCTransactions } from "../helpers/rcTransactions.hooks";
import { TransactionsSearchResponse } from "../types/api.types";

interface TransactionsFilteringData {
  searchResultsCount: number;
  failedGeocodingCount: number;
  multipleObjectsCount: number;
}

export interface TransactionsRCContextProps {
  baseTransactions?: Comparable[];
  fullTransactions?: ComparableFull[];
  buyTransaction?: (
    transactionId: string,
    propertyId: string
  ) => Promise<ComparableFull>;
  buyTransactions?: (
    transactionId: string[],
    propertyId: string
  ) => Promise<ComparableFull[]>;
  refetchBaseFilters?: (
    propertyId: string,
    saveResults?: boolean
  ) => Promise<void>;
  refetch?: (
    data: any,
    propertyId: string,
    allowBuy?: boolean,
    saveResults?: boolean
  ) => Promise<TransactionsSearchResponse | undefined>;
  isLoading?: boolean;
  isBuying?: string[];
  transactionsNeededToBuy?: number | undefined;
  dataUsed?: { data: any; propertyId: string } | undefined;
  searchCount?: number;
  transactionsFilteringData?: TransactionsFilteringData;
}

export const TransactionsRCContext =
  React.createContext<TransactionsRCContextProps>({});

interface TransactionsRCProviderProps {
  children?: React.ReactNode;
}

export const TransactionsRCProvider: React.FC<TransactionsRCProviderProps> = ({
  children,
}) => {
  const [baseTransactions, setBaseTransactions] = useState<Comparable[]>([]);
  const [fullTransactions, setFullTransactions] = useState<ComparableFull[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBuying, setIsBuying] = useState<string[]>([]);
  const [transactionsNeededToBuy, setTransactionsNeededToBuy] =
    useState<number>();
  const [dataUsed, setDataUsed] = useState<{ data: any; propertyId: string }>();

  const [searchCount, setSearchCount] = useState<number>(0);

  const [transactionsFilteringData, setTransactionsFilteringData] =
    useState<TransactionsFilteringData>();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (rcTransactionsProdMode) {
      return;
    }

    const savedFullTransactions = localStorage.getItem("fullTransactions");
    const savedBaseTransactions = localStorage.getItem("baseTransactions");

    if (savedBaseTransactions !== null) {
      setBaseTransactions(JSON.parse(savedBaseTransactions));
    }
    if (savedFullTransactions !== null) {
      setFullTransactions(JSON.parse(savedFullTransactions));
    }
  }, []);

  const saveTransactionsLocally = (
    baseTransactions: any,
    fullTransactions: any
  ) => {
    if (rcTransactionsProdMode) {
      return;
    }

    if (fullTransactions !== undefined)
      localStorage.setItem(
        "fullTransactions",
        JSON.stringify(fullTransactions)
      );
    if (baseTransactions !== undefined)
      localStorage.setItem(
        "baseTransactions",
        JSON.stringify(baseTransactions)
      );
  };

  const refetch = async (
    data: any,
    propertyId: string,
    allowBuy: boolean = false,
    saveResults: boolean = false
  ) => {
    setIsLoading(true);
    setTransactionsNeededToBuy(undefined);
    setDataUsed(undefined);
    try {
      const transactions = await rcTransactionsSearch(
        data,
        propertyId,
        allowBuy,
        saveResults
      );
      queryClient.invalidateQueries("used_credits");
      setSearchCount(searchCount + 1);
      setBaseTransactions(transactions.base_transactions);
      setFullTransactions(transactions.full_transactions);

      setTransactionsFilteringData({
        failedGeocodingCount: transactions.failed_geocoding_count,
        multipleObjectsCount: transactions.multiple_objects_count,
        searchResultsCount: transactions.search_count,
      });

      saveTransactionsLocally(
        transactions.base_transactions,
        transactions.full_transactions
      );

      return transactions;
    } catch (error: any) {
      const errorMessage = error?.response?.data;
      if (
        typeof errorMessage === "object" &&
        typeof errorMessage.transactions_count === "number"
      ) {
        setTransactionsNeededToBuy(errorMessage.transactions_count);
        setDataUsed({ data, propertyId });
        return;
      }

      showToastError(!!errorMessage ? errorMessage : "Įvyko klaida");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const { getRCFiltersQueryData } = useRCTransactions();

  const isUpdatePendingRef = React.useRef<string | undefined>();

  const refetchBaseFilters = async (
    propertyId: string,
    saveResults: boolean = false
  ) => {
    if (!isUpdatePendingRef.current) {
      isUpdatePendingRef.current = propertyId;
      return;
    }

    isUpdatePendingRef.current = undefined;

    const queryData = getRCFiltersQueryData();

    await refetch?.(queryData, propertyId, false, saveResults);
  };

  useEffect(() => {
    if (!isUpdatePendingRef.current) {
      return;
    }

    refetchBaseFilters(isUpdatePendingRef.current, true);
  }, [getRCFiltersQueryData]);

  const buyTransactions = async (
    transactionIds: string[],
    propertyId: string
  ) => {
    if (!transactionIds.length) {
      return [];
    }

    setIsBuying((oldBuying) => [...oldBuying, ...transactionIds]);
    try {
      const transactions = await rcTransactionDetails(
        transactionIds,
        propertyId
      );

      queryClient.invalidateQueries("used_credits");

      setFullTransactions((fullTransactions) => {
        const newFullTransactions = [...fullTransactions, ...transactions];
        saveTransactionsLocally(undefined, newFullTransactions);
        return newFullTransactions;
      });
      setBaseTransactions((baseTransactions) => {
        const newBaseTransactions = baseTransactions.filter(
          (tr) =>
            !transactions.some(
              (boughtTr) =>
                boughtTr.comparable_transaction.id ===
                tr.comparable_transaction.id
            )
        );
        saveTransactionsLocally(newBaseTransactions, undefined);
        return newBaseTransactions;
      });

      return transactions;
    } catch (error: any) {
      const errorMessage = error?.response?.data;
      showToastError(!!errorMessage ? errorMessage : "Įvyko klaida");
      throw error;
    } finally {
      setIsBuying((oldBuying) =>
        oldBuying.filter((id) => !transactionIds.includes(id))
      );
    }
  };

  const buyTransaction = async (transactionId: string, propertyId: string) => {
    const transaction = (await buyTransactions([transactionId], propertyId))[0];

    return transaction;
  };

  return (
    <TransactionsRCContext.Provider
      value={{
        baseTransactions,
        fullTransactions,
        buyTransaction,
        buyTransactions,
        refetchBaseFilters,
        refetch,
        isLoading,
        isBuying,
        transactionsNeededToBuy,
        dataUsed,
        searchCount,
        transactionsFilteringData,
      }}
    >
      {children}
    </TransactionsRCContext.Provider>
  );
};
