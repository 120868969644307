import React from "react";
import { useParams } from "react-router-dom";
import { useFinalPricePredictions } from "../../hooks/pricePredictions.hooks";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { TransparencyAdjustmentsCorrection } from "./TransparencyAdjustmentsCorrection";
import { TransparencySimilarity } from "./TransparencySimilarity";
import { useScrollToTop } from "../../hooks/router.hooks";

interface PricePredictionsTransparencyRouteProps {}

export const PricePredictionsTransparencyRoute: React.FC<
  PricePredictionsTransparencyRouteProps
> = () => {
  const { propertyId } = useParams();

  const { data, isLoading } = useFinalPricePredictions(propertyId ?? "", true);

  useScrollToTop();

  if (isLoading || !data) {
    return <LoaderCentered />;
  }

  return (
    <div>
      <TransparencyAdjustmentsCorrection data={data} />
      <TransparencySimilarity data={data} />
    </div>
  );
};
