import React, { useRef } from "react";
import {
  basicPermissions,
  PropertyPermission,
  PropertyShareSettings,
} from "./PropertyShareSettings";
import { useMutation } from "react-query";
import { generatePropertyShareLink } from "../../api/api";
import { MUILoadingButton } from "../../components/buttons/MUILoadingButton";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { setClipboard } from "common/clipboard/clipboard";

interface PropertyNewLinkShareSettingsProps {
  propertyId: string;
}

export const PropertyNewLinkShareSettings: React.FC<
  PropertyNewLinkShareSettingsProps
> = ({ propertyId }) => {
  const { isLoading: isLoadingLink, mutate: mutateLink } = useMutation(
    generatePropertyShareLink,
    {
      onSuccess: (data) => {
        setClipboard(data.link);
        showToastSuccess("Nuoroda nukopijuota į iškarpinę.");
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const { isLoading: isLoadingPdf, mutate: mutatePdf } = useMutation(
    generatePropertyShareLink,
    {
      onSuccess: (data) => {
        if (!data.pdfUrl) {
          showToastError();
          return;
        }
        window.location.href = data.pdfUrl;
      },
      onError: () => {
        showToastError();
      },
    }
  );

  const currentPermissions = useRef<PropertyPermission[]>(basicPermissions);

  const checkPermissions = () => {
    if (currentPermissions.current.length === 0) {
      showToastError("Pasirinkite bent vieną leidimą.");
      return false;
    }
    return true;
  };

  const mutate = (pdf?: boolean) => {
    const data = {
      propertyId,
      permissions: currentPermissions.current,
      pdf,
    };

    if (pdf) {
      mutatePdf(data);
    } else {
      mutateLink(data);
    }
  };

  return (
    <PropertyShareSettings
      initialPermissions={basicPermissions}
      onChange={(permissions) => {
        currentPermissions.current = permissions;
      }}
    >
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          gap: "10px",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <MUILoadingButton
          loading={isLoadingPdf}
          variant="outlined"
          onClick={() => {
            if (checkPermissions()) {
              mutate(true);
            }
          }}
        >
          Kurti PDF
        </MUILoadingButton>
        <MUILoadingButton loading={isLoadingLink} onClick={() => mutate()}>
          Kurti nuorodą
        </MUILoadingButton>
      </div>
    </PropertyShareSettings>
  );
};
