import React from "react";
import { useQuery } from "react-query";
import { getNearbySchools, getSchoolExamsResults, getSchoolRating } from "../../../api/api";
import { Chart } from "../../../components/charts/Chart";
import { SimpleTableComponent } from "../../../components/tables/simpleTable/SimpleTableComponent";
import { SimpleTableRow } from "../../../components/tables/simpleTable/SimpleTableRow";
import { SimpleTableHeader } from "../../../components/tables/simpleTable/SimpleTableHeader";
import { SimpleTableElement } from "../../../components/tables/simpleTable/SimpleTableElement";
import { Typography } from "common/components/Typography";
import { SimpleDialog } from "../../../components/dialogs/SimpleDialog";
import colors from "common/styles/colors";
import {
  PropertyInfra,
  SchoolExamResults,
  NearbySchools,
  SchoolRating,
} from "../../../types/api.types";
import { createChartData, processSchoolRating, getLocationDescription } from "../../../helpers/schoolExam.helpers";
import { LoaderCentered } from "../../../components/loaders/LoaderCentered";
import { IconBadge } from "common/components/map/IconBadge";
import { useRouteQuery } from "../../../hooks/router.hooks";

interface SchoolDialogProps {
  open: boolean;
  infra: PropertyInfra | null;
  propertyId: string;
  onClose: () => void;
}

export const SchoolDialog: React.FC<SchoolDialogProps> = ({
  open,
  infra,
  propertyId,
  onClose,
}) => {
  const query = useRouteQuery();
  
  const token = query.get("token");

  const { data: schoolRating, isLoading: isLoadingSchoolRating } = useQuery<SchoolRating | null>(
    ["schoolRating", propertyId, infra?.examResultsId],
    () =>
      infra ? getSchoolRating(propertyId, infra.examResultsId || "", token || "") : null,
    { enabled: open && !!infra?.examResultsId }
  );

  const { data: examResults } = useQuery<SchoolExamResults | null>(
    ["schoolExamResults", propertyId, infra?.examResultsId],
    () =>
      infra
        ? getSchoolExamsResults(propertyId, infra.examResultsId || "", token || "")
        : null,
    { enabled: open && !!infra?.examResultsId }
  );

  const { data: nearbySchools, isLoading: isLoadingNearbySchools } = useQuery<NearbySchools | null>(
    ["nearbySchools", propertyId, infra?.examResultsId],
    () =>
      infra ? getNearbySchools(propertyId, infra.examResultsId || "", token || "") : null,
    { enabled: open && !!infra }
  );

  const chartData = createChartData(examResults || null, infra?.name || "");
  const rating = processSchoolRating(schoolRating || null);

  const renderTableHeaders = () => (
    <SimpleTableRow>
      <SimpleTableHeader
        textAlign="left"
        width="90px"
        style={{ paddingLeft: "15px" }}
      >
        Vieta
      </SimpleTableHeader>
      <SimpleTableHeader textAlign="left" width="250px">
        Mokyklos pavadinimas
      </SimpleTableHeader>
      <SimpleTableHeader textAlign="right" width="200px">
        Egzaminų vidurkis
      </SimpleTableHeader>
      <SimpleTableHeader
        width="170px"
        style={{ paddingLeft: "15px", paddingRight: "15px" }}
      >
        Atstumas
      </SimpleTableHeader>
    </SimpleTableRow>
  );

  const renderTableRows = () =>
    nearbySchools?.top_schools.map((school, index) => (
      <SimpleTableRow
        key={school.school_name}
        style={{
          backgroundColor:
            school.school_name === infra?.name ? colors.purblebg : "inherit",
        }}
      >
        <SimpleTableElement
          isFirstElement={true}
          style={{ paddingLeft: "15px" }}
        >
          {index + 1}
        </SimpleTableElement>
        <SimpleTableElement textAlign="left">
          {school.school_name}
        </SimpleTableElement>
        <SimpleTableElement textAlign="right">
          {school.grade.toFixed(2)}
        </SimpleTableElement>
        <SimpleTableElement
          textAlign="right"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          ~{(school.distance/1000).toFixed(1)} km
        </SimpleTableElement>
      </SimpleTableRow>
    ));

  return (
    <SimpleDialog
      title={infra?.name || ""}
      open={open}
      onClose={onClose}
      hideButton={true}
    >
      {isLoadingSchoolRating ? (
        <LoaderCentered />
      ) : (
        <>
      <div style={{ display: "flex", marginBottom: "10px", gap: '8px', alignItems: 'center'}}>
        <IconBadge count={rating || "-"} width={40} height={40} backgroundColor={rating.includes("/") ? colors.purple2 : colors.grey} />
        <Typography variant="h4">TOP vertinimas</Typography>
      </div>
      <div>
        <Typography variant="caption">
          Įvertinimas pagrįstas 2024 metų {getLocationDescription(schoolRating || null)} mokyklų
          valstybinių brandos egzaminų bendru vidurkiu, apimančiu lietuvių kalbos, matematikos,
          anglų kalbos ir istorijos arba biologijos rezultatus.
        </Typography>
      </div>
      </>
        )}
      {chartData && (
        <Chart
          chartData={chartData}
          leftMargin={80}
          height={350}
          chartLabel={"Brandos egzamino rezultatas"}
          tickMinStep={10}
          max={100}
        />
      )}
      <div style={{ marginTop: "20px" }}>
        {isLoadingNearbySchools ? (
          <LoaderCentered />
        ) : (
          <>
            <Typography textStyle={{ display: "flex" }}>
              {nearbySchools?.location} mokyklų TOP10 sąrašas
            </Typography>

            <Typography
              variant="caption"
              textStyle={{
                display: "flex",
                color: colors.grey,
                marginTop: "10px",
              }}
            >
              {nearbySchools?.location_desc} esančių mokyklų TOP10 pagal 2024 metų
              valstybinių brandos egzaminų bendrą vidurkį.
            </Typography>
            <div
              style={{
                border: "1px solid",
                borderColor: colors.greybg,
                borderRadius: "10px",
                overflow: "hidden",
                marginTop: "20px",
                width: "100%",
                paddingTop: "15px",
              }}
            >
              <SimpleTableComponent>
                {renderTableHeaders()}
                {nearbySchools?.top_schools &&
                  renderTableRows()}
              </SimpleTableComponent>
            </div>
          </>
        )}
      </div>
    </SimpleDialog>
  );
};
