import React, { useEffect, useRef, useState } from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ContentCard } from "../../components/ContentCard";
import { Divider, Grid } from "@mui/material";
import { StyledImage } from "common/components/images/StyledImage";
import {
  PropertyPhotoCategory,
  PropertyPhotoCategoryTranslations,
} from "common/types/common.types";
import { Typography } from "common/components/Typography";
import { ImageGallery } from "../../components/imageGallery/ImageGallery";
import {
  getImageWidth,
  getResponsiveImageUri,
} from "common/helpers/image.helpers";
import { useScrollToTop } from "../../hooks/router.hooks";

interface PropertyGalleryProps {
  property: AppraiserProperty;
}

export const PropertyGallery: React.FC<PropertyGalleryProps> = ({
  property,
}) => {
  const windowWidthRef = useRef(window.innerWidth);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const images = property.images;

  useScrollToTop();
  if (!property.images.length) return null;

  return (
    <ContentCard title="Galerija">
      {Object.values(PropertyPhotoCategory).map((category) => {
        const filteredImages = images.filter(
          (image) =>
            (image.category ?? PropertyPhotoCategory.Other) === category
        );

        if (filteredImages.length === 0) {
          return null;
        }

        const categoryName = PropertyPhotoCategoryTranslations[category];

        const categoryDescription = property.photoCategories?.find(
          (photoCategory) => photoCategory.category === category
        )?.description;

        return (
          <div>
            {!!categoryName && (
              <Typography variant="h2">{categoryName}</Typography>
            )}
            {!!categoryDescription && (
              <div style={{ marginTop: "10px" }}>
                <Typography variant="caption">{categoryDescription}</Typography>
              </div>
            )}
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "10px",
              }}
            >
              {filteredImages.map((image, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <div
                    onClick={() => {
                      setIsGalleryOpen(true);
                      setSelectedImageIndex(
                        images.findIndex((img) => img === image)
                      );
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <StyledImage
                      imageProps={{
                        source: { uri: image.url },
                        resizeMode: "contain",
                      }}
                      height={400}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
            <Divider
              style={{
                marginTop: 30,
                marginBottom: 30,
              }}
            />
          </div>
        );
      })}
      <ImageGallery
        images={
          images?.map((img) =>
            getResponsiveImageUri(
              img.url,
              getImageWidth(windowWidthRef.current)
            )
          ) ?? []
        }
        isOpen={isGalleryOpen}
        onClose={() => {
          setIsGalleryOpen(false);
        }}
        headerText={property.address}
        defaultIndex={selectedImageIndex}
      />
    </ContentCard>
  );
};
