import React from "react";
import { Platform, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";
import {
  propertyIconBorderRadius,
  propertyIconSize,
  propertySelectedIconSize,
} from "./constants";
import colors from "../../styles/colors";
import { shadow5 } from "../../styles/shadows.styles";
import OkCheckIcon from "../../assets/icons/okCheck.svg";
import { Typography } from "../Typography";
import { IconBadge } from "./IconBadge";

export interface PropertyIconProps {
  isSelected?: boolean;
  iconOpacity?: number;
  borderColorAndroid?: string;
  backgroundColor?: string;
  withOkIcon?: boolean;
  multipleCount?: number;
  customText?: string;
  forceMultipleCount?: boolean;
  sizeMultiplier?: number;
  customIcon?: React.ReactElement;
  badgeCount?: number;
}

export const PropertyIcon: React.FC<PropertyIconProps> = ({
  isSelected,
  iconOpacity,
  borderColorAndroid,
  backgroundColor,
  withOkIcon,
  multipleCount,
  sizeMultiplier,
  customIcon,
  forceMultipleCount,
  badgeCount,
  customText,
}) => {
  const color = backgroundColor ?? colors.purple2;
  const borderStyle: ViewStyle =
    Platform.OS === "android"
      ? {
          borderWidth: 0.5,
          borderColor: borderColorAndroid ?? backgroundColor,
        }
      : {};

  if (isSelected) {
    return (
      <View style={propertySelectedIconSize}>
        <View
          style={[
            {
              width: propertySelectedIconSize.width,
              height: propertySelectedIconSize.height - 5,
              backgroundColor: color,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: propertyIconBorderRadius,
            },
            shadow5,
            borderStyle,
          ]}
        >
          <Svg width="26" height="19" viewBox="0 0 26 19" fill="none">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.29313 6.49298L13.0001 2.05421L18.7071 6.49298V17.1589H7.29313V6.49298ZM5.69313 7.73742L1.77869 10.782L0.796387 9.51902L12.509 0.409241C12.7978 0.18455 13.2024 0.18455 13.4913 0.409241L25.2038 9.51902L24.2215 10.782L20.3071 7.73742V17.9589C20.3071 18.4007 19.9489 18.7589 19.5071 18.7589H6.49313C6.0513 18.7589 5.69313 18.4007 5.69313 17.9589V7.73742Z"
              fill="white"
              fillOpacity={iconOpacity}
            />
          </Svg>
          <View
            style={[
              {
                height: 8.38,
                width: 8.38,
                transform: [{ rotate: "45deg" }],
                position: "absolute",
                bottom: -3,
                backgroundColor: color,
              },
            ]}
          />
        </View>
      </View>
    );
  }

  return (
    <View>
      <View
        style={[
          {
            ...propertyIconSize,
            backgroundColor: color,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: propertyIconBorderRadius,
            transform: [{ scale: sizeMultiplier ?? 1 }],
          },
          shadow5,
          borderStyle,
        ]}
      >
        {!!customText || (multipleCount ?? 0) > 1 || forceMultipleCount ? (
          <Typography
            variant="h5"
            textStyle={{
              color:
                backgroundColor === colors.white || backgroundColor === "white"
                  ? colors.purple2
                  : colors.white,
            }}
          >
            {customText ?? multipleCount}
          </Typography>
        ) : !!customIcon ? (
          customIcon
        ) : (
          <Svg
            width="20"
            height="15"
            viewBox="0 0 20 15"
            style={{ marginTop: -1 }}
          >
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.79994 5.28035L9.99994 2.01368L14.1999 5.28035V13.2002H5.79994V5.28035ZM4.19994 6.5248L1.49109 8.63168L0.508789 7.36871L9.50879 0.368713C9.79768 0.144022 10.2022 0.144022 10.4911 0.368713L19.4911 7.36871L18.5088 8.63168L15.7999 6.5248V14.0002C15.7999 14.442 15.4418 14.8002 14.9999 14.8002H4.99994C4.55811 14.8002 4.19994 14.442 4.19994 14.0002V6.5248Z"
              fill="white"
              fillOpacity={iconOpacity}
            />
          </Svg>
        )}
        {withOkIcon && (
          <View
            style={{
              position: "absolute",
              right: -8,
              top: -3,
            }}
          >
            <OkCheckIcon />
          </View>
        )}
      </View>
      {badgeCount !== undefined && (
        <View
          style={{
            position: "absolute",
            right: -10,
            top: -8,
          }}
        >
          <IconBadge count={badgeCount} />
        </View>
      )}
    </View>
  );
};
