import React from "react";
import { ContentCard } from "../../components/ContentCard";
import { SettingsRow } from "./SettingsRow";
import { Toggle } from "../../components/form/toggle/Toggle";
import { useOrganization } from "../../hooks/organization.hooks";
import { useMutation, useQueryClient } from "react-query";
import { setOrganizationTransactionsRetention } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { Organization } from "../../types/api.types";
import { getEndingByCountLT4 } from "common/helpers/text.helpers";
import { OrganizationProfileForm } from "./OrganizationProfileForm";
import { TransactionsRetentionSettings } from "./TransactionsRetentionSettings";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";

interface OrganizationSettingsRouteProps {}

export const OrganizationSettingsRoute: React.FC<
  OrganizationSettingsRouteProps
> = () => {
  const { data } = useOrganization();

  if (!data) {
    return null;
  }

  return (
    <>
      <ContentCard title="Nustatymai">
        <SettingsRow label="RC galutinis naudotojas">
          {!!data.RC_EndUserInfo &&
            Object.entries(data.RC_EndUserInfo).map(([key, value]) => (
              <div key={key}>
                <b>{key}</b>: {value}
              </div>
            ))}
        </SettingsRow>
        <div style={{ height: "30px" }} />
        <SettingsRow label="Sandorių saugojimo laikotarpis">
          <TransactionsRetentionSettings organization={data} />
        </SettingsRow>
        <div style={{ marginTop: "50px" }} />
      </ContentCard>
      <OrganizationProfileForm />
    </>
  );
};
