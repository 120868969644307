import React from "react";
import { AppraiserProperty } from "../../types/appraiser.types";
import { ComparableTransactionFull } from "common/types/common.types";
import { useQuery } from "react-query";
import { getAdjustmentCoeffs } from "../../api/api";
import { PropertyRow } from "../../components/PropertyRow";
import { TableComponent } from "../../components/tables/TableComponent";
import { round } from "common/helpers/common.helpers";
import { adjustmentFields } from "common/helpers/comparables.helpers";

interface TransparencyAdjustmentsCoeffsProps {
  field: keyof ComparableTransactionFull;
  provider: string;
  property: AppraiserProperty;
  comparable: ComparableTransactionFull | undefined;
}

export const TransparencyAdjustmentsCoeffs: React.FC<
  TransparencyAdjustmentsCoeffsProps
> = ({ field, provider, property, comparable }) => {
  const { data, isLoading } = useQuery(
    ["adjustments_coeffs", field, provider, property._id, comparable?.id],
    () => getAdjustmentCoeffs(field, provider, property._id, comparable),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <div>Kraunama...</div>;
  }

  const coeff = typeof data?.coeff === "number" ? round(data?.coeff, 3) : "-";

  const fieldValue = comparable
    ? adjustmentFields[field]?.fieldValue(comparable)
    : adjustmentFields[field]?.propertyFieldValue(property);

  return (
    <div>
      <div>{`Koef. ${coeff} (${fieldValue})`}</div>
    </div>
  );
};
