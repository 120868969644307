import { useCallback, useContext } from "react";
import {
  DesignationType,
  getTransactionREType,
} from "common/types/comparableFields.types";
import { ComparableFilterType } from "./comparableFilters.helpers";
import { ComparablesFiltersContext } from "../components/ComparablesFilterProvider";
import { SavedComparableFilter } from "../types/api.types";

export function getRCFiltersQuery(
  filters: (SavedComparableFilter | undefined)[]
) {
  let queryData = {};

  const addZeroToMonth = (month: number) => {
    return month < 10 ? `0${month}` : month;
  };

  for (const filter of filters) {
    if (!filter) {
      continue;
    }
    switch (filter.type) {
      case ComparableFilterType.Area:
        queryData = {
          ...queryData,
          plotas_nuo: filter.values?.[0]?.toString(),
          plotas_iki: filter.values?.[1]?.toString(),
        };
        break;
      case ComparableFilterType.Year:
        queryData = {
          ...queryData,
          metai_nuo: filter.values?.[0]?.toString(),
          metai_iki: filter.values?.[1]?.toString(),
        };
        break;
      case ComparableFilterType.Dates:
        const dates = filter.values;
        queryData = {
          ...queryData,
          sdata_nuo: `${dates?.start.year}-${addZeroToMonth(
            dates?.start.month
          )}`,
          sdata_iki: `${dates?.end.year}-${addZeroToMonth(dates?.end.month)}`,
        };
        break;
      case ComparableFilterType.ValuationZone:
        queryData = {
          ...queryData,
          zove_nr: filter.values?.join(","),
        };
        break;
      case ComparableFilterType.Designation:
        const designationTypes = [...filter.values];
        if (designationTypes.includes(DesignationType.Manufacturing)) {
          designationTypes.push(DesignationType.ManufacturingIndustry);
        }
        queryData = {
          ...queryData,
          pask_tipas: designationTypes.join(","),
        };
        break;
    }
  }
  return queryData;
}

export function useRCTransactions() {
  const { getAppliedFilter } = useContext(ComparablesFiltersContext);

  const getRCFiltersQueryData = useCallback(() => {
    const rc_filters = [
      ComparableFilterType.Area,
      ComparableFilterType.Year,
      ComparableFilterType.Dates,
      ComparableFilterType.ValuationZone,
      ComparableFilterType.Designation,
    ];

    const queryData = getRCFiltersQuery(
      rc_filters.map((filter) => getAppliedFilter?.(filter))
    );

    return queryData;
  }, [getAppliedFilter]);

  return { getRCFiltersQueryData };
}
