import React from "react";
import { Typography } from "../Typography";

interface FieldLabelProps {
  label: string;
  children?: React.ReactNode;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({ label, children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        textStyle={{
          fontSize: 16,
        }}
      >
        {label}
      </Typography>
      {children}
    </div>
  );
};
