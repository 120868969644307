import { Typography } from "common/components/Typography";
import { formatPrice, getEndingByCountLT1 } from "common/helpers/text.helpers";
import {
  getComparableAddress,
  getComparableImages,
} from "common/helpers/comparables.helpers";
import { Adjustments, Comparable } from "common/types/common.types";
import React, { useContext, useState } from "react";
import colors from "common/styles/colors";
import { getListString } from "common/helpers/common.helpers";

import { IconButton } from "common/components/buttons/IconButton";
import AddButtonIcon from "../../assets/icons/addButton.svg";
import RemoveButtonIcon from "../../assets/icons/removeButton.svg";
import { PropertyPlaceholderImage } from "../imageGallery/PropertyPlaceholderImage";
import ArrowAdjustmentDownIcon from "../../assets/icons/arrowAdjustmentDown.svg";
import ArrowAdjustmentUpIcon from "../../assets/icons/arrowAdjustmentUp.svg";
import { addAlpha } from "common/helpers/colors.helpers";
import { RelatedRETag } from "../RelatedRETag";
import {
  calcSoldPrice,
  getPercentage,
  getReservationText,
  getYearText,
  isComparableFull,
  uniqueByType,
} from "../../helpers/comparable.helpers";
import { ComparableCardMenu } from "./ComparableCardMenu";
import { MessageType, StatusMessage } from "../../types/api.types";
import {
  getMessageTypeDescription,
  getMessageTypeIcon,
} from "../messages/helpers";
import {
  Box,
  CircularProgress,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import { TransactionsRCContext } from "../TransactionsRCProvider";
import { ComparableNumber } from "./ComparableNumber";
import { getPropertyListString } from "../../helpers/property.helpers";
import { PropertyImageGallery } from "../imageGallery/PropertyImageGallery";
import { LinkButton } from "../buttons/LinkButton";
import LightTooltip from "../tooltips/StyledTooltip";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/MUITheme";

interface ComparableCardProps {
  comparable: Comparable;
  onClick?: () => void;
  onComparableRemove?: () => void;
  onComparableAdd?: () => void;
  isInCart?: boolean;
  isCorrected?: boolean;
  adjustments?: Adjustments;
  children?: React.ReactNode;
  withButtons?: boolean;
  status?: StatusMessage;
  withNumber?: number;
  isOverview?: boolean;

  noBorder?: boolean;
}

export const ComparableCard: React.FC<ComparableCardProps> = ({
  comparable,
  onClick,
  onComparableAdd,
  onComparableRemove,
  isCorrected,
  isInCart,
  adjustments,
  children,
  withButtons,
  status,
  withNumber,
  isOverview,

  noBorder,
}) => {
  const images = getComparableImages(comparable.comparable_transaction);

  const address = getComparableAddress(comparable.comparable_transaction);

  const adjustedPriceArea = adjustments?.adjusted_price_area ?? 0;

  const isFull = isComparableFull(comparable);

  const transaction_price =
    isFull && formatPrice(comparable.comparable_transaction.sold_price);
  const transaction_price_area =
    isFull &&
    `(${formatPrice(comparable.comparable_transaction.sold_price_area)}/m²)`;

  const perc =
    isFull &&
    !!adjustments &&
    getPercentage(
      adjustedPriceArea,
      calcSoldPrice(adjustments, comparable.comparable_transaction) /
        comparable.comparable_transaction.area
    );

  const transaction_date = comparable.comparable_transaction.sold_date;

  const captionString = getPropertyListString({
    area: comparable.comparable_transaction.area,
    rooms: isFull ? comparable.comparable_transaction.rooms : undefined,
    floorNr: isFull ? comparable.comparable_transaction.floor : undefined,
    floorCount: isFull
      ? comparable.comparable_transaction.floor_total
      : undefined,
    year: comparable.comparable_transaction.construction_year,
    renovationYear: isFull
      ? comparable.comparable_transaction.reconstruction_year
      : undefined,
    energyClass: isFull
      ? comparable.comparable_transaction.energy_class
      : undefined,
    finishing: isFull
      ? !!comparable.comparable_transaction.finishing
      : undefined,
  });

  const percentageColor =
    !!perc && (perc.percentage < 0 ? colors.red : colors.green);

  const matchedProject = comparable.comparable_transaction.matched_project;

  const StatusIcon = !!status ? getMessageTypeIcon(status.type) : undefined;

  const { isBuying } = useContext(TransactionsRCContext);

  const [isImageHovered, setIsImageHovered] = useState(false);

  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false); // to prevent visible tooltip on navigation

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        cursor: !!onClick ? "pointer" : "auto",
        position: "relative",
        minHeight: "180px",
        maxHeight: "200px",

        ...(!noBorder && {
          border: `1px solid ${colors.greyborder}`,
          borderRadius: "10px",
          backgroundColor: colors.white,
        }),
      }}
      onClick={onClick}
    >
      <PropertyImageGallery
        key={comparable.comparable_transaction.id}
        images={images}
        borderBottomLeftRadius={noBorder ? 0 : 10}
        borderTopLeftRadius={noBorder ? 0 : 10}
        height="100%"
        width={isMobile ? 110 : 180}
        borderRadius={0}
        onMouseEnter={() => setIsImageHovered(true)}
        onMouseLeave={() => setIsImageHovered(false)}
      />
      {!!isCorrected && (
        <div
          style={{
            position: "absolute",
            top: 4,
            left: 4,
            backgroundColor: colors.grey,
            lineHeight: 0,
            borderRadius: "5px",
            padding: "4px 8px",
          }}
        >
          <Typography
            variant="h4"
            textStyle={{ color: colors.white, fontSize: 12 }}
          >
            Peržiūrėta
          </Typography>
        </div>
      )}
      {!!images.length && isImageHovered && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: addAlpha(colors.grey, 0.8),
            lineHeight: 0,
            borderBottomLeftRadius: "10px",
            padding: "4px 8px",
            color: colors.white,
            height: "28px",
            display: "flex",
            alignItems: "center",
            fontSize: 12,
            pointerEvents: "none",
          }}
        >
          {`${images.length} nuotrauk${getEndingByCountLT1(
            images.length
          ).toLowerCase()}`}
        </div>
      )}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          minWidth: { xs: "200px", sm: "280px" },
          width: "100%",
        }}
      >
        {!!onComparableRemove && onComparableAdd && (
          <div style={{ position: "absolute", right: 0, top: 0 }}>
            <IconButton
              onPress={() => {
                if (isInCart) {
                  onComparableRemove();
                } else {
                  onComparableAdd();
                }
              }}
            >
              {isBuying?.includes(comparable.comparable_transaction.id) ? (
                <CircularProgress size={20} />
              ) : isInCart ? (
                <RemoveButtonIcon />
              ) : (
                <AddButtonIcon />
              )}
            </IconButton>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 5,
            gap: 2,
            padding: 10,
          }}
        >
          {!!matchedProject && (
            <Typography variant="h5" textStyle={{ color: colors.purple3 }}>
              {matchedProject.project_name}
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {withNumber !== undefined && (
              <ComparableNumber compNumber={withNumber} />
            )}
            {!!StatusIcon && !!status && !isNavigating && (
              <div style={{ marginRight: "5px" }}>
                <LightTooltip
                  title={
                    <div>
                      {!!status.message
                        ? status.message
                        : getMessageTypeDescription(status.type)}
                      <LinkButton
                        onClick={() => {
                          setIsNavigating(true);
                          navigate(
                            `${comparable.comparable_transaction.id}/messages`
                          );
                          setTimeout(() => {
                            setIsNavigating(false);
                          }, 200);
                        }}
                      >
                        Daugiau
                      </LinkButton>
                    </div>
                  }
                  arrow
                >
                  <div style={{ height: "18px" }}>
                    <StatusIcon />
                  </div>
                </LightTooltip>
              </div>
            )}
            <Typography variant="h5" textStyle={{ marginRight: "20px" }}>
              {address}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{transaction_price}</Typography>
            <Typography style={{ fontSize: 14, marginLeft: 6 }}>
              {transaction_price_area}
            </Typography>
            {!!perc && !!percentageColor && (
              <div
                style={{
                  padding: "4px",
                  backgroundColor: addAlpha(percentageColor, 0.2),
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px",
                  marginLeft: "8px",
                }}
              >
                <Typography
                  textStyle={{
                    fontSize: 12,
                    color: percentageColor,
                    marginRight: "2px",
                  }}
                >
                  {perc.percentageText}
                </Typography>
                {perc.percentage < 0 ? (
                  <ArrowAdjustmentDownIcon />
                ) : (
                  <ArrowAdjustmentUpIcon />
                )}
              </div>
            )}
          </div>
          <Typography textStyle={{ fontSize: 12, color: colors.green }}>
            Sandorio data {transaction_date}
          </Typography>
          <Typography
            variant="caption"
            textStyle={{ fontSize: 12, marginTop: 5 }}
          >
            {captionString}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "4px 12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            {uniqueByType(
              comparable.comparable_transaction.related_re ?? []
            ).map((related, index) => (
              <RelatedRETag key={index} re={related} />
            ))}
          </div>
          {!isOverview && <ComparableCardMenu comparable={comparable} />}
        </div>
        {children}
      </Box>
    </Box>
  );
};
