import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import colors from "common/styles/colors";
import { ChartType } from "../../types/api.types";
import { CenteredContentWrapper } from "../wrappers/CenteredContentWrapper";

interface ChartDataItem {
  x: string | number;
  y: number;
}

export interface ChartViewProps {
  width?: number;
  leftMargin?: number;
  height?: number;
  chartLabel?: string;
  tickMinStep?: number;
  max?: number;
}

interface BaseChartProps extends ChartViewProps {
  data: ChartDataItem[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  chartType?: ChartType;
  tickPlacement?: "middle" | "start" | "end" | "extremities";
  tickLabelPlacement?: "tick" | "middle";
  barLabel?: "value";
  colorMap?: any;
  curve?: "linear";
  layout?: "vertical" | "horizontal";
  width?: number;
  leftMargin?: number;
  height?: number;
  chartLabel?: string;
}

export const BaseChart: React.FC<BaseChartProps> = ({
  data,
  xAxisLabel,
  yAxisLabel,
  chartType,
  tickPlacement,
  tickLabelPlacement,
  barLabel,
  colorMap,
  curve,
  layout = "vertical",
  width,
  leftMargin,
  height,
  chartLabel,
  tickMinStep,
  max,
}) => {
  const isDataEmpty = data.length === 0;

  const xAxisData = isDataEmpty ? ["-"] : data.map((item) => item.x);
  const yAxisData = isDataEmpty ? [] : data.map((item) => item.y);

  const isHorizontal = layout === "horizontal";

  const xAxis = {
    data: xAxisData,
    label: xAxisLabel,
    scaleType: "band" as const,
    tickPlacement,
    tickLabelPlacement,
    colorMap,
  };

  const yAxis = {
    label: yAxisLabel,
    tickMinStep: tickMinStep,
    max: max,
    min: 0,
  };

  const commonProps = {
    xAxis: [isHorizontal ? yAxis : xAxis],
    yAxis: [isHorizontal ? xAxis : yAxis],
    series: [
      {
        curve,
        data: yAxisData,
        color: colors.purple2,
        label: !isDataEmpty ? chartLabel : undefined,
      },
    ],
    height: height || 400,
    margin: {
      left: !!yAxisLabel ? 80 : leftMargin || 40,
    },
    width: width,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: !!yAxisLabel ? "translateX(-30px)" : undefined,
      },
    },
    barLabel,
  };

  return (
    <div style={{ position: "relative", width: width, height: height }}>
      {chartType === ChartType.Lines ? (
        <LineChart {...commonProps} />
      ) : (
        <BarChart
          {...commonProps}
          layout={layout}
          slotProps={{
            legend: {
              position: { vertical: "bottom", horizontal: "left" },
              padding: 0,
            },
            noDataOverlay: {
              message: "Nėra duomenų.",
            },
          }}
        />
      )}
    </div>
  );
};

export default BaseChart;
