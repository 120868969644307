import React from "react";

interface AddonsWrapperProps {
  children: React.ReactNode;
}

export const AddonsWrapper: React.FC<AddonsWrapperProps> = ({ children }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
        gap: "20px",
      }}
    >
      {children}
    </div>
  );
};
