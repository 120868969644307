import colors from "common/styles/colors";
import React from "react";

interface ComparablesCountOverviewWrapperProps {
  children?: React.ReactNode;
  title: string;
  noBackground?: boolean;
}

export const ComparablesCountOverviewWrapper: React.FC<
  ComparablesCountOverviewWrapperProps
> = ({ children, title, noBackground }) => {
  return (
    <div
      style={{
        backgroundColor: noBackground ? colors.white : colors.greybg,
        padding: noBackground ? 0 : "20px",
        borderRadius: "5px",
      }}
    >
      <div style={{ fontWeight: 600, marginBottom: "10px" }}>{title}</div>
      {children}
    </div>
  );
};
