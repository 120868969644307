import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getBasicAppraiser } from "../../api/api";
import { StyledImage } from "common/components/images/StyledImage";
import colors from "common/styles/colors";
import { InfoRow } from "./InfoRow";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "../../styles/MUITheme";
import { BasicAppraiser as BasicAppraiserType } from "../../types/appraiser.types";
import { BasicInfoCard } from "./BasicInfoCard";
import { BasicOrganization, Confirmation } from "../../types/api.types";

interface BasicAppraiserProps {
  appraiser: BasicAppraiserType;
  organization?: BasicOrganization;
  marginTop?: number;
  confirmation?: Confirmation | null;
}

export const BasicAppraiser: React.FC<BasicAppraiserProps> = ({
  appraiser,
  organization,
  marginTop,
  confirmation,
}) => {
  return (
    <BasicInfoCard
      imageUrl={appraiser.profileImageUrl}
      name={appraiser.fullName}
      description={appraiser.positionTitle}
      infoRows={[
        { label: "El. pašto adresas", value: appraiser.email },
        { label: "Telefono numeris", value: appraiser.phoneNumber },
        { label: "Tinklapis", value: appraiser.website },
      ]}
      logoUrl={organization?.logo}
      marginTop={marginTop}
      confirmation={confirmation}
    />
  );
};
