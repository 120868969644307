import { round } from "common/helpers/common.helpers";
import React from "react";

export interface PercentageBarItem {
  icon: React.ReactNode;
  value: number;
  color: string;
}

interface PercentageBarProps {
  items: PercentageBarItem[];
}

const height = "16px";

export const PercentageBar: React.FC<PercentageBarProps> = ({ items }) => {
  return (
    <div
      style={{
        width: "100%",
        height,
        position: "relative",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        borderStyle: "solid",
        borderWidth: "1px",
      }}
    >
      {items.map((item, index) => {
        if (item.value === 0) {
          return null;
        }
        return (
          <div
            key={index}
            style={{
              width: `${round(Math.min(item.value, 1) * 100)}%`,
              minWidth: "16px",
              height,
              backgroundColor: item.color,
              display: "flex",
              alignItems: "center",
              paddingLeft: "4px",
            }}
          >
            {item.icon}
          </div>
        );
      })}
    </div>
  );
};
