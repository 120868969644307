import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useProperty,
  usePropertyEditRights,
  usePropertyPurchasedAddons,
} from "../../hooks/property.hooks";
import { ContentCard } from "../../components/ContentCard";
import { Typography } from "common/components/Typography";
import { PropertyRow } from "../../components/PropertyRow";
import { MarkerMap } from "../../components/map/MarkerMap";
import { Button, Divider, Link } from "@mui/material";
import { PredictionOverview } from "../../components/predictionOverview/PredictionOverview";
import { PropertyImageGallery } from "../../components/imageGallery/PropertyImageGallery";
import { useAppraiser } from "../../hooks/auth.hooks";
import {
  AppraiserRole,
  PropertyRequestStatus,
} from "../../types/appraiser.types";
import { LinkForButton } from "../../components/links/LinkForButton";
import colors from "common/styles/colors";
import { DeleteDialog } from "../../components/dialogs/DeleteDialog";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteAppraiserProperty } from "../../api/appraiserProperties.api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { PropertyCreditsOverview } from "../../components/map/PropertyCreditsOverview";
import { adjustmentFields } from "common/helpers/comparables.helpers";
import { setClipboard } from "common/clipboard/clipboard";
import { PropertyInfoContent } from "./PropertyInfoContent";
import ShareIcon from "@mui/icons-material/Share";
import { PropertyLeadContact } from "../../components/properties/PropertyLeadContact";
import { PropertyShareButton } from "./PropertyShareButton";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { BasicAppraiser } from "../propertiesCRUD/BasicAppraiser";
import { PropertyStatuses } from "./PropertyStatuses";
import { PropertyComment } from "./PropertyComment";
import { PropertyAppraiserSet } from "./PropertyAppraiserSet";
import { PredictionCompositeAssets } from "../../components/PredictionCompositeAssets";
import { PropertyFileUpload } from "./PropertyFileUpload";
import { PropertyEditButton } from "./PropertyEditButton";
import {
  getDepositPaid,
  getPropertyPurchasedAddons,
  getPropertyPurchasedCompositeAssets,
} from "../../api/api";
import { PropertyWithGallery } from "./PropertyWithGallery";
import { ExpirationTimeTag } from "../../components/tags/ExpirationTimeTag";
import { AutoPricePrediction } from "../../components/predictionOverview/AutoPricePrediction";
import { TransactionsRCProvider } from "../../components/TransactionsRCProvider";

interface PropertyProps {
  propertyId: string;
}

export const Property: React.FC<PropertyProps> = ({ propertyId }) => {
  const { data: property, isLoading, isError } = useProperty(propertyId);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate: deleteProperty } = useMutation(deleteAppraiserProperty, {
    onSuccess: () => {
      queryClient.invalidateQueries(["appraiserProperties"]);
      navigate("..");
    },
    onError: () => {
      showToastError();
    },
  });

  const { canEdit } = usePropertyEditRights(propertyId);

  const { data: appraiser, isLoading: isLoadingAppraiser } = useAppraiser();

  const { data: addons, isLoading: isLoadingAddons } =
    usePropertyPurchasedAddons(propertyId);

  const {
    data: purchasedCompositeAssets,
    isLoading: isLoadingCompositeAssets,
  } = useQuery(["compositeAssets", propertyId], () =>
    getPropertyPurchasedCompositeAssets(propertyId)
  );

  const { data: depositPaid, isLoading: isLoadingDeposits } = useQuery(
    ["depositPaid", propertyId],
    () => getDepositPaid(propertyId)
  );

  if (
    isLoading ||
    isLoadingAppraiser ||
    isLoadingAddons ||
    isLoadingCompositeAssets ||
    isLoadingDeposits
  ) {
    return <LoaderCentered />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  if (!property) {
    return null;
  }

  return (
    <PropertyWithGallery property={property}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {appraiser?.role === AppraiserRole.ADMIN && (
          <PropertyAppraiserSet property={property} />
        )}
        {!!property.leadContact && (
          <PropertyLeadContact
            leadContact={property.leadContact}
            createdAt={property.createdAt.toString()}
            updatedAt={property.updatedAtWithToken}
            discountCode={property.order?.discountCode}
            expectedValue={property.expectedValue}
            addons={addons}
            compositeAssets={purchasedCompositeAssets}
            deliveryTimeLabel={property.order?.deliveryTimeLabel ?? undefined}
            timeLeftBadge={
              !!property.order?.deliveryDate &&
              property.propertyRequestStatus !==
                PropertyRequestStatus.Completed ? (
                <ExpirationTimeTag deliveryDate={property.order.deliveryDate} />
              ) : undefined
            }
            depositPaid={depositPaid?.totalDepositPaid}
            showLinkToMessages
          />
        )}
        <ContentCard title="Informacija apie turtą">
          <PropertyInfoContent property={property} />
        </ContentCard>

        {canEdit && <PropertyEditButton propertyId={property._id} />}
        <ContentCard title="Galima turto kaina">
          <PredictionOverview property={property} hasEditRights={canEdit} />

          <div style={{ height: "15px" }} />
          <PropertyCreditsOverview style={{ marginTop: "15px" }} withHeader />
        </ContentCard>
        <PropertyFileUpload propertyId={propertyId} />
        <PropertyFileUpload
          customHeader="Užsakovo įkelti dokumentai"
          isCustomer
          customEditRight={false}
          propertyId={propertyId}
        />
        <PropertyComment propertyId={propertyId} />
        {canEdit && (
          <>
            <Link
              color={colors.red}
              style={{ cursor: "pointer", alignSelf: "flex-end" }}
              onClick={() => setIsDeleteOpen(true)}
            >
              Ištrinti turtą
            </Link>
            <DeleteDialog
              open={isDeleteOpen}
              onCancel={() => setIsDeleteOpen(false)}
              onConfirm={() => deleteProperty(property._id)}
              confirmationText={`Ar tikrai norite ištrinti turtą ${property.label} ?`}
            />
          </>
        )}
      </div>
    </PropertyWithGallery>
  );
};
