import { useMutation } from "react-query";
import {
  addMyProperty,
  deleteMyPropertyPhotos,
  updateMyProperty,
  uploadMyPropertyPhotos,
} from "../api/api";
import { MyProperty, MyPropertyAddRequest } from "../types/common.types";
import { showToastError } from "../toast/toast";
import { useContext, useEffect, useState } from "react";
import CommonConfig from "../commonConfig";
import { getErrorMessage } from "../helpers/error.helpers";
import { useRouteQuery } from "../../web-valuations/src/hooks/router.hooks";
import { PropertyCreationContext } from "../../web-valuations/src/routes/propertiesCRUD/PropertyCreationProvider";

export function useDeleteMyPropertyPhotosMutation(
  apiFunction = deleteMyPropertyPhotos
) {
  return useMutation(apiFunction, {
    onError: (error) => {
      showToastError("Nuotraukų ištrinti nepavyko");
      CommonConfig.errorHandler(error);
    },
  });
}

export function useUploadMyPropertyPhotosMutation(
  apiFunction = uploadMyPropertyPhotos
) {
  return useMutation(
    ({ formData, propertyId }: { formData: FormData; propertyId: string }) =>
      apiFunction(propertyId, formData),
    {
      onError: (error) => {
        showToastError("Nuotraukų įkelti nepavyko");
        CommonConfig.errorHandler(error);
      },
    }
  );
}

export const setPropertyTokenByPropertyId = (
  propertyId: string,
  token: string
) => {
  sessionStorage.setItem(`propertyToken.${propertyId}`, token);
};

export const deletePropertyTokenByPropertyId = (propertyId: string) => {
  sessionStorage.removeItem(`propertyToken.${propertyId}`);
};

export const useDeletePropertyToken = (propertyId: string) => {
  useEffect(() => {
    deletePropertyTokenByPropertyId(propertyId);
  }, [propertyId]);
};

export const setPropertyToken = (property: MyProperty) => {
  if (property.propertyToken) {
    setPropertyTokenByPropertyId(property._id, property.propertyToken);
  }
};

export function useWithPropertyEditToken() {
  const createdPropertyId = useRouteQuery().get("createdPropertyId");

  const editToken = useRouteQuery().get("editToken");

  useEffect(() => {
    if (!createdPropertyId || !editToken) {
      return;
    }
    setPropertyTokenByPropertyId(createdPropertyId, editToken);
  }, [editToken, createdPropertyId]);

  return { isEditing: !!editToken };
}

export function useUpdateMyProperty(
  propertyIdParam: string | undefined,
  onSuccess: (data?: MyProperty) => void,
  updateMyPropertyFunction = updateMyProperty,
  addMyPropertyFunction = addMyProperty,
  deleteMyPropertyPhotosFunction = deleteMyPropertyPhotos,
  uploadMyPropertyPhotosFunction = uploadMyPropertyPhotos
) {
  const [propertyId, setPropertyId] = useState(propertyIdParam);

  const { fromLead } = useContext(PropertyCreationContext);

  const { isLoading, mutateAsync, isSuccess, data } = useMutation(
    !!propertyId
      ? (myProperty: MyPropertyAddRequest) =>
          updateMyPropertyFunction(myProperty, propertyId)
      : addMyPropertyFunction,
    {
      onSuccess: (data) => {
        if (fromLead) {
          setPropertyToken(data);
        }
        setPropertyId(data._id);
      },
    }
  );

  const onMutationSuccess = () => {
    onSuccess(data);
  };

  const {
    isLoading: isUploadingPhotos,
    mutate: mutatePhotos,
    isSuccess: isUploadSuccess,
  } = useUploadMyPropertyPhotosMutation(uploadMyPropertyPhotosFunction);

  const {
    isLoading: isDeletingPhotos,
    mutateAsync: mutateDeletePhotosAsync,
    isSuccess: isDeleteSuccess,
  } = useDeleteMyPropertyPhotosMutation((photos: string[]) =>
    deleteMyPropertyPhotosFunction(photos, propertyId)
  );

  const [skipDeletingPhotos, setSkipDeletingPhotos] = useState(false);
  const [skipUploadingPhotos, setSkipUploadingPhotos] = useState(false);

  const submitHandler = async (
    myProperty: MyPropertyAddRequest,
    deletedPhotosIds: string[] | undefined,
    imagesFormdata: FormData | undefined
  ) => {
    setSkipUploadingPhotos(false);
    setSkipDeletingPhotos(false);

    let res;
    try {
      res = await mutateAsync(myProperty);
    } catch (error) {
      showToastError(getErrorMessage(error));
      return;
    }

    if (deletedPhotosIds && deletedPhotosIds?.length > 0 && !isDeleteSuccess) {
      await mutateDeletePhotosAsync(deletedPhotosIds);
    } else {
      setSkipDeletingPhotos(true);
    }

    if (res && imagesFormdata && !isUploadSuccess) {
      mutatePhotos({
        formData: imagesFormdata,
        propertyId: res._id,
      });
    } else {
      setSkipUploadingPhotos(true);
    }
  };

  useEffect(() => {
    const successUpload = isUploadSuccess || skipUploadingPhotos;
    const successDelete = isDeleteSuccess || skipDeletingPhotos;

    if (isSuccess && successUpload && successDelete) {
      onMutationSuccess();
    }
  }, [
    skipDeletingPhotos,
    skipUploadingPhotos,
    isSuccess,
    isUploadSuccess,
    isDeleteSuccess,
  ]);

  return {
    submitHandler,
    isLoading: isLoading || isUploadingPhotos || isDeletingPhotos,
  };
}
